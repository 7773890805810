




import Vue, { CreateElement } from 'vue';
import Component from 'vue-class-component';

const svg = `
<svg
  width="1em"
  height="1em"
  viewBox="0 0 20 21"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10.4427 20.0326L0.626156 18.0204C0.262582 17.9374 0 17.6263 0 17.2321V2.69035C0 2.29621 0.262582 1.9643 0.646355
  1.90207L10.4629 0.0558243C10.8062 -0.00640874 11.1496 0.263268 11.1496 0.636666V19.4518C11.1294 19.8252 10.8062 20.1156
  10.4427 20.0326Z"
    fill="currentColor"
  />
  <path
    d="M5.95972 9.82616L7.95938 5.80176L6.1617 5.98846L5.11138 8.68522L4.04085 6.21664L2.26338 6.40334L4.26304 9.80541L2.021
  13.166L3.67728 13.3734L5.11138 10.7389L6.52528 13.7053L8.20176 13.9128L5.95972 9.82616Z"
    fill="white"
  />
  <path
    d="M11.254 2.27563L10.2784 3.1054H18.6063C18.9295 3.1054 19.1921 3.37508 19.1921 3.70699V16.6929C19.1921 17.0248 18.9295
  17.2945 18.6063 17.2945H10.2784V18.1243H18.9699C19.5355 18.1243 20 17.6472 20 17.0663V3.31284C20 2.732 19.5355 2.25488 18.9699
  2.25488H10.2784L11.254 2.27563Z"
    fill="currentColor"
  />
  <path
    d="M16.3016 10.2403V8.06396H13.9426V10.2403H12.6831L15.1221 12.622L17.5611 10.2403H16.3016Z"
    fill="currentColor"
  />
</svg>`;

const ExcelDownloadSvg = {
  render: (h: CreateElement) => h('span', {
    domProps: {
      innerHTML: svg,
    },
  }),
};

@Component
export default class ExcelDownloadIcon extends Vue {
  ExcelDownloadSvg = ExcelDownloadSvg;
}
