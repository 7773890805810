



























































































import { AxiosResponse } from 'axios';
import Vue from 'vue';
import {
  Component,
  Prop,
  Ref,
  Watch,
} from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import { TableColumn, Pagination, Sorter } from '@/types/ant-design-vue-types';
import {
  CourseCreate,
  CourseListItem,
  CourseTemplateListItem,
  Patient,
} from '@/client-axios';
import date from '@/filters/date';
import BackendService from '@/api/backendService';
import {
  getActionsColumn,
  getDefaultPagination,
  getNumberColumn,
  sortIsDesc,
} from '@/utils/registry';
import { fakeDownload, getDownloadRequestConfig } from '@/utils/exporting';
import TreatmentCourseEditor from './TreatmentCourseEditor.vue';
import TreatmentCourseDetails from './TreatmentCourseDetails.vue';

interface Filter {
  templateId?: number;
}

@Component({
  components: {
    TreatmentCourseEditor,
    TreatmentCourseDetails,
  },
})
export default class Treatments extends Vue {
  @Prop({ required: true })
  readonly patient: Patient;

  @Prop({ required: false, type: Boolean, default: false })
  readonly readonly: boolean;

  get columns(): TableColumn<CourseListItem>[] {
    return [
      getNumberColumn(this.pagination),
      {
        title: 'Дата начала',
        dataIndex: 'startDate',
        customRender: date,
        sorter: true,
        defaultSortOrder: 'descend',
      },
      {
        title: 'Название',
        dataIndex: 'courseTemplateId',
        customRender: (templateId: number) => this.courseTemplates.find((x) => x.id === templateId)?.name,
      },
      {
        title: 'Рост, см',
        dataIndex: 'height',
      },
      {
        title: 'Вес, кг',
        dataIndex: 'weight',
      },
      {
        title: `Площадь тела, ${this.$const.squareMetersString}`,
        dataIndex: 'surfaceArea',
        customRender: (surfaceArea: number) => (surfaceArea ? surfaceArea.toFixed(2) : ''),
      },
      {
        title: 'Креатинин, мкмоль/л',
        dataIndex: 'creatinine',
      },
      getActionsColumn(),
    ];
  }

  rowClassName(row: CourseListItem) {
    if (row.id === this.selectedCourseId) {
      return 'ant-table-row-hover';
    }
    return '';
  }

  created() {
    this.load();
    this.loadCourseTemplateList();
  }

  pagination: Pagination = getDefaultPagination();

  sorter: Sorter = {
    field: 'startDate',
    order: 'descend',
  };

  filter: Filter = {
    templateId: undefined,
  };

  onTableStateChange(pagination: Pagination, _: unknown, sorter: Sorter) {
    this.pagination = pagination;
    this.sorter = sorter;
    this.load();
  }

  courses: CourseListItem[] = [];

  loading = false;

  loadingError: null | Error = null;

  load() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.coursesApi.listCourses(
      this.pagination.current,
      this.pagination.pageSize,
      this.sorter.order ? sortIsDesc(this.sorter.order) : undefined,
      this.sorter.order ? this.sorter.field : undefined,
      this.patient.id,
      this.filter.templateId,
    )
      .then(({ data }) => {
        this.courses = data.items;
        this.pagination.total = data.totalCount;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }

  @Ref()
  readonly form!: FormModel;

  validateForm() {
    return (this.form.validate() as Promise<unknown>);
  }

  newCourse: null | CourseCreate = null;

  add() {
    const lastCourse = this.courses[0];
    this.newCourse = {
      patientId: this.patient.id,
      antifungalId: undefined,
      hydratationId: undefined,
      courseTemplateId: undefined as unknown as number,
      startDate: undefined,
      height: lastCourse?.height,
      weight: lastCourse?.weight,
      idealWeight: undefined,
      creatinine: undefined,
      bilirubin: undefined,
      courseNumber: undefined,
      surfaceArea: undefined,
      clearance: undefined,
    };
  }

  addConfirm() {
    if (!this.newCourse) {
      throw new Error('Данные о курсе отсутствуют');
    }
    return this.validateForm()
      .then(this.executeAdd)
      .catch(() => {
        this.$message.warning('На форме присутствуют ошибки');
      });
  }

  addCancel() {
    this.newCourse = null;
  }

  adding = false;

  addingError: null | Error = null;

  executeAdd() {
    if (!this.newCourse) {
      throw new Error('Нет данных');
    }

    this.adding = true;
    this.addingError = null;
    return BackendService.coursesApi.createCourse(this.newCourse)
      .then(({ data }) => {
        this.adding = false;
        this.selectedCourseId = data.id;
        this.addCancel();
        this.load();
      })
      .catch((error) => {
        this.adding = false;
        this.addingError = error;
      });
  }

  selectedCourseId: null | number = null;

  @Watch('selectedCourseId')
  onSelectedCourseChanged() {
    if (this.selectedCourseId) {
      this.newCourse = null;
    }
  }

  @Watch('newCourse')
  onNewCourseChanged() {
    if (this.newCourse) {
      this.selectedCourseId = null;
    }
  }

  courseTemplates: CourseTemplateListItem[] = [];

  loadCourseTemplateList() {
    return BackendService.coursesApi.listCourseTemplates()
      .then(({ data }) => {
        this.courseTemplates = data;
      });
  }

  get selectedCourse() {
    return this.courses.find((x) => x.id === this.selectedCourseId);
  }

  get selectedCourseName() {
    if (this.selectedCourse) {
      return this.courseTemplates.find((x) => x.id === this.selectedCourse?.courseTemplateId)?.name;
    }
    return undefined;
  }

  view(course: CourseListItem) {
    this.selectedCourseId = course.id;
  }

  remove(course: CourseListItem) {
    this.$confirm({
      title: 'Вы действительно хотите удалить курс лечения?',
      okText: 'Удалить',
      okButtonProps: { type: 'danger', loading: this.removing },
      onOk: () => this.executeRemove(course),
    });
  }

  removing = false;

  removingError: null | Error = null;

  executeRemove(course: CourseListItem) {
    this.removing = true;
    this.removingError = null;
    return BackendService.coursesApi.deleteCourseById(course.id)
      .then(() => {
        this.removing = false;
        this.removingError = null;
        this.selectedCourseId = null;
        this.pagination = getDefaultPagination();
        this.load();
      })
      .catch((error) => {
        this.removing = false;
        this.removingError = error;
      });
  }

  downloading = false;

  download(course: CourseListItem) {
    const template = this.courseTemplates.find((x) => x.id === course.courseTemplateId);

    this.downloading = true;
    return BackendService.coursesApi.downloadExcelCourseById(course.id, getDownloadRequestConfig())
      .then((response) => {
        const actualResponse = response as unknown as AxiosResponse<unknown>;
        const fileName = `${this.patient.lastName}_${this.patient.firstName}_${this.patient.middleName}`
                         + `_${template?.name}_${course.startDate}.xlsx`;
        fakeDownload(actualResponse, fileName);
        this.downloading = false;
      })
      .catch((error) => {
        this.downloading = false;
        this.$message.error(`Ошибка загрузки файла ${error.message}`);
      });
  }

  templateIsOther(templateId: number) {
    const selectedTemplate = this.courseTemplates.find((x) => templateId === x.id);
    return selectedTemplate?.code === 'other';
  }
}
