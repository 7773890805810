




















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import BackendService from '@/api/backendService';
import { ResetPassword as ResetPasswordModel, ResultStatusModel } from '@/client-axios';

@Component({
  components: {},
})
export default class ResetPassword extends Vue {
  model: ResetPasswordModel = {
    email: '',
    token: '',
    password: '',
  };

  created() {
    if (this.$route.params.token) {
      this.model.token = this.$route.params.token;
    }
  }

  @Ref()
  readonly form: FormModel;

  resetPassword() {
    (this.form.validate() as Promise<unknown>)
      .then(this.executeResetPassword);
  }

  resetingPassword = false;

  resetingPasswordError: null | Error = null;

  resetStatus: ResultStatusModel | null = null;

  executeResetPassword() {
    this.resetStatus = null;
    this.resetingPassword = true;
    this.resetingPasswordError = null;
    return BackendService.usersApi.resetPassword(this.model)
      .then(({ data }) => {
        this.resetingPassword = false;
        if (data.succeeded) {
          this.$router.push({ name: 'account-login' });
        } else {
          this.resetStatus = data;
        }
      })
      .catch((error) => {
        this.resetingPasswordError = error;
        this.resetingPassword = false;
      });
  }
}
