const classifiers = {
    analysisStatus: {
      sampled: 'sampled',
      registered: 'registered',
      mailed: 'mailed',
      sent: 'sent',
      delivered: 'delivered',
      done: 'done',
      modified: 'modified',
      closed: 'closed',
    },
    sex: {
      male: 'male',
      female: 'female',
    },
    antifungal: {
      None: 'None',
      Posaconazole: 'Posaconazole',
      Voriconazole: 'Voriconazole',
      Fluconazole: 'Fluconazole',
    },
    administration: {
      intravenous: 'intravenous',
      subcutaneous: 'subcutaneous',
      intramusculary: 'intramusculary',
      peroral: 'peroral',
    },
    unit: {
      mg: 'mg',
      ml: 'ml',
      μg: 'μg',
    },
    ELN: {
      favorable: 'favorable',
      intermediate: 'intermediate',
      adverse: 'adverse',
    },
    ELNitemStatus: {
      positive: 'positive',
      negative: 'negative',
      undefined: 'undefined',
    },
    structureTypes: {
      root: 'root',
      federalCenter: 'federalCenter',
      transplantationClinic: 'transplantationClinic',
      laboratory: 'laboratory',
      clinic: 'clinic',
      department: 'department',
    },
    transportObject: {
      EDTA: 'EDTA',
      heparin: 'heparin',
      glass8: 'glass8',
    },
    followUpEvents: {
      remission: 'remission',
      relapse: 'relapse',
      death: 'death',
      lost: 'lost',
    },
};

export default classifiers;
