























































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { Getter } from 'vuex-class';
import RootState from '@/store/rootState';
import { AuthConst, AuthModuleNamespace } from '@/store/constants';
import { Affiliation } from '@/client-axios';
import { RootConst } from '@/store';

const calculateCurrentNavigationItem = (route: string): string => route.split('-')[0];

@Component
export default class Navigation extends Vue {
  current: string[] = [];

  updateCurrentMenuItems() {
    this.current = [
      calculateCurrentNavigationItem(this.$route.name as string),
    ];
    if (this.currentAffiliation) {
      this.current.push(`affiliation_${this.currentAffiliation?.id}`);
    }
  }

  created() {
    this.updateCurrentMenuItems();
    this.loadNotificationsCount();
  }

  get hasAccountsPermission(): boolean {
    return this.$auth.permissions.has(this.$const.permissions.sections.user);
  }

  get hasShippingCompaniesPermission(): boolean {
    return this.$auth.permissions.has(this.$const.permissions.sections.shippingCompany);
  }

  get hasStructuresPermission(): boolean {
    return this.$auth.permissions.has(this.$const.permissions.sections.structure);
  }

  get canViewPatients() {
    return this.$auth.permissions.has(this.$const.permissions.sections.patient);
  }

  get canViewAnalyzes() {
    return this.$auth.permissions.has(this.$const.permissions.sections.analysis);
  }

  get canViewStatistics() {
    return this.$auth.permissions.has(this.$const.permissions.sections.statistics);
  }

  get canViewDump() {
    return this.$auth.permissions.has(this.$const.permissions.sections.dump);
  }

  @Watch('$route')
  onRouteUpdated() {
    this.updateCurrentMenuItems();
  }

  get isAuthenticated(): boolean {
    return this.$store.getters[AuthConst.Getters.isAuthenticated] && !!this.currentAffiliation;
  }

  get isApplicationInitialized(): boolean {
    return (this.$store as Store<RootState>).state.status === null;
  }

  @Watch('isAuthenticated')
  onAuthenticated() {
    this.loadNotificationsCount();
  }

  get user() {
    return (this.$store as Store<RootState>).state[AuthModuleNamespace]?.user;
  }

  @Watch('currentAffiliation')
  onCurrentAffiliationChanged() {
    this.onRouteUpdated();
  }

  logout() {
    this.$store.dispatch(AuthConst.Actions.logout)
      .then(() => {
        this.$router.push({ name: 'account-login' });
      });
  }

  selectingAffiliation = false;

  selectAffiliation(affiliationId: number) {
    this.selectingAffiliation = true;
    this.$store.dispatch(AuthConst.Actions.selectAffiliation, affiliationId)
    .then(() => {
      if (this.$route.name === 'home') {
        this.$router.go(0);
      } else {
        this.selectingAffiliation = false;
        this.$router.push({ name: 'home' });
      }
      this.loadNotificationsCount();
    })
    .catch((error: Error) => {
      this.selectingAffiliation = false;
      this.$message.error({ content: error.message });
    });
  }

  loadNotificationsCount() {
    this.$store.dispatch(RootConst.Actions.loadNotifications);
  }

  @Getter(RootConst.Getters.notifications)
  readonly notificationsCount: null | number;

  @Getter(AuthConst.Getters.currentAffiliation)
  readonly currentAffiliation: Affiliation | undefined;

  get notificationsCountTitle() {
    const currentRole = this.currentAffiliation?.roleName;
    if (currentRole === this.$const.roles.Doctor) {
      return 'Непрочитанных ответов по консультациям';
    }
    if (currentRole === this.$const.roles.Consultant) {
      return 'Неотвеченных запросов на консультации';
    }
    return '';
  }
}
