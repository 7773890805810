export default {
  Getters: {
    isAuthenticated: 'isAuthenticated',
    currentAffiliation: 'currentAffiliation',
    permissions: 'permissions',
    isPatientsAccessRestricted: 'isPatientsAccessRestricted',
  },
  Actions: {
    login: 'login',
    logout: 'logout',
    getCurrent: 'getCurrent',
    getAffiliationStructureWithChildrenIds: 'getAffiliationStructureWithChildrenIds',
    selectAffiliation: 'selectAffiliation',
  },
  Mutations: {
    setUser: 'setUser',
    // selectAffiliation: 'selectAffiliation',
  },
};
