












import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AnalysisStatus } from '@/client-axios';

@Component
export default class StatusHistory extends Vue {
  @Prop({ required: true })
  readonly value!: AnalysisStatus[];
}
