






import { Store } from 'vuex';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import analysisStatusTagColor from '@/utils/analysys-status-tag-color';
import { RootConst } from '@/store';
import RootState from '@/store/rootState';
import { ClassifierItem } from '@/client-axios';

@Component
export default class AnalysisStatusTag extends Vue {
  @Prop({ required: true })
  readonly statusId: number;

  get status(): ClassifierItem | undefined {
    return (this.$store as Store<RootState>).getters[RootConst.Getters.classifierItemById](this.statusId);
  }

  get tagColor() {
    return analysisStatusTagColor(this.status?.value ?? '');
  }
}
