




















import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserListItem } from '@/client-axios';
import BackendService from '@/api/backendService';

@Component
export default class UserSelect extends Vue {
  @Prop({
    required: true,
  })
  readonly value: string | number;

  searchingUser = false;

  users: UserListItem[] = [];

  onUserSearch(value: string) {
    this.searchingUser = true;
    return BackendService.usersApi.listUser(1, 5, undefined, undefined, value)
      .then(({ data }) => {
        this.users = data.items;
        this.searchingUser = false;
      })
      .catch(() => {
        this.searchingUser = false;
      });
  }

  onValueChange(val?: { target: HTMLInputElement } | string | number) {
    this.$emit('input', val);
  }
}
