











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'value',
    event: 'change',
  },
})
export default class DatePicker extends Vue {
  @Prop({ required: true })
  readonly value: string;
}
