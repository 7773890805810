



























































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DonorCreate } from '@/client-axios';

@Component
export default class DonorEditor extends Vue {
  @Prop({ required: true })
  readonly value: DonorCreate;

  compatibilityRules = [
    {
      len: 5,
      message: 'Поле должно быть длиной 5 символов',
    },
  ];
}
