


















import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import { DataType, getDataTypeDisplayName } from './StatisticsChart.vue';

@Component({
  filters: {
    chartType(val: DataType) {
      return getDataTypeDisplayName(val);
    },
  },
})
export default class ChartTypeDropdown extends Vue {
  @Prop({ required: true, type: String })
  readonly value: string;

  types: DataType[] = ['basic', 'cumulative'];

  switchType(value: DataType) {
    this.$emit('input', value);
  }
}
