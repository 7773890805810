

















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  AnalysisResultDetailed,
  TemplateAnalysis,
  TemplateTest,
  TemplateTestField,
  TestResultField,
} from '@/client-axios';
import orderComparer, { IOrderable } from '@/utils/order-comparer';
import TestField from './TestField.vue';

export interface ExtendedTest extends TemplateTest {
  testFields: {[key: string]: TestResultField},
}

@Component({
  components: {
    TestField,
  },
})
export default class AnalysisEditor extends Vue {
  @Prop({ required: true })
  readonly template: TemplateAnalysis;

  @Prop({ required: true })
  readonly value: AnalysisResultDetailed;

  get templateTests() {
    return this.template.templateTests;
  }

  get orderedTemplateTests() {
    return [...this.templateTests].sort(orderComparer);
  }

  get resultFields(): { [templateFieldId: number]: TestResultField } {
    return Object.fromEntries(this.value.testResultFields.map((resultField) => [resultField.templateTestFieldId, resultField]));
  }

  onFieldUpdated(field: TestResultField) {
    const fieldToEdit = this.value.testResultFields.find((x) => (x.id
      ? x.id === field.id
      : x.templateTestFieldId === field.templateTestFieldId));
    if (fieldToEdit) {
      Object.assign(fieldToEdit, field);
    }
  }

  getValueForTemplateField(templateField: TemplateTestField): TestResultField | undefined {
    return this.value.testResultFields.find((x) => x.templateTestFieldId === templateField.id);
  }

  sortByOrder = <T extends IOrderable>(array: T[]) => [...array].sort(orderComparer);

  mounted() {
    this.addAll();
  }

  filterTestString = '';

  get availableToAddTests() {
    const addedTestsIds = this.orderedTemplateTests.map((x) => x.id);
    const filteredTests = this.template?.templateTests?.filter((x) => !addedTestsIds.includes(x.id)) ?? [];

    if (this.filterTestString) {
      const normalizedInput = this.filterTestString.toUpperCase();
      return filteredTests.filter((x) => x.name?.toUpperCase().includes(normalizedInput));
    }
    return filteredTests;
  }

  newTest: null | ExtendedTest = null;

  addNewTest() {
    this.newTest = {
      id: undefined as unknown as number,
      name: undefined,
      templateAnalysisId: this.template.id,
      templateTestFields: [],
      testFields: {},
    };
  }

  addNewTestConfirm() {
    if (this.newTest) {
      const selectedTest = this.availableToAddTests.find((x) => x.id === this.newTest?.id);
      if (selectedTest) {
        const entries = selectedTest.templateTestFields?.map((x) => ({
          templateTestFieldId: x.id,
          numberValue: undefined,
          textValue: undefined,
          optionId: undefined,
          optionValue: undefined,
        }))
        .map((x) => [x.templateTestFieldId, x]) ?? [];

        const testFields = Object.fromEntries(entries);

        this.newTest = {
          ...selectedTest,
          testFields: testFields,
        };
        this.value.testResultFields.push(testFields);
      }
      this.addNewTestCancel();
    }
  }

  addNewTestCancel() {
    this.newTest = null;
    this.filterTestString = '';
  }

  addAll() {
    this.availableToAddTests.forEach((test) => {
      this.addNewTest();

      if (this.newTest) {
        this.newTest.id = test.id;
        this.addNewTestConfirm();
      }
    });
  }

// removeTest(test: TemplateTest) {
//   const testToRemoveIndex = this.value.testResultFields.findIndex((x) => x.id === test.id);
//   if (testToRemoveIndex !== -1) {
//     this.value.testResultFields.splice(testToRemoveIndex, 1);
//   }
// }
}
