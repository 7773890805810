


































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import BackendService from '@/api/backendService';
import { getDefaultPagination, sortIsDesc } from '@/utils/registry';
import { Pagination, Sorter, TableColumn } from '@/types/ant-design-vue-types';
import { LoginLogListItem, UserListItem } from '@/client-axios';
import dateTime from '@/filters/date-time';
import UserSelect from '@/components/Form/UserSelect.vue';

declare type LogAction = 'login' | 'logout';

interface LogFilter {
  dateFrom?: string;
  dateTo?: string;
  userName?: string;
  userId?: number;
  action?: LogAction;
}

const getEmptyFilter = (): LogFilter => ({
  dateFrom: undefined,
  dateTo: undefined,
  userName: undefined,
  userId: undefined,
  action: undefined,
});

const actionLabels: {[key: string]: string} = {
  login: 'Вход в систему',
  logout: 'Выход из системы',
};

@Component({
  components: {
    UserSelect,
  },
})
export default class LoginsLog extends Vue {
  // eslint-disable-next-line
  get columns(): TableColumn<LoginLogListItem>[] {
    return [
      {
        dataIndex: 'actionDateTime',
        title: 'Дата и время',
        sorter: true,
        defaultSortOrder: 'descend',
        customRender: dateTime,
      },
      {
        dataIndex: 'action',
        title: 'Событие',
        customRender: (action: string) => actionLabels[action],
      },
      {
        dataIndex: 'user',
        title: 'Пользователь',
        customRender: (user: UserListItem) => (
          <router-link to={{ name: 'users-details', params: { id: user.id } }}>
            { user.email } ({ user.name })
          </router-link>
        ),
      },
    ];
  }

  created() {
    this.load();
  }

  logItems: LoginLogListItem[] = [];

  pagination: Pagination = getDefaultPagination();

  sort: Sorter = {
    field: 'actionDateTime',
    order: 'descend',
  };

  onTableStateChange(pagination: Pagination, _: unknown, sorter: Sorter) {
    this.pagination = pagination;
    this.sort = sorter;
    this.load();
  }

  filter: LogFilter = getEmptyFilter();

  actions = ['login', 'logout'].map((x) => ({
    value: x,
    label: actionLabels[x],
  }));

  loading = false;

  loadingError: null | Error = null;

  load() {
    this.loading = true;
    return BackendService.loggingApi.listLogins(
      this.pagination.current,
      this.pagination.pageSize,
      sortIsDesc(this.sort.order),
      this.sort.field,
      this.filter.userId ?? undefined,
      this.filter.userName ?? undefined,
      this.filter.action ?? undefined,
      this.filter.dateFrom ?? undefined,
      this.filter.dateTo ?? undefined,
    )
      .then(({ data }) => {
        this.logItems = data.items;
        this.pagination.total = data.totalCount;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }

  clearFilter() {
    this.filter = getEmptyFilter();
    this.pagination = getDefaultPagination();
  }

  applyFilter() {
    this.pagination = getDefaultPagination();
    this.load();
  }
}
