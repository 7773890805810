















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { routes } from '@/router';

@Component
export default class Breadcrumbs extends Vue {
  get routes() {
    const currentRouteParts = this.$route.path.split('/')
      .filter((x) => x)
      .map((x) => routes.find((y) => `/${x}` === y.path))
      .filter((x) => x)
      .map((x) => ({
        path: x?.path,
        breadcrumbName: x?.meta?.title ?? x?.name ?? x?.path,
      }))
      .filter((x) => x);

    return [
      {
        path: '/',
        breadcrumbName: 'Главная',
      },
      ...currentRouteParts,
      {
        path: '',
        breadcrumbName: this.$route.meta?.title ?? this.$route.name ?? this.$route.path,
      },
    ]
      .filter((x) => x && x.path !== this.$route.path);
  }
}
