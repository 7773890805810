















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ruRU from 'ant-design-vue/es/locale/ru_RU';
import Navigation from '@/components/Navigation.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { RootConst } from './store';
import { Status } from './store/rootState';
import { AuthConst } from './store/constants';
import { Affiliation } from './client-axios';

@Component({
  components: {
    Navigation,
    Breadcrumbs,
  },
})
export default class App extends Vue {
  locale = ruRU;

  @Getter(RootConst.Getters.status)
  readonly status: Status | null;

  @Getter(AuthConst.Getters.currentAffiliation)
  readonly currentAffiliation: Affiliation | undefined;

  get statusIcon() {
    switch (this.status?.type) {
      case 'error':
        return 'exclamation-circle';
      case 'info':
        return 'loading';
      default:
        return undefined;
    }
  }

  get statusTitle() {
    switch (this.status?.type) {
      case 'error':
        return 'При загрузке данных произошла ошибка';
      case 'info':
        return 'Инициализация приложения...';
      case '403':
        return 'Доступ закрыт';
      default:
        return undefined;
    }
  }

  logout() {
    return this.$store.dispatch(AuthConst.Actions.logout)
      .then(() => {
        this.$store.commit(RootConst.Mutations.setStatus, null);
        this.$router.push({ name: 'account-login' });
      });
  }
}
