












































































































































































import JsBarcode from 'jsbarcode';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import {
  AnalysisChangeSampleDate,
  AnalysisResultDetailed,
  ClassifierItem,
  FileInfo,
  StructureFull,
  TemplateAnalysis,
  TemplateTestField,
  TestResultField,
  Affiliation,
} from '@/client-axios';
import BackendService from '@/api/backendService';
import datesComparer from '@/utils/dates-comparer';
import orderComparer from '@/utils/order-comparer';
import PatientInfo from '@/components/PatientInfo.vue';
import DonorInfo from '@/components/DonorInfo.vue';
import FileList from '@/components/Form/FileList.vue';
import { roles } from '@/mixins/const-mixin';
import { TableColumn } from '@/types/ant-design-vue-types';
import { AuthConst, RootConst } from '@/store/constants';
import classifiers from '@/utils/classifiers';
import { fakeDownload, getDownloadRequestConfig } from '@/utils/exporting';
import { Tables } from '@/utils/tables';
import DisplayTestField, { getValueWithUnits } from './DisplayTestField.vue';
import StatusHistory from './StatusHistory.vue';
import { displayReferenceValues } from './TestField.vue';

export function getDisplayValue(template: TemplateTestField, field: TestResultField): string | undefined {
  switch (template.analysisResultType?.type) {
  case 'string':
  case 'text':
    return field?.textValue;
  case 'float':
    return getValueWithUnits(
        field?.numberValue?.toString(),
        template.analysisResultType?.unit,
    );
  case 'options':
    return template.analysisResultType?.optionGroup?.options?.find((x) => x.id === field?.optionId)?.value;
  default:
    return '';
  }
}

const rolesCanEditAnalyzes = [
  roles.ChiefAdministrator,
  roles.Doctor,
  roles.DepartmentHead,
  roles.ClinicAdministrator,
];

@Component({
  components: {
    DisplayTestField,
    PatientInfo,
    DonorInfo,
    StatusHistory,
    FileList,
  },
})
export default class AnalysisDetails extends Vue {
  get analysisId() {
    return parseInt(this.$route.params.id, 10);
  }

  created() {
    this.load();
    this.loadUserLaboratory();
    this.loadAttachments();
  }

  analysis: null | AnalysisResultDetailed = null;

  loading = false;

  loadingError: null | Error = null;

  load() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.analysesApi.getAnalysisById(this.analysisId)
      .then(({ data }) => {
        if (data.statuses) {
          data.statuses = [...data.statuses].sort((a, b) => datesComparer(a.statusDateTime, b.statusDateTime));
        }
        this.analysis = data;
        return this.loadTemplate();
      })
      .then(() => {
        this.loading = false;
        this.$nextTick(() => {
          this.displayBarcode();
        });
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }

  get fillButtonText(): string {
    const analysisFilledStatus: ClassifierItem = this.$store.getters[RootConst.Getters.classifierItemByName](
      'analysisStatus',
      classifiers.analysisStatus.modified,
    );
    return this.analysis?.currentStatusId === analysisFilledStatus.id ? 'Обновить результат' : 'Внести результат';
  }

  displayBarcode() {
    if (this.analysis?.barCode) {
      JsBarcode('#barcode', this.analysis?.barCode, {
        format: this.$const.barcodeFormat,
        lineColor: '#000',
        width: 3,
        height: 40,
        displayValue: true,
        margin: 0,
      });
    }
  }

  template: null | TemplateAnalysis = null;

  loadTemplate() {
    if (!this.analysis?.templateAnalysisId) {
      throw new Error('Невозможно загрузить данные о шаблоне. Идентификатор не указан');
    }

    return BackendService.analysesApi.getAnalysisTemplateById(this.analysis.templateAnalysisId)
      .then(({ data }) => {
        if (data.templateTests) {
          data.templateTests.forEach((x) => {
            if (x.templateTestFields) {
              x.templateTestFields = [...x.templateTestFields].sort(orderComparer);
            }
          });
        }
        this.template = data;
      });
  }

  get displayedTests() {
    const fieldsIds = this.analysis?.testResultFields?.map((x) => x.templateTestFieldId) ?? [];
    const result = this.template?.templateTests?.filter((x) => x.templateTestFields?.some((y) => fieldsIds?.includes(y.id))) ?? [];
    return [...result].sort(orderComparer);
  }

  get fieldsWithValues() {
    return (this.analysis?.testResultFields ?? [])
      .filter((x) => x.numberValue || x.textValue || x.optionId || (x.attachment && Object.keys(x.attachment).length > 0));
  }

  get displayedTestFields() {
    const templateFieldsIdsWithValues = this.fieldsWithValues.map((x) => x.templateTestFieldId);
    return this.displayedTests.map((x) => x.templateTestFields ?? []).flat().filter((x) => templateFieldsIdsWithValues.includes(x.id));
  }

  get actualStatus() {
    if (!this.analysis?.statuses || this.analysis.statuses.length === 0) {
      return undefined;
    }
    return this.analysis.statuses[this.analysis.statuses.length - 1];
  }

  sortByOrder = (array: TemplateTestField[]) => [...array].sort(orderComparer);

  testRowSpanDisplayed: {[key: number]: boolean} = {};

  fieldsTypesWithoutReferenceValues: (string | undefined)[] = ['text', 'file'];

  // eslint-disable-next-line
  get fieldsColumns() {
    const columns: Array<TableColumn<TemplateTestField>> = [
      {
        title: '',
        dataIndex: 'name',
        key: 'test',
        width: '25%',
        align: 'right',
        colSpan: 0,
        customRender: (name: string, template: TemplateTestField) => {
          const templateTest = this.template?.templateTests?.find((x) => x.id === template.templateTestId);
          const testHasOneField = templateTest?.templateTestFields?.length === 1;
          const testFieldsWithValues = templateTest?.templateTestFields?.filter((x) => this.fieldsWithValues
            .some((y) => y.templateTestFieldId === x.id));
          const rowSpanDisplayed = this.testRowSpanDisplayed[template.templateTestId];
          const result = {
            children: templateTest?.name,
            attrs: {
              rowSpan: rowSpanDisplayed ? 0 : testFieldsWithValues?.length ?? 1,
              colSpan: testHasOneField && !name ? 2 : 1,
            },
            style: {
              verticalAlign: 'top',
            },
          };
          this.testRowSpanDisplayed[template.templateTestId ?? -1] = true;
          return result;
        },
      },
      {
        title: 'Название',
        dataIndex: 'name',
        align: 'right',
        width: '25%',
        colSpan: 2,
        customRender: (name: string, template: TemplateTestField) => {
          const templateTest = this.template?.templateTests?.find((x) => x.id === template.templateTestId);
          const testHasOneField = templateTest?.templateTestFields?.length === 1;
          return {
            children: name ?? templateTest,
            attrs: {
              colSpan: testHasOneField ? 0 : 1,
            },
          };
        },
      },
      {
        title: 'Значение',
        dataIndex: 'id',
        width: '25%',
        customRender: (id: number, template: TemplateTestField) => {
          const field = this.analysis?.testResultFields?.find((x) => x.templateTestFieldId === id);
          let displayValue: string | undefined | Element = '';
          if (template && field) {
            if (template.analysisResultType?.type === 'file' && field.attachment) {
              displayValue = (
                <a href={`/api/filestorage/download/${field.attachment.uuid}`} download>
                  <a-icon type="paper-clip" /> {field.attachment.fileName}
                </a>
              ) as unknown as Element;
            } else {
              displayValue = getDisplayValue(template, field);
            }
          }
          return {
            children: displayValue,
            attrs: {
              colSpan: this.fieldsTypesWithoutReferenceValues.includes(template.analysisResultType?.type) ? 2 : 1,
            },
          };
        },
      },
    ];

    if (this.template?.templateTests?.some((x) => x.templateTestFields?.some((y) => y.analysisResultType?.type === 'options'
        || !!y.referenceLowerLimit
        || !!y.referenceUpperLimit))) {
      columns.push({
        title: 'Референсные значения',
        key: 'referenceValues',
        customRender: (_: number, template: TemplateTestField) => {
          if (this.fieldsTypesWithoutReferenceValues.includes(template.analysisResultType?.type)) {
            return {
              attrs: {
                colSpan: 0,
              },
            };
          }
          return displayReferenceValues(template);
        },
      });
    }
    return columns;
  }

  remove() {
    this.$confirm({
      content: 'Вы действительно хотите удалить исследование?',
      okText: 'Удалить',
      okType: 'danger',
      onOk: this.executeRemove,
      okButtonProps: {
        loading: this.removing,
      },
    });
  }

  removing = false;

  removingError: null | Error = null;

  executeRemove() {
    this.removing = false;
    this.removingError = null;
    return BackendService.analysesApi.deleteAnalysisById(this.analysisId)
      .then(() => {
        this.removing = false;
        this.$router.push({ name: 'analyzes' });
      })
      .catch((error) => {
        this.removing = false;
        this.removingError = error;
        this.$message.error(`При выполнении операции произошла ошибка ${error.message}`);
      });
  }

  undoingRemove = false;

  undoRemove() {
    this.undoingRemove = false;
    return BackendService.entitiesApi.undoDelete({ id: this.analysisId, table: Tables.AnalysisResults })
      .then(() => {
        this.undoingRemove = false;
        this.$router.go(0);
      })
      .catch((error) => {
        this.undoingRemove = false;
        this.$message.error(`При выполнении операции произошла ошибка ${error.message}`);
      });
  }

  @Getter(AuthConst.Getters.currentAffiliation)
  readonly currentAffiliation: Affiliation | undefined;

  userLaboratory: null | StructureFull = null;

  loadUserLaboratory() {
    if (!this.currentAffiliation?.structureId) {
      return Promise.resolve();
    }
    return BackendService.usersApi.getStructurebyId(this.currentAffiliation.structureId)
      .then(({ data }) => {
        this.userLaboratory = data;
      });
  }

  get availableTemplatesIds() {
    return new Set(this.userLaboratory?.templatesIds);
  }

  get canDelete() {
    return this.$auth.permissions.has(this.$const.permissions.sections.analysis, this.$const.permissions.actions.delete);
  }

  get canEdit() {
    const currentRole = this.currentAffiliation?.roleName ?? '';
    if (rolesCanEditAnalyzes.includes(currentRole)) {
      return true;
    }

    const rolesCanEditAnalyzesByType = [roles.LaboratoryStaff, roles.LaboratoryAdministrator];
    if (rolesCanEditAnalyzesByType.includes(currentRole)) {
      return this.analysis?.templateAnalysisId && this.availableTemplatesIds.has(this.analysis.templateAnalysisId);
    }

    return false;
  }

  attachments: FileInfo[] = [];

  attachmentsLoading = false;

  attachmentsLoadingError: null | Error = null;

  loadAttachments() {
    this.attachmentsLoading = true;
    this.attachmentsLoadingError = null;
    return BackendService.analysesApi.listAnalysisAttachments(this.analysisId)
      .then(({ data }) => {
        this.attachments = data;
        this.attachmentsLoading = false;
      })
      .catch((error) => {
        this.attachmentsLoading = false;
        this.attachmentsLoadingError = error;
      });
  }

  get analysisUploadActionUrl() {
    return `/api/analyses/${this.analysisId}/attachments`;
  }

  showStatusesHistory = false;

  downloadingPrintLabel = false;

  downloadPrintLabel() {
    this.downloadingPrintLabel = true;
    return BackendService.analysesApi.getAnalysisLabels([this.analysisId], getDownloadRequestConfig())
      .then((response) => {
        fakeDownload(response);
        this.downloadingPrintLabel = false;
      })
      .catch(() => {
        this.downloadingPrintLabel = false;
      });
  }

  changeSampleDateModel: AnalysisChangeSampleDate | null = null;

  changeSampleDate() {
    if (!this.analysis) {
      throw new Error('Данные анализа отсутствуют');
    }

    this.changeSampleDateModel = {
      sampleDate: this.analysis.sampleDate,
    };
  }

  changingSampleDate = false;

  changeSampleDateConfirm() {
    if (!this.changeSampleDateModel) {
      throw new Error('Данные для обновления даты забора отсутствуют');
    }

    this.changingSampleDate = true;
    return BackendService.analysesApi.changeSampleDate(this.analysisId, this.changeSampleDateModel)
      .then(({ data }) => {
        this.changingSampleDate = false;
        if (!data.succeeded) {
          throw new Error(data.message);
        }
        if (this.analysis && this.changeSampleDateModel) {
          this.analysis.sampleDate = this.changeSampleDateModel.sampleDate;
        }
        this.$message.success({ content: 'Дата забора успешно обновлена' });
        this.changeSampleDateCancel();
      })
      .catch((error: Error) => {
        this.changingSampleDate = false;
        this.$message.error({ content: `При обновлении даты забора произошла ошибка: ${error.message}` });
      });
  }

  changeSampleDateCancel() {
    this.changeSampleDateModel = null;
  }
}
