export default function (val: boolean) {
  if (val === true) {
    return 'Да';
  }

  if (val === false) {
    return 'Нет';
  }

  return '';
}
