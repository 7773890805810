











































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IStatisticsFilter, PeriodType, periodTypeName } from '@/utils/statistics';

@Component
export default class StatisticsFilter extends Vue {
  @Prop({ required: true })
  readonly value: IStatisticsFilter;

  periodTypesOptions = (['week', 'month', 'quarter', 'year'] as PeriodType[])
    .map((x) => ({
      name: periodTypeName(x),
      value: x,
    }));
}
