

















































import { FormModel } from 'ant-design-vue';
import Vue from 'vue';
import {
  Component,
  Prop,
  Ref,
  Watch,
} from 'vue-property-decorator';
import { FollowUp, FollowUpCreate } from '@/client-axios';
import BackendService from '@/api/backendService';
import FollowUpEditor from './FollowUpEditor.vue';
import FollowUpTimeline from './FollowUpTimeline.vue';

@Component({
  components: {
    FollowUpEditor,
    FollowUpTimeline,
  },
})
export default class PatientFollowUps extends Vue {
  @Prop({ required: true, type: Number })
  readonly patientId: number;

  @Prop({ required: true, type: Array })
  readonly value: FollowUp[];

  @Prop({ required: true, type: Boolean, default: true })
  readonly readonly: boolean;

  showAddFollowUp = false;

  @Watch('showAddFollowUp')
  onShowAddFollowUpChange() {
    if (this.showAddFollowUp) {
      this.addFollowUp();
    } else {
      this.addFollowUpCancel();
    }
  }

  @Ref()
  readonly form!: FormModel;

  validateForm() {
    return (this.form.validate() as Promise<unknown>);
  }

  newFollowUp: null | FollowUpCreate = null;

  addFollowUp() {
    this.newFollowUp = {
      patientId: this.patientId,
      months: undefined,
      contactDate: undefined,
      events: [],
    };
  }

  adding = false;

  addingError: null | Error = null;

  addFollowUpConfirm() {
    this.validateForm()
      .then(this.executeAddFollowUp)
      .then(this.loadFollowUps);
  }

  executeAddFollowUp() {
    if (!this.newFollowUp) {
      throw new Error('Данные фоллоуапа отсутствуют');
    }

    this.adding = true;
    this.addingError = null;
    return BackendService.patientApi.createFollowUp(this.newFollowUp)
      .then(() => {
        this.adding = false;
        this.showAddFollowUp = false;
      })
      .catch((error) => {
        this.addingError = error;
        this.adding = false;
      });
  }

  addFollowUpCancel() {
    this.newFollowUp = null;
  }

  loadingFollowUps = false;

  loadFollowUps() {
    this.loadingFollowUps = true;
    return BackendService.patientApi.listFollowUps(1, 100, false, 'months', this.patientId)
      .then(({ data }) => {
        this.loadingFollowUps = false;
        this.$emit('input', data.items ?? []);
      })
      .catch(() => {
        this.loadingFollowUps = false;
      });
  }
}
