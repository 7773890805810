




















import { Store } from 'vuex';
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import classifier from '@/filters/classifier';
import date from '@/filters/date';
import { TableColumn } from '@/types/ant-design-vue-types';
import { AnalysisResultListItem } from '@/client-axios';
import BackendService from '@/api/backendService';
import RootState from '@/store/rootState';

interface ExtendedAnalysisResultShallow extends AnalysisResultListItem {
  templateAnalysisName?: string;
  sampleDate?: string;
}

@Component
export default class PatientAnalyzes extends Vue {
  @Prop({ required: true, type: Number })
  readonly patientId: number;

  @Prop({ required: false, type: Boolean, default: true })
  readonly readonly: boolean;

  created() {
    this.loadAnalyzes();
  }

  loadingAnalyzes = false;

  analyzes: ExtendedAnalysisResultShallow[] = [];

  loadAnalyzes() {
    this.loadingAnalyzes = true;
    return BackendService.analysesApi.listAnalyses(1, 100, undefined, undefined, this.patientId)
      .then(({ data }) => {
        const store = this.$store as Store<RootState>;
        const templates = store.state.analyzesTemplates;
        this.analyzes = data.items?.map((x) => ({
          ...x,
          templateAnalysisName: templates.find((y) => y.id === x.templateAnalysisId)?.name,
          sampleDate: x.sampleDate,
        })) ?? [];
        this.loadingAnalyzes = false;
      })
      .catch(() => {
        this.loadingAnalyzes = false;
      });
  }

  // eslint-disable-next-line
  get analyzesColumns(): TableColumn<ExtendedAnalysisResultShallow>[] {
    return [
      {
        title: 'Тип',
        dataIndex: 'templateAnalysisName',
      },
      {
        title: 'Дата забора',
        dataIndex: 'sampleDate',
        customRender: date,
      },
      {
        title: 'Статус',
        dataIndex: 'currentStatusId',
        customRender: (currentStatusId: number) => (
          <analysis-status-tag status-id={currentStatusId} />
        ),
      },
      {
        title: 'Биоматериал',
        dataIndex: 'biomaterialId',
        customRender: (biomaterialId: number) => classifier(biomaterialId, 'biomaterial'),
      },
      {
        key: 'actions',
        dataIndex: 'id',
        align: 'center',
        customRender: (id: number) => (
          <router-link to={{ name: 'analyzes-details', params: { id: id } }}>
            Перейти
          </router-link>
        ),
      },
    ];
  }
}
