import classifiers from '@/utils/classifiers';

export default function (status: string) {
  switch (status) {
    case classifiers.analysisStatus.done:
    case classifiers.analysisStatus.modified:
    case classifiers.analysisStatus.closed:
      return 'green';
    default:
      return 'blue';
  }
}
