export default function (a?: string, b?: string): number {
  if (a) {
    if (b) {
      return a.localeCompare(b);
    }
    return 1;
  }
  if (b) {
    return -1;
  }
  return 0;
}
