import moment from 'moment';

export default function (date: string | undefined) {
  if (!date) {
    return '';
  }

  let result = moment.duration(moment().diff(date)).humanize();

  if (!/^[0-9]+/.test(result)) {
    result = `1 ${result}`;
  }

  return result;
}
