
























































import moment from 'moment';
import Vue from 'vue';
import { Component, Ref, Watch } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import {
  AnalysisResultDetailed,
  AnalysisResultFill,
  TemplateAnalysis,
  TestResultField,
} from '@/client-axios';
import BackendService from '@/api/backendService';
import { ShortDateValueFormat } from '@/mixins/const-mixin';
import AnalysisEditor from './AnalysisEditor.vue';

@Component({
  components: {
    AnalysisEditor,
  },
})
export default class FillAnalysis extends Vue {
  get analysisId() {
    return parseInt(this.$route.params.id, 10);
  }

  created() {
    this.load();
  }

  loading = false;

  loadingError: null | Error = null;

  analysis: null | AnalysisResultDetailed = null;

  @Watch('analysisId')
  load() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.analysesApi.getAnalysisById(this.analysisId)
      .then(({ data }) => {
        this.analysis = data;
      })
      .then(this.loadTemplate)
      .then(this.fillAbsentResultFields)
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loadingError = error;
        this.loading = false;
      });
  }

  template: null | TemplateAnalysis = null;

  loadTemplate() {
    if (!this.analysis?.templateAnalysisId) {
      throw new Error('Шаблон анализа не указан');
    }
    return BackendService.analysesApi.getAnalysisTemplateById(this.analysis.templateAnalysisId)
      .then(({ data }) => {
        this.template = data;
      });
  }

  fillAbsentResultFields() {
    if (!this.analysis?.testResultFields || !this.template?.templateTests) {
      return;
    }

    const addedTemplateFieldsIds = this.analysis.testResultFields.map((x) => x.templateTestFieldId);
    const absentTemplateTestFields = this.template.templateTests.map((x) => x.templateTestFields)
      .flat()
      .filter((templateField) => !addedTemplateFieldsIds.includes(templateField.id))
      .map((templateField): TestResultField => ({
        id: undefined,
        templateTestFieldId: templateField.id,
        numberValue: undefined,
        textValue: undefined,
        optionId: undefined,
        attachment: undefined,
      }));
    this.analysis.testResultFields.push(...absentTemplateTestFields);
  }

  model: AnalysisResultFill = {
    doneDate: moment().format(ShortDateValueFormat),
    testResultFields: [],
    externalId: undefined,
  };

  @Ref()
  readonly form: FormModel;

  validate() {
    return (this.form.validate() as Promise<unknown>);
  }

  fill() {
    return this.validate()
      .then(this.executeFill)
      .catch(() => {
        this.$message.warning('На форме присутствуют ошибки');
      });
  }

  filling = false;

  fillingError: null | Error = null;

  executeFill() {
    this.model.testResultFields = this.analysis?.testResultFields
      .map(({ attachment, ...x }) => {
        if (!attachment || Object.keys(attachment).length === 0) {
          return x;
        }
        return {
          ...x,
          attachment,
        };
      });

    this.filling = true;
    this.fillingError = null;
    return BackendService.analysesApi.modifyAnalysis(this.analysisId, this.model)
      .then(() => {
        this.filling = false;
        this.$router.push({ name: 'analyzes-details', params: { id: this.$route.params.id } });
      })
      .catch((error) => {
        this.filling = false;
        this.fillingError = error;
      });
  }
}
