








































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
  AffiliationCreate,
  Role,
  StructureListItem,
  StructuresTypesRole,
} from '@/client-axios';
import BackendService from '@/api/backendService';
import StructureTreeSelect from '@/components/StructureTreeSelect.vue';
import RolePermissionsTable from '@/components/RolePermissionsTable.vue';
import {
  getStructuresTree,
} from '@/utils/structures';

@Component({
  components: {
    StructureTreeSelect,
    RolePermissionsTable,
  },
})
export default class AffiliationEditor extends Vue {
  @Prop({ required: true })
  readonly value: AffiliationCreate;

  @Prop({ required: true, type: Array })
  readonly roles: Role[];

  @Watch('value.structureId')
  onStructureChange() {
    this.value.roleId = undefined as unknown as number;
  }

  created() {
    this.loadAllowedStructuresTypesRoles();
  }

  structureTypesRoles: StructuresTypesRole[] = [];

  loadAllowedStructuresTypesRoles() {
    return BackendService.usersApi.getStructuresTypesRoles()
      .then(({ data }) => {
        this.structureTypesRoles = data.items ?? [];
      });
  }

  get filteredRoles() {
    const allowedRoles = this.structureTypesRoles.filter((x) => x.structureTypeId === this.selectedStructure?.typeId)
      .map((x) => x.roleId);
    return this.roles.filter((x) => x.name && allowedRoles.includes(x.id));
  }

  @Prop({ required: false, type: Array, default: () => [] })
  readonly structures: StructureListItem[];

  get structuresTree() {
    return getStructuresTree(this.structures);
  }

  get selectedRole() {
    return this.roles.find((x) => x.id === this.value.roleId);
  }

  get selectedStructure() {
    return this.structures.find((x) => x.id === this.value.structureId);
  }

  get isAdmin() {
    return this.$isInRole(this.$const.roles.ChiefAdministrator);
  }
}
