



























































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Patient, PatientModifyWHODiagnosisId, WHODiagnosisItem } from '@/client-axios';
import BackendService from '@/api/backendService';
import DiagnosisTreeSelect from '@/components/DiagnosisTreeSelect.vue';
import HctCalculator from '../HctCalculator.vue';

@Component({
  components: {
    HctCalculator,
    DiagnosisTreeSelect,
  },
})
export default class PatientDiagnosis extends Vue {
  @Prop({ required: true })
  readonly patient: Patient;

  showHctCalculator = false;

  get hctSurvivalProbability() {
    const hct = this.patient.diagnosisData?.HCTCI;
    if (typeof hct === 'undefined') {
      return undefined;
    }

    if (hct === 0) {
      return 71;
    }

    if (hct === 1 || hct === 2) {
      return 60;
    }

    return 34;
  }

  get editDiagnosisIdAvailable(): boolean {
    return this.$isAdmin() || this.$isInRole(this.$const.roles.Consultant);
  }

  editDiagnosisModel: null | PatientModifyWHODiagnosisId = null;

  editDiagnosis() {
    this.editDiagnosisModel = {
      whoDiagnosisId: null as unknown as number,
    };
  }

  selectedDiagnosis: null | WHODiagnosisItem = null;

  onDiagnosisInput(value: number | undefined, item: WHODiagnosisItem | undefined) {
    if (!this.editDiagnosisModel) {
      return;
    }
    this.editDiagnosisModel.whoDiagnosisId = value as unknown as number;
    this.selectedDiagnosis = item ?? null;
  }

  editingDiagnosisId = false;

  editDiagnosisConfirmed() {
    if (!this.editDiagnosisModel) {
      throw new Error('Данные для редактирования не указаны');
    }

    this.editingDiagnosisId = true;
    return BackendService.patientApi.modifyPatientWHODiagnosisId(this.patient.id, this.editDiagnosisModel)
      .then(() => {
        this.editingDiagnosisId = false;
        if (this.patient.diagnosisData && this.editDiagnosisModel) {
          this.patient.diagnosisData.whoDiagnosisId = this.editDiagnosisModel.whoDiagnosisId;

          if (this.selectedDiagnosis) {
            this.patient.diagnosisData.whoDiagnosis = this.selectedDiagnosis;
          }
        }
        this.editDiagnosisModel = null;
      })
      .catch((error) => {
        this.editingDiagnosisId = false;
      });
  }
}
