/* tslint:disable */
/* eslint-disable */
/**
 * OML-info
 * Pavlov University OML Diagnosis System
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Affiliation
 */
export interface Affiliation {
    /**
     * 
     * @type {number}
     * @memberof Affiliation
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Affiliation
     */
    structureId: number;
    /**
     * 
     * @type {number}
     * @memberof Affiliation
     */
    roleId: number;
    /**
     * 
     * @type {string}
     * @memberof Affiliation
     */
    roleName: string;
    /**
     * 
     * @type {string}
     * @memberof Affiliation
     */
    roleDisplayName: string;
    /**
     * 
     * @type {string}
     * @memberof Affiliation
     */
    structureName: string;
    /**
     * 
     * @type {number}
     * @memberof Affiliation
     */
    structureTypeId: number;
}
/**
 * 
 * @export
 * @interface AffiliationCreate
 */
export interface AffiliationCreate {
    /**
     * 
     * @type {number}
     * @memberof AffiliationCreate
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliationCreate
     */
    structureId: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliationCreate
     */
    roleId: number;
}
/**
 * 
 * @export
 * @interface AffiliationFill
 */
export interface AffiliationFill {
    /**
     * 
     * @type {number}
     * @memberof AffiliationFill
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AffiliationFill
     */
    recordStatus: string;
    /**
     * 
     * @type {number}
     * @memberof AffiliationFill
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliationFill
     */
    structureId: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliationFill
     */
    roleId: number;
}
/**
 * 
 * @export
 * @interface AffiliationFull
 */
export interface AffiliationFull {
    /**
     * 
     * @type {number}
     * @memberof AffiliationFull
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AffiliationFull
     */
    recordStatus: string;
    /**
     * 
     * @type {number}
     * @memberof AffiliationFull
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliationFull
     */
    structureId: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliationFull
     */
    roleId: number;
    /**
     * 
     * @type {UserListItem}
     * @memberof AffiliationFull
     */
    user: UserListItem;
    /**
     * 
     * @type {StructureListItem}
     * @memberof AffiliationFull
     */
    structure: StructureListItem;
    /**
     * 
     * @type {RoleListItem}
     * @memberof AffiliationFull
     */
    role: RoleListItem;
    /**
     * 
     * @type {Array<SubscriptionEvent>}
     * @memberof AffiliationFull
     */
    subscriptions: Array<SubscriptionEvent>;
}
/**
 * 
 * @export
 * @interface AffiliationListItem
 */
export interface AffiliationListItem {
    /**
     * 
     * @type {number}
     * @memberof AffiliationListItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AffiliationListItem
     */
    recordStatus: string;
    /**
     * 
     * @type {number}
     * @memberof AffiliationListItem
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliationListItem
     */
    structureId: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliationListItem
     */
    roleId: number;
    /**
     * 
     * @type {UserListItem}
     * @memberof AffiliationListItem
     */
    user: UserListItem;
    /**
     * 
     * @type {StructureListItem}
     * @memberof AffiliationListItem
     */
    structure: StructureListItem;
    /**
     * 
     * @type {RoleListItem}
     * @memberof AffiliationListItem
     */
    role: RoleListItem;
}
/**
 * 
 * @export
 * @interface AffiliationSubscribe
 */
export interface AffiliationSubscribe {
    /**
     * 
     * @type {string}
     * @memberof AffiliationSubscribe
     */
    name: string;
}
/**
 * 
 * @export
 * @interface Analyses
 */
export interface Analyses {
    /**
     * 
     * @type {number}
     * @memberof Analyses
     */
    totalCount: number;
    /**
     * 
     * @type {Array<GroupedModel>}
     * @memberof Analyses
     */
    byDoctors?: Array<GroupedModel>;
    /**
     * 
     * @type {Array<GroupedModel>}
     * @memberof Analyses
     */
    byTypes?: Array<GroupedModel>;
    /**
     * 
     * @type {Array<GroupedModel>}
     * @memberof Analyses
     */
    byStatuses?: Array<GroupedModel>;
}
/**
 * 
 * @export
 * @interface AnalysisChangeSampleDate
 */
export interface AnalysisChangeSampleDate {
    /**
     * 
     * @type {string}
     * @memberof AnalysisChangeSampleDate
     */
    sampleDate: string;
}
/**
 * 
 * @export
 * @interface AnalysisReceived
 */
export interface AnalysisReceived {
    /**
     * 
     * @type {Array<string>}
     * @memberof AnalysisReceived
     */
    barCodes: Array<string>;
}
/**
 * 
 * @export
 * @interface AnalysisReceivedResponse
 */
export interface AnalysisReceivedResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof AnalysisReceivedResponse
     */
    pass: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnalysisReceivedResponse
     */
    repeated: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnalysisReceivedResponse
     */
    notFound: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnalysisReceivedResponse
     */
    tooLate: Array<string>;
}
/**
 * 
 * @export
 * @interface AnalysisResultCreate
 */
export interface AnalysisResultCreate {
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultCreate
     */
    patientId?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultCreate
     */
    donorId?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultCreate
     */
    templateAnalysisId: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultCreate
     */
    biomaterialId?: number;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultCreate
     */
    sampleDate?: string;
}
/**
 * 
 * @export
 * @interface AnalysisResultDetailed
 */
export interface AnalysisResultDetailed {
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultDetailed
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultDetailed
     */
    recordStatus: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultDetailed
     */
    externalId: string;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultDetailed
     */
    patientId?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultDetailed
     */
    templateAnalysisId: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultDetailed
     */
    biomaterialId?: number;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultDetailed
     */
    barCode?: string;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultDetailed
     */
    currentStatusId?: number;
    /**
     * 
     * @type {Array<AnalysisStatus>}
     * @memberof AnalysisResultDetailed
     */
    statuses: Array<AnalysisStatus>;
    /**
     * 
     * @type {Array<TestResultField>}
     * @memberof AnalysisResultDetailed
     */
    testResultFields: Array<TestResultField>;
    /**
     * 
     * @type {Donor}
     * @memberof AnalysisResultDetailed
     */
    donor?: Donor;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultDetailed
     */
    sampleDate: string;
}
/**
 * 
 * @export
 * @interface AnalysisResultFill
 */
export interface AnalysisResultFill {
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultFill
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultFill
     */
    doneDate?: string;
    /**
     * 
     * @type {Array<TestResultField>}
     * @memberof AnalysisResultFill
     */
    testResultFields?: Array<TestResultField>;
}
/**
 * 
 * @export
 * @interface AnalysisResultList
 */
export interface AnalysisResultList {
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<AnalysisResultListItem>}
     * @memberof AnalysisResultList
     */
    items: Array<AnalysisResultListItem>;
}
/**
 * 
 * @export
 * @interface AnalysisResultListItem
 */
export interface AnalysisResultListItem {
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultListItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultListItem
     */
    recordStatus: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultListItem
     */
    externalId?: string;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultListItem
     */
    patientId?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultListItem
     */
    donorId?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultListItem
     */
    templateAnalysisId: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultListItem
     */
    biomaterialId?: number;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultListItem
     */
    barCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultListItem
     */
    sampleDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultListItem
     */
    currentStatusId?: number;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultListItem
     */
    patientFIO?: string;
}
/**
 * 
 * @export
 * @interface AnalysisResultOption
 */
export interface AnalysisResultOption {
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultOption
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultOption
     */
    groupId: number;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultOption
     */
    value: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnalysisResultOption
     */
    isNormal?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultOption
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface AnalysisResultOptionGroup
 */
export interface AnalysisResultOptionGroup {
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultOptionGroup
     */
    id: number;
    /**
     * 
     * @type {Array<AnalysisResultOption>}
     * @memberof AnalysisResultOptionGroup
     */
    options: Array<AnalysisResultOption>;
}
/**
 * 
 * @export
 * @interface AnalysisResultType
 */
export interface AnalysisResultType {
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultType
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultType
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultType
     */
    unitId?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultType
     */
    optionGroupId?: number;
    /**
     * 
     * @type {AnalysisResultUnit}
     * @memberof AnalysisResultType
     */
    unit?: AnalysisResultUnit;
    /**
     * 
     * @type {AnalysisResultOptionGroup}
     * @memberof AnalysisResultType
     */
    optionGroup?: AnalysisResultOptionGroup;
}
/**
 * 
 * @export
 * @interface AnalysisResultUnit
 */
export interface AnalysisResultUnit {
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultUnit
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultUnit
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResultUnit
     */
    delimiter?: string;
}
/**
 * 
 * @export
 * @interface AnalysisStatus
 */
export interface AnalysisStatus {
    /**
     * 
     * @type {number}
     * @memberof AnalysisStatus
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisStatus
     */
    analysisResultId: number;
    /**
     * 
     * @type {string}
     * @memberof AnalysisStatus
     */
    statusDateTime?: string;
    /**
     * 
     * @type {number}
     * @memberof AnalysisStatus
     */
    statusId: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisStatus
     */
    statusUserId?: number;
}
/**
 * 
 * @export
 * @interface Available
 */
export interface Available {
    /**
     * 
     * @type {Array<number>}
     * @memberof Available
     */
    structures?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Available
     */
    users?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Available
     */
    affiliations?: Array<number>;
}
/**
 * 
 * @export
 * @interface ChangePassword
 */
export interface ChangePassword {
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    email: string;
}
/**
 * 
 * @export
 * @interface Classifier
 */
export interface Classifier {
    /**
     * 
     * @type {number}
     * @memberof Classifier
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Classifier
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Classifier
     */
    displayName: string;
    /**
     * 
     * @type {Array<ClassifierItem>}
     * @memberof Classifier
     */
    items?: Array<ClassifierItem>;
}
/**
 * 
 * @export
 * @interface ClassifierItem
 */
export interface ClassifierItem {
    /**
     * 
     * @type {number}
     * @memberof ClassifierItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ClassifierItem
     */
    classifierId: number;
    /**
     * 
     * @type {string}
     * @memberof ClassifierItem
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof ClassifierItem
     */
    displayValue: string;
    /**
     * 
     * @type {number}
     * @memberof ClassifierItem
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface ConsultationMessageCreate
 */
export interface ConsultationMessageCreate {
    /**
     * 
     * @type {number}
     * @memberof ConsultationMessageCreate
     */
    patientId: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConsultationMessageCreate
     */
    isAnswered?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConsultationMessageCreate
     */
    isRequest: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConsultationMessageCreate
     */
    message: string;
    /**
     * 
     * @type {Array<FileInfoCreate>}
     * @memberof ConsultationMessageCreate
     */
    attachments?: Array<FileInfoCreate>;
}
/**
 * 
 * @export
 * @interface ConsultationMessageList
 */
export interface ConsultationMessageList {
    /**
     * 
     * @type {number}
     * @memberof ConsultationMessageList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<ConsultationMessageListItem>}
     * @memberof ConsultationMessageList
     */
    items?: Array<ConsultationMessageListItem>;
}
/**
 * 
 * @export
 * @interface ConsultationMessageListItem
 */
export interface ConsultationMessageListItem {
    /**
     * 
     * @type {number}
     * @memberof ConsultationMessageListItem
     */
    patientId: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConsultationMessageListItem
     */
    isAnswered?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConsultationMessageListItem
     */
    isRequest: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConsultationMessageListItem
     */
    message: string;
    /**
     * 
     * @type {Array<FileInfoListItem>}
     * @memberof ConsultationMessageListItem
     */
    attachments?: Array<FileInfoListItem>;
    /**
     * 
     * @type {number}
     * @memberof ConsultationMessageListItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ConsultationMessageListItem
     */
    recordStatus: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationMessageListItem
     */
    createdDateTime: string;
    /**
     * 
     * @type {number}
     * @memberof ConsultationMessageListItem
     */
    createdUser: number;
    /**
     * 
     * @type {string}
     * @memberof ConsultationMessageListItem
     */
    modifiedDateTime: string;
    /**
     * 
     * @type {number}
     * @memberof ConsultationMessageListItem
     */
    modifiedUser: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConsultationMessageListItem
     */
    isRead: boolean;
}
/**
 * 
 * @export
 * @interface CourseCreate
 */
export interface CourseCreate {
    /**
     * 
     * @type {number}
     * @memberof CourseCreate
     */
    courseTemplateId: number;
    /**
     * 
     * @type {string}
     * @memberof CourseCreate
     */
    startDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseCreate
     */
    patientId: number;
    /**
     * 
     * @type {number}
     * @memberof CourseCreate
     */
    courseNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseCreate
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseCreate
     */
    weight?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseCreate
     */
    creatinine?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseCreate
     */
    bilirubin?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseCreate
     */
    antifungalId?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseCreate
     */
    hydratationId?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseCreate
     */
    idealWeight?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseCreate
     */
    surfaceArea?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseCreate
     */
    clearance?: number;
}
/**
 * 
 * @export
 * @interface CourseDayItem
 */
export interface CourseDayItem {
    /**
     * 
     * @type {string}
     * @memberof CourseDayItem
     */
    drugName: string;
    /**
     * 
     * @type {number}
     * @memberof CourseDayItem
     */
    day: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDayItem
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof CourseDayItem
     */
    displayValue: string;
    /**
     * 
     * @type {string}
     * @memberof CourseDayItem
     */
    administration: string;
    /**
     * 
     * @type {number}
     * @memberof CourseDayItem
     */
    calculatedDose: number;
    /**
     * 
     * @type {number}
     * @memberof CourseDayItem
     */
    calculatedTimes: number;
    /**
     * 
     * @type {string}
     * @memberof CourseDayItem
     */
    unit: string;
}
/**
 * 
 * @export
 * @interface CourseList
 */
export interface CourseList {
    /**
     * 
     * @type {number}
     * @memberof CourseList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<CourseListItem>}
     * @memberof CourseList
     */
    items: Array<CourseListItem>;
}
/**
 * 
 * @export
 * @interface CourseListItem
 */
export interface CourseListItem {
    /**
     * 
     * @type {number}
     * @memberof CourseListItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CourseListItem
     */
    recordStatus: string;
    /**
     * 
     * @type {number}
     * @memberof CourseListItem
     */
    courseTemplateId: number;
    /**
     * 
     * @type {string}
     * @memberof CourseListItem
     */
    startDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseListItem
     */
    patientId: number;
    /**
     * 
     * @type {number}
     * @memberof CourseListItem
     */
    courseNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseListItem
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseListItem
     */
    weight?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseListItem
     */
    creatinine?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseListItem
     */
    bilirubin?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseListItem
     */
    antifungalId?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseListItem
     */
    hydratationId?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseListItem
     */
    idealWeight?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseListItem
     */
    surfaceArea?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseListItem
     */
    clearance?: number;
}
/**
 * 
 * @export
 * @interface CourseSummary
 */
export interface CourseSummary {
    /**
     * 
     * @type {number}
     * @memberof CourseSummary
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CourseSummary
     */
    courseName: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSummary
     */
    courseCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseSummary
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof CourseSummary
     */
    weight: number;
    /**
     * 
     * @type {number}
     * @memberof CourseSummary
     */
    surfaceArea: number;
    /**
     * 
     * @type {string}
     * @memberof CourseSummary
     */
    startDate: string;
    /**
     * 
     * @type {number}
     * @memberof CourseSummary
     */
    durationDays: number;
    /**
     * 
     * @type {PatientCourseInfo}
     * @memberof CourseSummary
     */
    patient?: PatientCourseInfo;
    /**
     * 
     * @type {Array<CourseSummaryPrimaryDrugsItem>}
     * @memberof CourseSummary
     */
    primaryDrugs: Array<CourseSummaryPrimaryDrugsItem>;
    /**
     * 
     * @type {Array<CourseDayItem>}
     * @memberof CourseSummary
     */
    drugDays: Array<CourseDayItem>;
}
/**
 * 
 * @export
 * @interface CourseSummaryPrimaryDrugsItem
 */
export interface CourseSummaryPrimaryDrugsItem {
    /**
     * 
     * @type {string}
     * @memberof CourseSummaryPrimaryDrugsItem
     */
    drugName: string;
    /**
     * 
     * @type {number}
     * @memberof CourseSummaryPrimaryDrugsItem
     */
    order: number;
    /**
     * 
     * @type {number}
     * @memberof CourseSummaryPrimaryDrugsItem
     */
    totalDose: number;
    /**
     * 
     * @type {string}
     * @memberof CourseSummaryPrimaryDrugsItem
     */
    unit: string;
    /**
     * 
     * @type {string}
     * @memberof CourseSummaryPrimaryDrugsItem
     */
    administration: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CourseSummaryPrimaryDrugsItem
     */
    timesPerDay: Array<number>;
}
/**
 * 
 * @export
 * @interface CourseTemplateListItem
 */
export interface CourseTemplateListItem {
    /**
     * 
     * @type {number}
     * @memberof CourseTemplateListItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CourseTemplateListItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CourseTemplateListItem
     */
    code: string;
}
/**
 * 
 * @export
 * @interface CurrentUserInfo
 */
export interface CurrentUserInfo {
    /**
     * 
     * @type {number}
     * @memberof CurrentUserInfo
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserInfo
     */
    userEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserInfo
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserInfo
     */
    userPhone: string;
    /**
     * 
     * @type {Affiliation}
     * @memberof CurrentUserInfo
     */
    currentAffiliation: Affiliation;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof CurrentUserInfo
     */
    permissions: Array<Permission>;
    /**
     * 
     * @type {Available}
     * @memberof CurrentUserInfo
     */
    available: Available;
    /**
     * 
     * @type {Array<Affiliation>}
     * @memberof CurrentUserInfo
     */
    userAffiliations: Array<Affiliation>;
}
/**
 * 
 * @export
 * @interface DiagnosisData
 */
export interface DiagnosisData {
    /**
     * 
     * @type {string}
     * @memberof DiagnosisData
     */
    diagnosisDate?: string;
    /**
     * 
     * @type {number}
     * @memberof DiagnosisData
     */
    HCTCI?: number;
    /**
     * 
     * @type {number}
     * @memberof DiagnosisData
     */
    leucocytes?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DiagnosisData
     */
    myelodysplasticSyndrome?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiagnosisData
     */
    prevOncologyTreatment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiagnosisData
     */
    hematologyRemission?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DiagnosisData
     */
    hematologyRemissionDate?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof DiagnosisData
     */
    hctItemsId?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof DiagnosisData
     */
    whoDiagnosisId: number;
    /**
     * 
     * @type {number}
     * @memberof DiagnosisData
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof DiagnosisData
     */
    patientId: number;
    /**
     * 
     * @type {WHODiagnosisItem}
     * @memberof DiagnosisData
     */
    whoDiagnosis?: WHODiagnosisItem;
}
/**
 * 
 * @export
 * @interface DiagnosisDataCreate
 */
export interface DiagnosisDataCreate {
    /**
     * 
     * @type {string}
     * @memberof DiagnosisDataCreate
     */
    diagnosisDate?: string;
    /**
     * 
     * @type {number}
     * @memberof DiagnosisDataCreate
     */
    HCTCI?: number;
    /**
     * 
     * @type {number}
     * @memberof DiagnosisDataCreate
     */
    leucocytes?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DiagnosisDataCreate
     */
    myelodysplasticSyndrome?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiagnosisDataCreate
     */
    prevOncologyTreatment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiagnosisDataCreate
     */
    hematologyRemission?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DiagnosisDataCreate
     */
    hematologyRemissionDate?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof DiagnosisDataCreate
     */
    hctItemsId?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof DiagnosisDataCreate
     */
    whoDiagnosisId: number;
}
/**
 * 
 * @export
 * @interface Donor
 */
export interface Donor {
    /**
     * 
     * @type {string}
     * @memberof Donor
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Donor
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Donor
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof Donor
     */
    birthDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Donor
     */
    relationDegree?: string;
    /**
     * 
     * @type {number}
     * @memberof Donor
     */
    patientId: number;
    /**
     * 
     * @type {number}
     * @memberof Donor
     */
    resultHLAId?: number;
    /**
     * 
     * @type {string}
     * @memberof Donor
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof Donor
     */
    compatibility?: string;
    /**
     * 
     * @type {number}
     * @memberof Donor
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Donor
     */
    recordStatus: string;
}
/**
 * 
 * @export
 * @interface DonorCreate
 */
export interface DonorCreate {
    /**
     * 
     * @type {string}
     * @memberof DonorCreate
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof DonorCreate
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof DonorCreate
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof DonorCreate
     */
    birthDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DonorCreate
     */
    relationDegree?: string;
    /**
     * 
     * @type {number}
     * @memberof DonorCreate
     */
    patientId: number;
    /**
     * 
     * @type {number}
     * @memberof DonorCreate
     */
    resultHLAId?: number;
    /**
     * 
     * @type {string}
     * @memberof DonorCreate
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof DonorCreate
     */
    compatibility?: string;
}
/**
 * 
 * @export
 * @interface DonorList
 */
export interface DonorList {
    /**
     * 
     * @type {number}
     * @memberof DonorList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<Donor>}
     * @memberof DonorList
     */
    items: Array<Donor>;
}
/**
 * 
 * @export
 * @interface Entity
 */
export interface Entity {
    /**
     * 
     * @type {number}
     * @memberof Entity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    table: string;
}
/**
 * 
 * @export
 * @interface FileInfo
 */
export interface FileInfo {
    /**
     * 
     * @type {number}
     * @memberof FileInfo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    fileName: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfo
     */
    fileSize: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    mimeType: string;
}
/**
 * 
 * @export
 * @interface FileInfoCreate
 */
export interface FileInfoCreate {
    /**
     * 
     * @type {string}
     * @memberof FileInfoCreate
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCreate
     */
    fileName: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoCreate
     */
    fileSize: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoCreate
     */
    mimeType: string;
}
/**
 * 
 * @export
 * @interface FileInfoListItem
 */
export interface FileInfoListItem {
    /**
     * 
     * @type {string}
     * @memberof FileInfoListItem
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoListItem
     */
    fileName: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoListItem
     */
    fileSize: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoListItem
     */
    mimeType: string;
    /**
     * 
     * @type {number}
     * @memberof FileInfoListItem
     */
    id: number;
}
/**
 * 
 * @export
 * @interface FollowUp
 */
export interface FollowUp {
    /**
     * 
     * @type {number}
     * @memberof FollowUp
     */
    patientId: number;
    /**
     * 
     * @type {number}
     * @memberof FollowUp
     */
    months?: number;
    /**
     * 
     * @type {string}
     * @memberof FollowUp
     */
    contactDate?: string;
    /**
     * 
     * @type {Array<FollowUpEvent>}
     * @memberof FollowUp
     */
    events?: Array<FollowUpEvent>;
    /**
     * 
     * @type {number}
     * @memberof FollowUp
     */
    id: number;
}
/**
 * 
 * @export
 * @interface FollowUpCreate
 */
export interface FollowUpCreate {
    /**
     * 
     * @type {number}
     * @memberof FollowUpCreate
     */
    patientId: number;
    /**
     * 
     * @type {number}
     * @memberof FollowUpCreate
     */
    months?: number;
    /**
     * 
     * @type {string}
     * @memberof FollowUpCreate
     */
    contactDate?: string;
    /**
     * 
     * @type {Array<FollowUpEventCreate>}
     * @memberof FollowUpCreate
     */
    events?: Array<FollowUpEventCreate>;
}
/**
 * 
 * @export
 * @interface FollowUpEvent
 */
export interface FollowUpEvent {
    /**
     * 
     * @type {number}
     * @memberof FollowUpEvent
     */
    typeId: number;
    /**
     * 
     * @type {string}
     * @memberof FollowUpEvent
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof FollowUpEvent
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof FollowUpEvent
     */
    followUpId: number;
}
/**
 * 
 * @export
 * @interface FollowUpEventCreate
 */
export interface FollowUpEventCreate {
    /**
     * 
     * @type {number}
     * @memberof FollowUpEventCreate
     */
    typeId: number;
    /**
     * 
     * @type {string}
     * @memberof FollowUpEventCreate
     */
    date?: string;
}
/**
 * 
 * @export
 * @interface FollowUpList
 */
export interface FollowUpList {
    /**
     * 
     * @type {number}
     * @memberof FollowUpList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<FollowUp>}
     * @memberof FollowUpList
     */
    items: Array<FollowUp>;
}
/**
 * 
 * @export
 * @interface GroupedCityModel
 */
export interface GroupedCityModel {
    /**
     * 
     * @type {string}
     * @memberof GroupedCityModel
     */
    groupName: string;
    /**
     * 
     * @type {string}
     * @memberof GroupedCityModel
     */
    timeStep: string;
    /**
     * 
     * @type {number}
     * @memberof GroupedCityModel
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof GroupedCityModel
     */
    city: string;
}
/**
 * 
 * @export
 * @interface GroupedModel
 */
export interface GroupedModel {
    /**
     * 
     * @type {string}
     * @memberof GroupedModel
     */
    groupName: string;
    /**
     * 
     * @type {string}
     * @memberof GroupedModel
     */
    timeStep: string;
    /**
     * 
     * @type {number}
     * @memberof GroupedModel
     */
    count: number;
}
/**
 * 
 * @export
 * @interface HCTItem
 */
export interface HCTItem {
    /**
     * 
     * @type {number}
     * @memberof HCTItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof HCTItem
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof HCTItem
     */
    comorbidity: string;
    /**
     * 
     * @type {string}
     * @memberof HCTItem
     */
    displayValue: string;
    /**
     * 
     * @type {number}
     * @memberof HCTItem
     */
    weightedScore: number;
}
/**
 * 
 * @export
 * @interface IdList
 */
export interface IdList {
    /**
     * 
     * @type {Array<number>}
     * @memberof IdList
     */
    items?: Array<number>;
}
/**
 * 
 * @export
 * @interface IdModel
 */
export interface IdModel {
    /**
     * 
     * @type {number}
     * @memberof IdModel
     */
    id: number;
}
/**
 * 
 * @export
 * @interface LaboratoryProfile
 */
export interface LaboratoryProfile {
    /**
     * 
     * @type {number}
     * @memberof LaboratoryProfile
     */
    laboratoryId: number;
    /**
     * 
     * @type {number}
     * @memberof LaboratoryProfile
     */
    profileId: number;
}
/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    email: string;
}
/**
 * 
 * @export
 * @interface LoginLogList
 */
export interface LoginLogList {
    /**
     * 
     * @type {number}
     * @memberof LoginLogList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<LoginLogListItem>}
     * @memberof LoginLogList
     */
    items: Array<LoginLogListItem>;
}
/**
 * 
 * @export
 * @interface LoginLogListItem
 */
export interface LoginLogListItem {
    /**
     * 
     * @type {number}
     * @memberof LoginLogListItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof LoginLogListItem
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof LoginLogListItem
     */
    actionDateTime: string;
    /**
     * 
     * @type {string}
     * @memberof LoginLogListItem
     */
    action: string;
    /**
     * 
     * @type {UserListItem}
     * @memberof LoginLogListItem
     */
    user?: UserListItem;
}
/**
 * 
 * @export
 * @interface Logins
 */
export interface Logins {
    /**
     * 
     * @type {number}
     * @memberof Logins
     */
    totalCount: number;
    /**
     * 
     * @type {Array<GroupedModel>}
     * @memberof Logins
     */
    byUsers?: Array<GroupedModel>;
}
/**
 * 
 * @export
 * @interface Patient
 */
export interface Patient {
    /**
     * 
     * @type {number}
     * @memberof Patient
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    recordStatus: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    createdDateTime: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    externalId?: string;
    /**
     * 
     * @type {number}
     * @memberof Patient
     */
    affiliationId: number;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    birthDate?: string;
    /**
     * 
     * @type {number}
     * @memberof Patient
     */
    sexId?: number;
    /**
     * 
     * @type {DiagnosisData}
     * @memberof Patient
     */
    diagnosisData?: DiagnosisData;
    /**
     * 
     * @type {Array<FollowUp>}
     * @memberof Patient
     */
    followUps: Array<FollowUp>;
    /**
     * 
     * @type {Array<Donor>}
     * @memberof Patient
     */
    donors: Array<Donor>;
    /**
     * 
     * @type {Array<FileInfo>}
     * @memberof Patient
     */
    attachments: Array<FileInfo>;
    /**
     * 
     * @type {AffiliationFill}
     * @memberof Patient
     */
    affiliation?: AffiliationFill;
}
/**
 * 
 * @export
 * @interface PatientCourseInfo
 */
export interface PatientCourseInfo {
    /**
     * 
     * @type {number}
     * @memberof PatientCourseInfo
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PatientCourseInfo
     */
    fullName: string;
    /**
     * 
     * @type {number}
     * @memberof PatientCourseInfo
     */
    age: number;
}
/**
 * 
 * @export
 * @interface PatientCreate
 */
export interface PatientCreate {
    /**
     * 
     * @type {string}
     * @memberof PatientCreate
     */
    externalId?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientCreate
     */
    affiliationId: number;
    /**
     * 
     * @type {string}
     * @memberof PatientCreate
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientCreate
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientCreate
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientCreate
     */
    birthDate?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientCreate
     */
    sexId?: number;
    /**
     * 
     * @type {DiagnosisDataCreate}
     * @memberof PatientCreate
     */
    diagnosisData?: DiagnosisDataCreate;
}
/**
 * 
 * @export
 * @interface PatientList
 */
export interface PatientList {
    /**
     * 
     * @type {number}
     * @memberof PatientList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<PatientListItem>}
     * @memberof PatientList
     */
    items: Array<PatientListItem>;
}
/**
 * 
 * @export
 * @interface PatientListItem
 */
export interface PatientListItem {
    /**
     * 
     * @type {number}
     * @memberof PatientListItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PatientListItem
     */
    recordStatus: string;
    /**
     * 
     * @type {string}
     * @memberof PatientListItem
     */
    createdDateTime: string;
    /**
     * 
     * @type {string}
     * @memberof PatientListItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientListItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientListItem
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientListItem
     */
    birthDate?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientListItem
     */
    sexId?: number;
    /**
     * 
     * @type {number}
     * @memberof PatientListItem
     */
    affiliationId: number;
    /**
     * 
     * @type {number}
     * @memberof PatientListItem
     */
    structureId: number;
    /**
     * 
     * @type {number}
     * @memberof PatientListItem
     */
    userId: number;
    /**
     * 
     * @type {boolean}
     * @memberof PatientListItem
     */
    isTransplantationPerformed: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientListItem
     */
    statusDate: string;
    /**
     * 
     * @type {number}
     * @memberof PatientListItem
     */
    statusId: number;
    /**
     * 
     * @type {string}
     * @memberof PatientListItem
     */
    hlaSampleDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientListItem
     */
    isHLAPerformed: boolean;
    /**
     * 
     * @type {number}
     * @memberof PatientListItem
     */
    whoDiagnosisId: number;
}
/**
 * 
 * @export
 * @interface PatientModify
 */
export interface PatientModify {
    /**
     * 
     * @type {string}
     * @memberof PatientModify
     */
    externalId?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientModify
     */
    affiliationId: number;
    /**
     * 
     * @type {string}
     * @memberof PatientModify
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientModify
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PatientModify
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientModify
     */
    birthDate?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientModify
     */
    sexId?: number;
    /**
     * 
     * @type {DiagnosisData}
     * @memberof PatientModify
     */
    diagnosisData?: DiagnosisData;
}
/**
 * 
 * @export
 * @interface PatientModifyExternalId
 */
export interface PatientModifyExternalId {
    /**
     * 
     * @type {string}
     * @memberof PatientModifyExternalId
     */
    externalId?: string;
}
/**
 * 
 * @export
 * @interface PatientModifyWHODiagnosisId
 */
export interface PatientModifyWHODiagnosisId {
    /**
     * 
     * @type {number}
     * @memberof PatientModifyWHODiagnosisId
     */
    whoDiagnosisId: number;
}
/**
 * 
 * @export
 * @interface Patients
 */
export interface Patients {
    /**
     * 
     * @type {number}
     * @memberof Patients
     */
    totalCount: number;
    /**
     * 
     * @type {Array<GroupedCityModel>}
     * @memberof Patients
     */
    byDoctors?: Array<GroupedCityModel>;
    /**
     * 
     * @type {Array<GroupedCityModel>}
     * @memberof Patients
     */
    byDepartments?: Array<GroupedCityModel>;
}
/**
 * 
 * @export
 * @interface Permission
 */
export interface Permission {
    /**
     * 
     * @type {number}
     * @memberof Permission
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    action: string;
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    section: string;
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    scope: string;
}
/**
 * 
 * @export
 * @interface ResetPassword
 */
export interface ResetPassword {
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    email: string;
}
/**
 * 
 * @export
 * @interface ResultStatusModel
 */
export interface ResultStatusModel {
    /**
     * 
     * @type {boolean}
     * @memberof ResultStatusModel
     */
    succeeded: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResultStatusModel
     */
    message: string;
}
/**
 * 
 * @export
 * @interface RiskResult
 */
export interface RiskResult {
    /**
     * 
     * @type {number}
     * @memberof RiskResult
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof RiskResult
     */
    patientId: number;
    /**
     * 
     * @type {number}
     * @memberof RiskResult
     */
    testCount: number;
    /**
     * 
     * @type {string}
     * @memberof RiskResult
     */
    computationDateTime: string;
    /**
     * 
     * @type {number}
     * @memberof RiskResult
     */
    overallRiskId: number;
    /**
     * 
     * @type {string}
     * @memberof RiskResult
     */
    overallRisk: string;
    /**
     * 
     * @type {Array<RiskResultItem>}
     * @memberof RiskResult
     */
    items: Array<RiskResultItem>;
}
/**
 * 
 * @export
 * @interface RiskResultItem
 */
export interface RiskResultItem {
    /**
     * 
     * @type {number}
     * @memberof RiskResultItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof RiskResultItem
     */
    riskResultId: number;
    /**
     * 
     * @type {number}
     * @memberof RiskResultItem
     */
    riskTemplateId: number;
    /**
     * 
     * @type {number}
     * @memberof RiskResultItem
     */
    riskCategoryId: number;
    /**
     * 
     * @type {number}
     * @memberof RiskResultItem
     */
    itemStatusId: number;
    /**
     * 
     * @type {number}
     * @memberof RiskResultItem
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof RiskResultItem
     */
    displayValue: string;
    /**
     * 
     * @type {string}
     * @memberof RiskResultItem
     */
    itemStatus: string;
    /**
     * 
     * @type {string}
     * @memberof RiskResultItem
     */
    riskCategory: string;
    /**
     * 
     * @type {string}
     * @memberof RiskResultItem
     */
    note?: string;
}
/**
 * 
 * @export
 * @interface RiskTemplate
 */
export interface RiskTemplate {
    /**
     * 
     * @type {number}
     * @memberof RiskTemplate
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof RiskTemplate
     */
    riskCategoryId: number;
    /**
     * 
     * @type {string}
     * @memberof RiskTemplate
     */
    displayValue: string;
    /**
     * 
     * @type {number}
     * @memberof RiskTemplate
     */
    order: number;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    displayName: string;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof Role
     */
    permissions?: Array<Permission>;
}
/**
 * 
 * @export
 * @interface RoleListItem
 */
export interface RoleListItem {
    /**
     * 
     * @type {number}
     * @memberof RoleListItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof RoleListItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RoleListItem
     */
    displayName: string;
}
/**
 * 
 * @export
 * @interface ShippingCompanyCreateModel
 */
export interface ShippingCompanyCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ShippingCompanyCreateModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingCompanyCreateModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingCompanyCreateModel
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface ShippingCompanyFullModel
 */
export interface ShippingCompanyFullModel {
    /**
     * 
     * @type {string}
     * @memberof ShippingCompanyFullModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingCompanyFullModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingCompanyFullModel
     */
    phone: string;
    /**
     * 
     * @type {number}
     * @memberof ShippingCompanyFullModel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingCompanyFullModel
     */
    recordStatus: string;
}
/**
 * 
 * @export
 * @interface ShippingCompanyModifyModel
 */
export interface ShippingCompanyModifyModel {
    /**
     * 
     * @type {string}
     * @memberof ShippingCompanyModifyModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingCompanyModifyModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingCompanyModifyModel
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface ShippingNotificationEmailModel
 */
export interface ShippingNotificationEmailModel {
    /**
     * 
     * @type {Array<number>}
     * @memberof ShippingNotificationEmailModel
     */
    analysesIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ShippingNotificationEmailModel
     */
    shippingCompanyId: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingNotificationEmailModel
     */
    contactPersonName: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingNotificationEmailModel
     */
    contactPersonPhone: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingNotificationEmailModel
     */
    contactPersonEmail: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingNotificationEmailModel
     */
    contactHours: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingNotificationEmailModel
     */
    comment: string;
}
/**
 * 
 * @export
 * @interface StructureAffiliation
 */
export interface StructureAffiliation {
    /**
     * 
     * @type {number}
     * @memberof StructureAffiliation
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StructureAffiliation
     */
    recordStatus: string;
    /**
     * 
     * @type {number}
     * @memberof StructureAffiliation
     */
    parentId?: number;
    /**
     * 
     * @type {string}
     * @memberof StructureAffiliation
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof StructureAffiliation
     */
    city: string;
    /**
     * 
     * @type {number}
     * @memberof StructureAffiliation
     */
    typeId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof StructureAffiliation
     */
    children?: Array<number>;
}
/**
 * 
 * @export
 * @interface StructureCreate
 */
export interface StructureCreate {
    /**
     * 
     * @type {number}
     * @memberof StructureCreate
     */
    parentId: number;
    /**
     * 
     * @type {string}
     * @memberof StructureCreate
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof StructureCreate
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof StructureCreate
     */
    address: string;
    /**
     * 
     * @type {number}
     * @memberof StructureCreate
     */
    typeId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof StructureCreate
     */
    templatesIds?: Array<number>;
}
/**
 * 
 * @export
 * @interface StructureFull
 */
export interface StructureFull {
    /**
     * 
     * @type {number}
     * @memberof StructureFull
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StructureFull
     */
    recordStatus: string;
    /**
     * 
     * @type {number}
     * @memberof StructureFull
     */
    parentId: number;
    /**
     * 
     * @type {string}
     * @memberof StructureFull
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof StructureFull
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof StructureFull
     */
    address: string;
    /**
     * 
     * @type {number}
     * @memberof StructureFull
     */
    typeId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof StructureFull
     */
    templatesIds?: Array<number>;
}
/**
 * 
 * @export
 * @interface StructureListItem
 */
export interface StructureListItem {
    /**
     * 
     * @type {number}
     * @memberof StructureListItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StructureListItem
     */
    recordStatus: string;
    /**
     * 
     * @type {number}
     * @memberof StructureListItem
     */
    parentId?: number;
    /**
     * 
     * @type {string}
     * @memberof StructureListItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof StructureListItem
     */
    city: string;
    /**
     * 
     * @type {number}
     * @memberof StructureListItem
     */
    typeId: number;
}
/**
 * 
 * @export
 * @interface StructureModify
 */
export interface StructureModify {
    /**
     * 
     * @type {number}
     * @memberof StructureModify
     */
    parentId?: number;
    /**
     * 
     * @type {string}
     * @memberof StructureModify
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof StructureModify
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof StructureModify
     */
    address?: string;
    /**
     * 
     * @type {number}
     * @memberof StructureModify
     */
    typeId: number;
}
/**
 * 
 * @export
 * @interface StructureParentsModel
 */
export interface StructureParentsModel {
    /**
     * 
     * @type {number}
     * @memberof StructureParentsModel
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof StructureParentsModel
     */
    parentId: number;
    /**
     * 
     * @type {string}
     * @memberof StructureParentsModel
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof StructureParentsModel
     */
    typeId: number;
}
/**
 * 
 * @export
 * @interface StructureTree
 */
export interface StructureTree {
    /**
     * 
     * @type {number}
     * @memberof StructureTree
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof StructureTree
     */
    parentId: number;
    /**
     * 
     * @type {string}
     * @memberof StructureTree
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof StructureTree
     */
    address: string;
    /**
     * 
     * @type {number}
     * @memberof StructureTree
     */
    typeId: number;
    /**
     * 
     * @type {Array<StructureTree>}
     * @memberof StructureTree
     */
    children?: Array<StructureTree>;
}
/**
 * 
 * @export
 * @interface StructuresTypesRole
 */
export interface StructuresTypesRole {
    /**
     * 
     * @type {number}
     * @memberof StructuresTypesRole
     */
    structureTypeId: number;
    /**
     * 
     * @type {number}
     * @memberof StructuresTypesRole
     */
    roleId: number;
}
/**
 * 
 * @export
 * @interface StructuresTypesRoles
 */
export interface StructuresTypesRoles {
    /**
     * 
     * @type {Array<StructuresTypesRole>}
     * @memberof StructuresTypesRoles
     */
    items?: Array<StructuresTypesRole>;
}
/**
 * 
 * @export
 * @interface SubscriptionEvent
 */
export interface SubscriptionEvent {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionEvent
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionEvent
     */
    displayName: string;
}
/**
 * 
 * @export
 * @interface TemplateAnalysis
 */
export interface TemplateAnalysis {
    /**
     * 
     * @type {number}
     * @memberof TemplateAnalysis
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TemplateAnalysis
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof TemplateAnalysis
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateAnalysis
     */
    biomaterialId?: number;
    /**
     * 
     * @type {Array<TemplateTest>}
     * @memberof TemplateAnalysis
     */
    templateTests: Array<TemplateTest>;
}
/**
 * 
 * @export
 * @interface TemplateAnalysisList
 */
export interface TemplateAnalysisList {
    /**
     * 
     * @type {number}
     * @memberof TemplateAnalysisList
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TemplateAnalysisList
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof TemplateAnalysisList
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateAnalysisList
     */
    biomaterialId?: number;
}
/**
 * 
 * @export
 * @interface TemplateTest
 */
export interface TemplateTest {
    /**
     * 
     * @type {number}
     * @memberof TemplateTest
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TemplateTest
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof TemplateTest
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateTest
     */
    templateAnalysisId: number;
    /**
     * 
     * @type {Array<TemplateTestField>}
     * @memberof TemplateTest
     */
    templateTestFields: Array<TemplateTestField>;
}
/**
 * 
 * @export
 * @interface TemplateTestField
 */
export interface TemplateTestField {
    /**
     * 
     * @type {number}
     * @memberof TemplateTestField
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TemplateTestField
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof TemplateTestField
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateTestField
     */
    analysisResultTypeId: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateTestField
     */
    referenceLowerLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateTestField
     */
    referenceUpperLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateTestField
     */
    templateTestId: number;
    /**
     * 
     * @type {AnalysisResultType}
     * @memberof TemplateTestField
     */
    analysisResultType: AnalysisResultType;
}
/**
 * 
 * @export
 * @interface TestResultField
 */
export interface TestResultField {
    /**
     * 
     * @type {number}
     * @memberof TestResultField
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultField
     */
    templateTestFieldId: number;
    /**
     * 
     * @type {number}
     * @memberof TestResultField
     */
    numberValue?: number;
    /**
     * 
     * @type {string}
     * @memberof TestResultField
     */
    textValue?: string;
    /**
     * 
     * @type {number}
     * @memberof TestResultField
     */
    optionId?: number;
    /**
     * 
     * @type {FileInfo}
     * @memberof TestResultField
     */
    attachment?: FileInfo;
}
/**
 * 
 * @export
 * @interface Transplantation
 */
export interface Transplantation {
    /**
     * 
     * @type {boolean}
     * @memberof Transplantation
     */
    isPerformed: boolean;
    /**
     * 
     * @type {string}
     * @memberof Transplantation
     */
    transplantationDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof Transplantation
     */
    engraftment: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Transplantation
     */
    aGVHD: boolean;
    /**
     * 
     * @type {string}
     * @memberof Transplantation
     */
    aGVHDDate: string;
    /**
     * 
     * @type {number}
     * @memberof Transplantation
     */
    aGVHDGrade: number;
    /**
     * 
     * @type {boolean}
     * @memberof Transplantation
     */
    cGVHD: boolean;
    /**
     * 
     * @type {string}
     * @memberof Transplantation
     */
    cGVHDDate: string;
    /**
     * 
     * @type {number}
     * @memberof Transplantation
     */
    cGVHDGrade: number;
}
/**
 * 
 * @export
 * @interface UserAffiliation
 */
export interface UserAffiliation {
    /**
     * 
     * @type {number}
     * @memberof UserAffiliation
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserAffiliation
     */
    recordStatus: string;
    /**
     * 
     * @type {number}
     * @memberof UserAffiliation
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof UserAffiliation
     */
    structureId: number;
    /**
     * 
     * @type {number}
     * @memberof UserAffiliation
     */
    roleId: number;
    /**
     * 
     * @type {StructureAffiliation}
     * @memberof UserAffiliation
     */
    structure: StructureAffiliation;
    /**
     * 
     * @type {Role}
     * @memberof UserAffiliation
     */
    role: Role;
}
/**
 * 
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreate
     */
    sendInvitationMail: boolean;
}
/**
 * 
 * @export
 * @interface UserEmail
 */
export interface UserEmail {
    /**
     * 
     * @type {string}
     * @memberof UserEmail
     */
    email: string;
}
/**
 * 
 * @export
 * @interface UserFull
 */
export interface UserFull {
    /**
     * 
     * @type {number}
     * @memberof UserFull
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserFull
     */
    recordStatus: string;
    /**
     * 
     * @type {string}
     * @memberof UserFull
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserFull
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof UserFull
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserFull
     */
    isBlocked: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserFull
     */
    lastAffiliationId: number;
    /**
     * 
     * @type {Array<UserAffiliation>}
     * @memberof UserFull
     */
    affiliations: Array<UserAffiliation>;
}
/**
 * 
 * @export
 * @interface UserList
 */
export interface UserList {
    /**
     * 
     * @type {number}
     * @memberof UserList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<UserListItem>}
     * @memberof UserList
     */
    items: Array<UserListItem>;
}
/**
 * 
 * @export
 * @interface UserListItem
 */
export interface UserListItem {
    /**
     * 
     * @type {number}
     * @memberof UserListItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserListItem
     */
    recordStatus: string;
    /**
     * 
     * @type {string}
     * @memberof UserListItem
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserListItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserListItem
     */
    phone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserListItem
     */
    isBlocked?: boolean;
}
/**
 * 
 * @export
 * @interface UserModify
 */
export interface UserModify {
    /**
     * 
     * @type {string}
     * @memberof UserModify
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserModify
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModify
     */
    name: string;
}
/**
 * 
 * @export
 * @interface WHODiagnosisItem
 */
export interface WHODiagnosisItem {
    /**
     * 
     * @type {number}
     * @memberof WHODiagnosisItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WHODiagnosisItem
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof WHODiagnosisItem
     */
    name: string;
}
/**
 * 
 * @export
 * @interface WHOTree
 */
export interface WHOTree {
    /**
     * 
     * @type {number}
     * @memberof WHOTree
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WHOTree
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof WHOTree
     */
    name: string;
    /**
     * 
     * @type {Array<WHOTree>}
     * @memberof WHOTree
     */
    children?: Array<WHOTree>;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Получить текущую аффиляцию, разрешения и доступные объекты
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        current: async (xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/current/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Login} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (payload: Login, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling login.');
            }
            const localVarPath = `/account/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Войти как другой пользователь
         * @param {IdModel} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAs: async (payload: IdModel, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling loginAs.');
            }
            const localVarPath = `/account/login-as/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/logout/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Сменить аффиляцию текущего пользователя
         * @param {IdModel} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchAffiliation: async (payload: IdModel, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling switchAffiliation.');
            }
            const localVarPath = `/account/switch-affiliation/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Получить текущую аффиляцию, разрешения и доступные объекты
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async current(xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentUserInfo>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).current(xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Login} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(payload: Login, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultStatusModel>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).login(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Войти как другой пользователь
         * @param {IdModel} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAs(payload: IdModel, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultStatusModel>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).loginAs(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultStatusModel>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).logout(xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Сменить аффиляцию текущего пользователя
         * @param {IdModel} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async switchAffiliation(payload: IdModel, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultStatusModel>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).switchAffiliation(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Получить текущую аффиляцию, разрешения и доступные объекты
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        current(xFields?: string, options?: any): AxiosPromise<CurrentUserInfo> {
            return AccountApiFp(configuration).current(xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Login} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(payload: Login, xFields?: string, options?: any): AxiosPromise<ResultStatusModel> {
            return AccountApiFp(configuration).login(payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Войти как другой пользователь
         * @param {IdModel} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAs(payload: IdModel, xFields?: string, options?: any): AxiosPromise<ResultStatusModel> {
            return AccountApiFp(configuration).loginAs(payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(xFields?: string, options?: any): AxiosPromise<ResultStatusModel> {
            return AccountApiFp(configuration).logout(xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Сменить аффиляцию текущего пользователя
         * @param {IdModel} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchAffiliation(payload: IdModel, xFields?: string, options?: any): AxiosPromise<ResultStatusModel> {
            return AccountApiFp(configuration).switchAffiliation(payload, xFields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * Получить текущую аффиляцию, разрешения и доступные объекты
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public current(xFields?: string, options?: any) {
        return AccountApiFp(this.configuration).current(xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Login} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public login(payload: Login, xFields?: string, options?: any) {
        return AccountApiFp(this.configuration).login(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Войти как другой пользователь
     * @param {IdModel} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public loginAs(payload: IdModel, xFields?: string, options?: any) {
        return AccountApiFp(this.configuration).loginAs(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public logout(xFields?: string, options?: any) {
        return AccountApiFp(this.configuration).logout(xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Сменить аффиляцию текущего пользователя
     * @param {IdModel} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public switchAffiliation(payload: IdModel, xFields?: string, options?: any) {
        return AccountApiFp(this.configuration).switchAffiliation(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalysesApi - axios parameter creator
 * @export
 */
export const AnalysesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Приложить файл к анализу
         * @param {number} id 
         * @param {any} file 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachFileToAnalysis: async (id: number, file: any, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling attachFileToAnalysis.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling attachFileToAnalysis.');
            }
            const localVarPath = `/analyses/{id}/attachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Изменить дату забора анализа
         * @param {number} id 
         * @param {AnalysisChangeSampleDate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeSampleDate: async (id: number, payload: AnalysisChangeSampleDate, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling changeSampleDate.');
            }
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling changeSampleDate.');
            }
            const localVarPath = `/analyses/{id}/change-sample-date`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Создать новый анализ
         * @param {AnalysisResultCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalysis: async (payload: AnalysisResultCreate, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling createAnalysis.');
            }
            const localVarPath = `/analyses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Удалить анализ
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnalysisById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAnalysisById.');
            }
            const localVarPath = `/analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить детальную информацию по анализу
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisById: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAnalysisById.');
            }
            const localVarPath = `/analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Напечатать наклейки
         * @param {Array<number>} [analysisId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisLabels: async (analysisId?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/analyses/shipping/print`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (analysisId) {
                localVarQueryParameter['analysisId'] = analysisId.join(COLLECTION_FORMATS.csv);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить детальный шаблон анализа по id
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisTemplateById: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAnalysisTemplateById.');
            }
            const localVarPath = `/analyses/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Сформировать сопроводительный список
         * @param {Array<number>} [analysisId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShippingList: async (analysisId?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/analyses/shipping/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (analysisId) {
                localVarQueryParameter['analysisId'] = analysisId.join(COLLECTION_FORMATS.csv);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить список всех анализов
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {number} [patientId] 
         * @param {Array<number>} [templateAnalysisId] 
         * @param {Array<number>} [affiliationId] 
         * @param {Array<number>} [structureId] 
         * @param {number} [currentStatusId] 
         * @param {string} [sampleDateFrom] 
         * @param {string} [sampleDateTo] 
         * @param {string} [barCode] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAnalyses: async (currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, patientId?: number, templateAnalysisId?: Array<number>, affiliationId?: Array<number>, structureId?: Array<number>, currentStatusId?: number, sampleDateFrom?: string, sampleDateTo?: string, barCode?: string, showDeleted?: boolean, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/analyses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (patientId !== undefined) {
                localVarQueryParameter['patientId'] = patientId;
            }

            if (templateAnalysisId) {
                localVarQueryParameter['templateAnalysisId'] = templateAnalysisId.join(COLLECTION_FORMATS.csv);
            }

            if (affiliationId) {
                localVarQueryParameter['affiliationId'] = affiliationId.join(COLLECTION_FORMATS.csv);
            }

            if (structureId) {
                localVarQueryParameter['structureId'] = structureId.join(COLLECTION_FORMATS.csv);
            }

            if (currentStatusId !== undefined) {
                localVarQueryParameter['currentStatusId'] = currentStatusId;
            }

            if (sampleDateFrom !== undefined) {
                localVarQueryParameter['sampleDateFrom'] = (sampleDateFrom as any instanceof Date) ?
                    (sampleDateFrom as any).toISOString().substr(0,10) :
                    sampleDateFrom;
            }

            if (sampleDateTo !== undefined) {
                localVarQueryParameter['sampleDateTo'] = (sampleDateTo as any instanceof Date) ?
                    (sampleDateTo as any).toISOString().substr(0,10) :
                    sampleDateTo;
            }

            if (barCode !== undefined) {
                localVarQueryParameter['barCode'] = barCode;
            }

            if (showDeleted !== undefined) {
                localVarQueryParameter['showDeleted'] = showDeleted;
            }

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить список файлов, приложенных к анализу
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAnalysisAttachments: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling listAnalysisAttachments.');
            }
            const localVarPath = `/analyses/{id}/attachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить список всех шаблонов анализов
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAnalysisTemplates: async (xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/analyses/templates/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Изменить анализ
         * @param {number} id 
         * @param {AnalysisResultFill} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyAnalysis: async (id: number, payload: AnalysisResultFill, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyAnalysis.');
            }
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling modifyAnalysis.');
            }
            const localVarPath = `/analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Отправить письмо в транспортную компанию
         * @param {ShippingNotificationEmailModel} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyShippingCompany: async (payload: ShippingNotificationEmailModel, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling notifyShippingCompany.');
            }
            const localVarPath = `/analyses/shipping/notify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Отметить анализы полученными
         * @param {AnalysisReceived} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAnalysisReceivedStatus: async (payload: AnalysisReceived, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling setAnalysisReceivedStatus.');
            }
            const localVarPath = `/analyses/received/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Отметить анализы как отправленные
         * @param {Array<number>} [analysisId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAnalysisSentStatus: async (analysisId?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/analyses/shipping/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (analysisId) {
                localVarQueryParameter['analysisId'] = analysisId.join(COLLECTION_FORMATS.csv);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalysesApi - functional programming interface
 * @export
 */
export const AnalysesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Приложить файл к анализу
         * @param {number} id 
         * @param {any} file 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachFileToAnalysis(id: number, file: any, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileInfo>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).attachFileToAnalysis(id, file, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Изменить дату забора анализа
         * @param {number} id 
         * @param {AnalysisChangeSampleDate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeSampleDate(id: number, payload: AnalysisChangeSampleDate, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultStatusModel>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).changeSampleDate(id, payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Создать новый анализ
         * @param {AnalysisResultCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnalysis(payload: AnalysisResultCreate, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdModel>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).createAnalysis(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Удалить анализ
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnalysisById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).deleteAnalysisById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получить детальную информацию по анализу
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalysisById(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisResultDetailed>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).getAnalysisById(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Напечатать наклейки
         * @param {Array<number>} [analysisId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalysisLabels(analysisId?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).getAnalysisLabels(analysisId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получить детальный шаблон анализа по id
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalysisTemplateById(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateAnalysis>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).getAnalysisTemplateById(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Сформировать сопроводительный список
         * @param {Array<number>} [analysisId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShippingList(analysisId?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).getShippingList(analysisId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получить список всех анализов
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {number} [patientId] 
         * @param {Array<number>} [templateAnalysisId] 
         * @param {Array<number>} [affiliationId] 
         * @param {Array<number>} [structureId] 
         * @param {number} [currentStatusId] 
         * @param {string} [sampleDateFrom] 
         * @param {string} [sampleDateTo] 
         * @param {string} [barCode] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAnalyses(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, patientId?: number, templateAnalysisId?: Array<number>, affiliationId?: Array<number>, structureId?: Array<number>, currentStatusId?: number, sampleDateFrom?: string, sampleDateTo?: string, barCode?: string, showDeleted?: boolean, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisResultList>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).listAnalyses(currentPage, perPage, sortDesc, sortBy, patientId, templateAnalysisId, affiliationId, structureId, currentStatusId, sampleDateFrom, sampleDateTo, barCode, showDeleted, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получить список файлов, приложенных к анализу
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAnalysisAttachments(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileInfo>>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).listAnalysisAttachments(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получить список всех шаблонов анализов
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAnalysisTemplates(xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateAnalysisList>>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).listAnalysisTemplates(xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Изменить анализ
         * @param {number} id 
         * @param {AnalysisResultFill} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyAnalysis(id: number, payload: AnalysisResultFill, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).modifyAnalysis(id, payload, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Отправить письмо в транспортную компанию
         * @param {ShippingNotificationEmailModel} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifyShippingCompany(payload: ShippingNotificationEmailModel, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultStatusModel>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).notifyShippingCompany(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Отметить анализы полученными
         * @param {AnalysisReceived} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAnalysisReceivedStatus(payload: AnalysisReceived, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisReceivedResponse>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).setAnalysisReceivedStatus(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Отметить анализы как отправленные
         * @param {Array<number>} [analysisId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAnalysisSentStatus(analysisId?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).setAnalysisSentStatus(analysisId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AnalysesApi - factory interface
 * @export
 */
export const AnalysesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Приложить файл к анализу
         * @param {number} id 
         * @param {any} file 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachFileToAnalysis(id: number, file: any, xFields?: string, options?: any): AxiosPromise<FileInfo> {
            return AnalysesApiFp(configuration).attachFileToAnalysis(id, file, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Изменить дату забора анализа
         * @param {number} id 
         * @param {AnalysisChangeSampleDate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeSampleDate(id: number, payload: AnalysisChangeSampleDate, xFields?: string, options?: any): AxiosPromise<ResultStatusModel> {
            return AnalysesApiFp(configuration).changeSampleDate(id, payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Создать новый анализ
         * @param {AnalysisResultCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnalysis(payload: AnalysisResultCreate, xFields?: string, options?: any): AxiosPromise<IdModel> {
            return AnalysesApiFp(configuration).createAnalysis(payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Удалить анализ
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnalysisById(id: number, options?: any): AxiosPromise<void> {
            return AnalysesApiFp(configuration).deleteAnalysisById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить детальную информацию по анализу
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisById(id: number, xFields?: string, options?: any): AxiosPromise<AnalysisResultDetailed> {
            return AnalysesApiFp(configuration).getAnalysisById(id, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Напечатать наклейки
         * @param {Array<number>} [analysisId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisLabels(analysisId?: Array<number>, options?: any): AxiosPromise<void> {
            return AnalysesApiFp(configuration).getAnalysisLabels(analysisId, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить детальный шаблон анализа по id
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisTemplateById(id: number, xFields?: string, options?: any): AxiosPromise<TemplateAnalysis> {
            return AnalysesApiFp(configuration).getAnalysisTemplateById(id, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Сформировать сопроводительный список
         * @param {Array<number>} [analysisId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShippingList(analysisId?: Array<number>, options?: any): AxiosPromise<void> {
            return AnalysesApiFp(configuration).getShippingList(analysisId, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить список всех анализов
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {number} [patientId] 
         * @param {Array<number>} [templateAnalysisId] 
         * @param {Array<number>} [affiliationId] 
         * @param {Array<number>} [structureId] 
         * @param {number} [currentStatusId] 
         * @param {string} [sampleDateFrom] 
         * @param {string} [sampleDateTo] 
         * @param {string} [barCode] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAnalyses(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, patientId?: number, templateAnalysisId?: Array<number>, affiliationId?: Array<number>, structureId?: Array<number>, currentStatusId?: number, sampleDateFrom?: string, sampleDateTo?: string, barCode?: string, showDeleted?: boolean, xFields?: string, options?: any): AxiosPromise<AnalysisResultList> {
            return AnalysesApiFp(configuration).listAnalyses(currentPage, perPage, sortDesc, sortBy, patientId, templateAnalysisId, affiliationId, structureId, currentStatusId, sampleDateFrom, sampleDateTo, barCode, showDeleted, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить список файлов, приложенных к анализу
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAnalysisAttachments(id: number, xFields?: string, options?: any): AxiosPromise<Array<FileInfo>> {
            return AnalysesApiFp(configuration).listAnalysisAttachments(id, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить список всех шаблонов анализов
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAnalysisTemplates(xFields?: string, options?: any): AxiosPromise<Array<TemplateAnalysisList>> {
            return AnalysesApiFp(configuration).listAnalysisTemplates(xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Изменить анализ
         * @param {number} id 
         * @param {AnalysisResultFill} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyAnalysis(id: number, payload: AnalysisResultFill, options?: any): AxiosPromise<void> {
            return AnalysesApiFp(configuration).modifyAnalysis(id, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * Отправить письмо в транспортную компанию
         * @param {ShippingNotificationEmailModel} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyShippingCompany(payload: ShippingNotificationEmailModel, xFields?: string, options?: any): AxiosPromise<ResultStatusModel> {
            return AnalysesApiFp(configuration).notifyShippingCompany(payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Отметить анализы полученными
         * @param {AnalysisReceived} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAnalysisReceivedStatus(payload: AnalysisReceived, xFields?: string, options?: any): AxiosPromise<AnalysisReceivedResponse> {
            return AnalysesApiFp(configuration).setAnalysisReceivedStatus(payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Отметить анализы как отправленные
         * @param {Array<number>} [analysisId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAnalysisSentStatus(analysisId?: Array<number>, options?: any): AxiosPromise<void> {
            return AnalysesApiFp(configuration).setAnalysisSentStatus(analysisId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalysesApi - object-oriented interface
 * @export
 * @class AnalysesApi
 * @extends {BaseAPI}
 */
export class AnalysesApi extends BaseAPI {
    /**
     * Приложить файл к анализу
     * @param {number} id 
     * @param {any} file 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public attachFileToAnalysis(id: number, file: any, xFields?: string, options?: any) {
        return AnalysesApiFp(this.configuration).attachFileToAnalysis(id, file, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Изменить дату забора анализа
     * @param {number} id 
     * @param {AnalysisChangeSampleDate} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public changeSampleDate(id: number, payload: AnalysisChangeSampleDate, xFields?: string, options?: any) {
        return AnalysesApiFp(this.configuration).changeSampleDate(id, payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Создать новый анализ
     * @param {AnalysisResultCreate} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public createAnalysis(payload: AnalysisResultCreate, xFields?: string, options?: any) {
        return AnalysesApiFp(this.configuration).createAnalysis(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Удалить анализ
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public deleteAnalysisById(id: number, options?: any) {
        return AnalysesApiFp(this.configuration).deleteAnalysisById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить детальную информацию по анализу
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public getAnalysisById(id: number, xFields?: string, options?: any) {
        return AnalysesApiFp(this.configuration).getAnalysisById(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Напечатать наклейки
     * @param {Array<number>} [analysisId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public getAnalysisLabels(analysisId?: Array<number>, options?: any) {
        return AnalysesApiFp(this.configuration).getAnalysisLabels(analysisId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить детальный шаблон анализа по id
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public getAnalysisTemplateById(id: number, xFields?: string, options?: any) {
        return AnalysesApiFp(this.configuration).getAnalysisTemplateById(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Сформировать сопроводительный список
     * @param {Array<number>} [analysisId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public getShippingList(analysisId?: Array<number>, options?: any) {
        return AnalysesApiFp(this.configuration).getShippingList(analysisId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить список всех анализов
     * @param {number} [currentPage] 
     * @param {number} [perPage] 
     * @param {boolean} [sortDesc] 
     * @param {string} [sortBy] 
     * @param {number} [patientId] 
     * @param {Array<number>} [templateAnalysisId] 
     * @param {Array<number>} [affiliationId] 
     * @param {Array<number>} [structureId] 
     * @param {number} [currentStatusId] 
     * @param {string} [sampleDateFrom] 
     * @param {string} [sampleDateTo] 
     * @param {string} [barCode] 
     * @param {boolean} [showDeleted] 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public listAnalyses(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, patientId?: number, templateAnalysisId?: Array<number>, affiliationId?: Array<number>, structureId?: Array<number>, currentStatusId?: number, sampleDateFrom?: string, sampleDateTo?: string, barCode?: string, showDeleted?: boolean, xFields?: string, options?: any) {
        return AnalysesApiFp(this.configuration).listAnalyses(currentPage, perPage, sortDesc, sortBy, patientId, templateAnalysisId, affiliationId, structureId, currentStatusId, sampleDateFrom, sampleDateTo, barCode, showDeleted, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить список файлов, приложенных к анализу
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public listAnalysisAttachments(id: number, xFields?: string, options?: any) {
        return AnalysesApiFp(this.configuration).listAnalysisAttachments(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить список всех шаблонов анализов
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public listAnalysisTemplates(xFields?: string, options?: any) {
        return AnalysesApiFp(this.configuration).listAnalysisTemplates(xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Изменить анализ
     * @param {number} id 
     * @param {AnalysisResultFill} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public modifyAnalysis(id: number, payload: AnalysisResultFill, options?: any) {
        return AnalysesApiFp(this.configuration).modifyAnalysis(id, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Отправить письмо в транспортную компанию
     * @param {ShippingNotificationEmailModel} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public notifyShippingCompany(payload: ShippingNotificationEmailModel, xFields?: string, options?: any) {
        return AnalysesApiFp(this.configuration).notifyShippingCompany(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Отметить анализы полученными
     * @param {AnalysisReceived} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public setAnalysisReceivedStatus(payload: AnalysisReceived, xFields?: string, options?: any) {
        return AnalysesApiFp(this.configuration).setAnalysisReceivedStatus(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Отметить анализы как отправленные
     * @param {Array<number>} [analysisId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public setAnalysisSentStatus(analysisId?: Array<number>, options?: any) {
        return AnalysesApiFp(this.configuration).setAnalysisSentStatus(analysisId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClassifierApi - axios parameter creator
 * @export
 */
export const ClassifierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassifierByName: async (name: string, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getClassifierByName.');
            }
            const localVarPath = `/classifier/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClassifiers: async (xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/classifier/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClassifierApi - functional programming interface
 * @export
 */
export const ClassifierApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassifierByName(name: string, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Classifier>> {
            const localVarAxiosArgs = await ClassifierApiAxiosParamCreator(configuration).getClassifierByName(name, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClassifiers(xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Classifier>>> {
            const localVarAxiosArgs = await ClassifierApiAxiosParamCreator(configuration).listClassifiers(xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClassifierApi - factory interface
 * @export
 */
export const ClassifierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassifierByName(name: string, xFields?: string, options?: any): AxiosPromise<Classifier> {
            return ClassifierApiFp(configuration).getClassifierByName(name, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClassifiers(xFields?: string, options?: any): AxiosPromise<Array<Classifier>> {
            return ClassifierApiFp(configuration).listClassifiers(xFields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClassifierApi - object-oriented interface
 * @export
 * @class ClassifierApi
 * @extends {BaseAPI}
 */
export class ClassifierApi extends BaseAPI {
    /**
     * 
     * @param {string} name 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassifierApi
     */
    public getClassifierByName(name: string, xFields?: string, options?: any) {
        return ClassifierApiFp(this.configuration).getClassifierByName(name, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassifierApi
     */
    public listClassifiers(xFields?: string, options?: any) {
        return ClassifierApiFp(this.configuration).listClassifiers(xFields, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConsultationsApi - axios parameter creator
 * @export
 */
export const ConsultationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Создать новое сообщение в консультациях
         * @param {ConsultationMessageCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsultationMessage: async (payload: ConsultationMessageCreate, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling createConsultationMessage.');
            }
            const localVarPath = `/consultations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Вывести список сообщений в консультациях
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {number} [patientId] 
         * @param {number} [affiliationId] 
         * @param {boolean} [isRead] 
         * @param {boolean} [isAnswered] 
         * @param {boolean} [isRequest] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConsultationMessages: async (currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, patientId?: number, affiliationId?: number, isRead?: boolean, isAnswered?: boolean, isRequest?: boolean, showDeleted?: boolean, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/consultations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (patientId !== undefined) {
                localVarQueryParameter['patientId'] = patientId;
            }

            if (affiliationId !== undefined) {
                localVarQueryParameter['affiliationId'] = affiliationId;
            }

            if (isRead !== undefined) {
                localVarQueryParameter['isRead'] = isRead;
            }

            if (isAnswered !== undefined) {
                localVarQueryParameter['isAnswered'] = isAnswered;
            }

            if (isRequest !== undefined) {
                localVarQueryParameter['isRequest'] = isRequest;
            }

            if (showDeleted !== undefined) {
                localVarQueryParameter['showDeleted'] = showDeleted;
            }

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Удалить сообщение в консультациях по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeConsultationMessageById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeConsultationMessageById.');
            }
            const localVarPath = `/consultations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Установить флаг \"Прочитано\" для сообщения по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setReadStatusById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling setReadStatusById.');
            }
            const localVarPath = `/consultations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsultationsApi - functional programming interface
 * @export
 */
export const ConsultationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Создать новое сообщение в консультациях
         * @param {ConsultationMessageCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConsultationMessage(payload: ConsultationMessageCreate, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdModel>> {
            const localVarAxiosArgs = await ConsultationsApiAxiosParamCreator(configuration).createConsultationMessage(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Вывести список сообщений в консультациях
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {number} [patientId] 
         * @param {number} [affiliationId] 
         * @param {boolean} [isRead] 
         * @param {boolean} [isAnswered] 
         * @param {boolean} [isRequest] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConsultationMessages(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, patientId?: number, affiliationId?: number, isRead?: boolean, isAnswered?: boolean, isRequest?: boolean, showDeleted?: boolean, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsultationMessageList>> {
            const localVarAxiosArgs = await ConsultationsApiAxiosParamCreator(configuration).listConsultationMessages(currentPage, perPage, sortDesc, sortBy, patientId, affiliationId, isRead, isAnswered, isRequest, showDeleted, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Удалить сообщение в консультациях по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeConsultationMessageById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ConsultationsApiAxiosParamCreator(configuration).removeConsultationMessageById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Установить флаг \"Прочитано\" для сообщения по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setReadStatusById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ConsultationsApiAxiosParamCreator(configuration).setReadStatusById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ConsultationsApi - factory interface
 * @export
 */
export const ConsultationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Создать новое сообщение в консультациях
         * @param {ConsultationMessageCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConsultationMessage(payload: ConsultationMessageCreate, xFields?: string, options?: any): AxiosPromise<IdModel> {
            return ConsultationsApiFp(configuration).createConsultationMessage(payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Вывести список сообщений в консультациях
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {number} [patientId] 
         * @param {number} [affiliationId] 
         * @param {boolean} [isRead] 
         * @param {boolean} [isAnswered] 
         * @param {boolean} [isRequest] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConsultationMessages(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, patientId?: number, affiliationId?: number, isRead?: boolean, isAnswered?: boolean, isRequest?: boolean, showDeleted?: boolean, xFields?: string, options?: any): AxiosPromise<ConsultationMessageList> {
            return ConsultationsApiFp(configuration).listConsultationMessages(currentPage, perPage, sortDesc, sortBy, patientId, affiliationId, isRead, isAnswered, isRequest, showDeleted, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Удалить сообщение в консультациях по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeConsultationMessageById(id: number, options?: any): AxiosPromise<void> {
            return ConsultationsApiFp(configuration).removeConsultationMessageById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Установить флаг \"Прочитано\" для сообщения по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setReadStatusById(id: number, options?: any): AxiosPromise<void> {
            return ConsultationsApiFp(configuration).setReadStatusById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConsultationsApi - object-oriented interface
 * @export
 * @class ConsultationsApi
 * @extends {BaseAPI}
 */
export class ConsultationsApi extends BaseAPI {
    /**
     * Создать новое сообщение в консультациях
     * @param {ConsultationMessageCreate} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationsApi
     */
    public createConsultationMessage(payload: ConsultationMessageCreate, xFields?: string, options?: any) {
        return ConsultationsApiFp(this.configuration).createConsultationMessage(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Вывести список сообщений в консультациях
     * @param {number} [currentPage] 
     * @param {number} [perPage] 
     * @param {boolean} [sortDesc] 
     * @param {string} [sortBy] 
     * @param {number} [patientId] 
     * @param {number} [affiliationId] 
     * @param {boolean} [isRead] 
     * @param {boolean} [isAnswered] 
     * @param {boolean} [isRequest] 
     * @param {boolean} [showDeleted] 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationsApi
     */
    public listConsultationMessages(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, patientId?: number, affiliationId?: number, isRead?: boolean, isAnswered?: boolean, isRequest?: boolean, showDeleted?: boolean, xFields?: string, options?: any) {
        return ConsultationsApiFp(this.configuration).listConsultationMessages(currentPage, perPage, sortDesc, sortBy, patientId, affiliationId, isRead, isAnswered, isRequest, showDeleted, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Удалить сообщение в консультациях по id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationsApi
     */
    public removeConsultationMessageById(id: number, options?: any) {
        return ConsultationsApiFp(this.configuration).removeConsultationMessageById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Установить флаг \"Прочитано\" для сообщения по id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultationsApi
     */
    public setReadStatusById(id: number, options?: any) {
        return ConsultationsApiFp(this.configuration).setReadStatusById(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CoursesApi - axios parameter creator
 * @export
 */
export const CoursesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CourseCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourse: async (payload: CourseCreate, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling createCourse.');
            }
            const localVarPath = `/courses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCourseById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCourseById.');
            }
            const localVarPath = `/courses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadExcelCourseById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadExcelCourseById.');
            }
            const localVarPath = `/courses/download/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseById: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCourseById.');
            }
            const localVarPath = `/courses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseSummaryApi: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCourseSummaryApi.');
            }
            const localVarPath = `/courses/summary/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCourseTemplates: async (xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/templates/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {number} [patientId] 
         * @param {number} [courseTemplateId] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCourses: async (currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, patientId?: number, courseTemplateId?: number, showDeleted?: boolean, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (patientId !== undefined) {
                localVarQueryParameter['patientId'] = patientId;
            }

            if (courseTemplateId !== undefined) {
                localVarQueryParameter['courseTemplateId'] = courseTemplateId;
            }

            if (showDeleted !== undefined) {
                localVarQueryParameter['showDeleted'] = showDeleted;
            }

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoursesApi - functional programming interface
 * @export
 */
export const CoursesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CourseCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCourse(payload: CourseCreate, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdModel>> {
            const localVarAxiosArgs = await CoursesApiAxiosParamCreator(configuration).createCourse(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCourseById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CoursesApiAxiosParamCreator(configuration).deleteCourseById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadExcelCourseById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CoursesApiAxiosParamCreator(configuration).downloadExcelCourseById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseById(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseSummary>> {
            const localVarAxiosArgs = await CoursesApiAxiosParamCreator(configuration).getCourseById(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseSummaryApi(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseSummary>> {
            const localVarAxiosArgs = await CoursesApiAxiosParamCreator(configuration).getCourseSummaryApi(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCourseTemplates(xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseTemplateListItem>>> {
            const localVarAxiosArgs = await CoursesApiAxiosParamCreator(configuration).listCourseTemplates(xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {number} [patientId] 
         * @param {number} [courseTemplateId] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCourses(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, patientId?: number, courseTemplateId?: number, showDeleted?: boolean, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseList>> {
            const localVarAxiosArgs = await CoursesApiAxiosParamCreator(configuration).listCourses(currentPage, perPage, sortDesc, sortBy, patientId, courseTemplateId, showDeleted, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CoursesApi - factory interface
 * @export
 */
export const CoursesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CourseCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCourse(payload: CourseCreate, xFields?: string, options?: any): AxiosPromise<IdModel> {
            return CoursesApiFp(configuration).createCourse(payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCourseById(id: number, options?: any): AxiosPromise<void> {
            return CoursesApiFp(configuration).deleteCourseById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadExcelCourseById(id: number, options?: any): AxiosPromise<void> {
            return CoursesApiFp(configuration).downloadExcelCourseById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseById(id: number, xFields?: string, options?: any): AxiosPromise<CourseSummary> {
            return CoursesApiFp(configuration).getCourseById(id, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseSummaryApi(id: number, xFields?: string, options?: any): AxiosPromise<CourseSummary> {
            return CoursesApiFp(configuration).getCourseSummaryApi(id, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCourseTemplates(xFields?: string, options?: any): AxiosPromise<Array<CourseTemplateListItem>> {
            return CoursesApiFp(configuration).listCourseTemplates(xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {number} [patientId] 
         * @param {number} [courseTemplateId] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCourses(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, patientId?: number, courseTemplateId?: number, showDeleted?: boolean, xFields?: string, options?: any): AxiosPromise<CourseList> {
            return CoursesApiFp(configuration).listCourses(currentPage, perPage, sortDesc, sortBy, patientId, courseTemplateId, showDeleted, xFields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoursesApi - object-oriented interface
 * @export
 * @class CoursesApi
 * @extends {BaseAPI}
 */
export class CoursesApi extends BaseAPI {
    /**
     * 
     * @param {CourseCreate} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public createCourse(payload: CourseCreate, xFields?: string, options?: any) {
        return CoursesApiFp(this.configuration).createCourse(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public deleteCourseById(id: number, options?: any) {
        return CoursesApiFp(this.configuration).deleteCourseById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public downloadExcelCourseById(id: number, options?: any) {
        return CoursesApiFp(this.configuration).downloadExcelCourseById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCourseById(id: number, xFields?: string, options?: any) {
        return CoursesApiFp(this.configuration).getCourseById(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getCourseSummaryApi(id: number, xFields?: string, options?: any) {
        return CoursesApiFp(this.configuration).getCourseSummaryApi(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public listCourseTemplates(xFields?: string, options?: any) {
        return CoursesApiFp(this.configuration).listCourseTemplates(xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [currentPage] 
     * @param {number} [perPage] 
     * @param {boolean} [sortDesc] 
     * @param {string} [sortBy] 
     * @param {number} [patientId] 
     * @param {number} [courseTemplateId] 
     * @param {boolean} [showDeleted] 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public listCourses(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, patientId?: number, courseTemplateId?: number, showDeleted?: boolean, xFields?: string, options?: any) {
        return CoursesApiFp(this.configuration).listCourses(currentPage, perPage, sortDesc, sortBy, patientId, courseTemplateId, showDeleted, xFields, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DiagnosisClassificationApi - axios parameter creator
 * @export
 */
export const DiagnosisClassificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Получить подгруппу диагнозов классификатора ВОЗ
         * @param {number} [parentId] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWHOClassificationChildren: async (parentId?: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/diagnosis-classification/who/children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить дерево классификатора ВОЗ
         * @param {string} [q] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        treeWHOClassification: async (q?: string, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/diagnosis-classification/who/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiagnosisClassificationApi - functional programming interface
 * @export
 */
export const DiagnosisClassificationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Получить подгруппу диагнозов классификатора ВОЗ
         * @param {number} [parentId] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWHOClassificationChildren(parentId?: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WHODiagnosisItem>>> {
            const localVarAxiosArgs = await DiagnosisClassificationApiAxiosParamCreator(configuration).getWHOClassificationChildren(parentId, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получить дерево классификатора ВОЗ
         * @param {string} [q] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async treeWHOClassification(q?: string, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WHOTree>>> {
            const localVarAxiosArgs = await DiagnosisClassificationApiAxiosParamCreator(configuration).treeWHOClassification(q, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DiagnosisClassificationApi - factory interface
 * @export
 */
export const DiagnosisClassificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Получить подгруппу диагнозов классификатора ВОЗ
         * @param {number} [parentId] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWHOClassificationChildren(parentId?: number, xFields?: string, options?: any): AxiosPromise<Array<WHODiagnosisItem>> {
            return DiagnosisClassificationApiFp(configuration).getWHOClassificationChildren(parentId, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить дерево классификатора ВОЗ
         * @param {string} [q] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        treeWHOClassification(q?: string, xFields?: string, options?: any): AxiosPromise<Array<WHOTree>> {
            return DiagnosisClassificationApiFp(configuration).treeWHOClassification(q, xFields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiagnosisClassificationApi - object-oriented interface
 * @export
 * @class DiagnosisClassificationApi
 * @extends {BaseAPI}
 */
export class DiagnosisClassificationApi extends BaseAPI {
    /**
     * Получить подгруппу диагнозов классификатора ВОЗ
     * @param {number} [parentId] 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosisClassificationApi
     */
    public getWHOClassificationChildren(parentId?: number, xFields?: string, options?: any) {
        return DiagnosisClassificationApiFp(this.configuration).getWHOClassificationChildren(parentId, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить дерево классификатора ВОЗ
     * @param {string} [q] 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosisClassificationApi
     */
    public treeWHOClassification(q?: string, xFields?: string, options?: any) {
        return DiagnosisClassificationApiFp(this.configuration).treeWHOClassification(q, xFields, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DumpApi - axios parameter creator
 * @export
 */
export const DumpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAnalysesLong: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dump/analyses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAnalysesStatuses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dump/analyses-statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPatientsAnalysesWide: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dump/patientsanalyses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPatientsWide: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dump/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dump/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DumpApi - functional programming interface
 * @export
 */
export const DumpApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAnalysesLong(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DumpApiAxiosParamCreator(configuration).downloadAnalysesLong(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAnalysesStatuses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DumpApiAxiosParamCreator(configuration).downloadAnalysesStatuses(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPatientsAnalysesWide(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DumpApiAxiosParamCreator(configuration).downloadPatientsAnalysesWide(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPatientsWide(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DumpApiAxiosParamCreator(configuration).downloadPatientsWide(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DumpApiAxiosParamCreator(configuration).downloadUsers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DumpApi - factory interface
 * @export
 */
export const DumpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAnalysesLong(options?: any): AxiosPromise<void> {
            return DumpApiFp(configuration).downloadAnalysesLong(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAnalysesStatuses(options?: any): AxiosPromise<void> {
            return DumpApiFp(configuration).downloadAnalysesStatuses(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPatientsAnalysesWide(options?: any): AxiosPromise<void> {
            return DumpApiFp(configuration).downloadPatientsAnalysesWide(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPatientsWide(options?: any): AxiosPromise<void> {
            return DumpApiFp(configuration).downloadPatientsWide(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsers(options?: any): AxiosPromise<void> {
            return DumpApiFp(configuration).downloadUsers(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DumpApi - object-oriented interface
 * @export
 * @class DumpApi
 * @extends {BaseAPI}
 */
export class DumpApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DumpApi
     */
    public downloadAnalysesLong(options?: any) {
        return DumpApiFp(this.configuration).downloadAnalysesLong(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DumpApi
     */
    public downloadAnalysesStatuses(options?: any) {
        return DumpApiFp(this.configuration).downloadAnalysesStatuses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DumpApi
     */
    public downloadPatientsAnalysesWide(options?: any) {
        return DumpApiFp(this.configuration).downloadPatientsAnalysesWide(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DumpApi
     */
    public downloadPatientsWide(options?: any) {
        return DumpApiFp(this.configuration).downloadPatientsWide(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DumpApi
     */
    public downloadUsers(options?: any) {
        return DumpApiFp(this.configuration).downloadUsers(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EntitiesApi - axios parameter creator
 * @export
 */
export const EntitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Отменить удаление сущности
         * @param {Entity} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undoDelete: async (payload: Entity, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling undoDelete.');
            }
            const localVarPath = `/entities/undo-delete/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EntitiesApi - functional programming interface
 * @export
 */
export const EntitiesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Отменить удаление сущности
         * @param {Entity} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async undoDelete(payload: Entity, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultStatusModel>> {
            const localVarAxiosArgs = await EntitiesApiAxiosParamCreator(configuration).undoDelete(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EntitiesApi - factory interface
 * @export
 */
export const EntitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Отменить удаление сущности
         * @param {Entity} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undoDelete(payload: Entity, xFields?: string, options?: any): AxiosPromise<ResultStatusModel> {
            return EntitiesApiFp(configuration).undoDelete(payload, xFields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EntitiesApi - object-oriented interface
 * @export
 * @class EntitiesApi
 * @extends {BaseAPI}
 */
export class EntitiesApi extends BaseAPI {
    /**
     * Отменить удаление сущности
     * @param {Entity} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    public undoDelete(payload: Entity, xFields?: string, options?: any) {
        return EntitiesApiFp(this.configuration).undoDelete(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilestorageApi - axios parameter creator
 * @export
 */
export const FilestorageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Удалить файл
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileByUUID: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling deleteFileByUUID.');
            }
            const localVarPath = `/filestorage/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Скачать файл
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFileByUUID: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling downloadFileByUUID.');
            }
            const localVarPath = `/filestorage/download/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить данные файла
         * @param {string} uuid 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileInfoByUUID: async (uuid: string, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling getFileInfoByUUID.');
            }
            const localVarPath = `/filestorage/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Загрузить файл во временный каталог и получить данные
         * @param {any} file 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (file: any, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadFile.');
            }
            const localVarPath = `/filestorage/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilestorageApi - functional programming interface
 * @export
 */
export const FilestorageApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Удалить файл
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFileByUUID(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FilestorageApiAxiosParamCreator(configuration).deleteFileByUUID(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Скачать файл
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFileByUUID(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FilestorageApiAxiosParamCreator(configuration).downloadFileByUUID(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получить данные файла
         * @param {string} uuid 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileInfoByUUID(uuid: string, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileInfo>> {
            const localVarAxiosArgs = await FilestorageApiAxiosParamCreator(configuration).getFileInfoByUUID(uuid, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Загрузить файл во временный каталог и получить данные
         * @param {any} file 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(file: any, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileInfo>> {
            const localVarAxiosArgs = await FilestorageApiAxiosParamCreator(configuration).uploadFile(file, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FilestorageApi - factory interface
 * @export
 */
export const FilestorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Удалить файл
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileByUUID(uuid: string, options?: any): AxiosPromise<void> {
            return FilestorageApiFp(configuration).deleteFileByUUID(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Скачать файл
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFileByUUID(uuid: string, options?: any): AxiosPromise<void> {
            return FilestorageApiFp(configuration).downloadFileByUUID(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить данные файла
         * @param {string} uuid 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileInfoByUUID(uuid: string, xFields?: string, options?: any): AxiosPromise<FileInfo> {
            return FilestorageApiFp(configuration).getFileInfoByUUID(uuid, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Загрузить файл во временный каталог и получить данные
         * @param {any} file 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(file: any, xFields?: string, options?: any): AxiosPromise<FileInfo> {
            return FilestorageApiFp(configuration).uploadFile(file, xFields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilestorageApi - object-oriented interface
 * @export
 * @class FilestorageApi
 * @extends {BaseAPI}
 */
export class FilestorageApi extends BaseAPI {
    /**
     * Удалить файл
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilestorageApi
     */
    public deleteFileByUUID(uuid: string, options?: any) {
        return FilestorageApiFp(this.configuration).deleteFileByUUID(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Скачать файл
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilestorageApi
     */
    public downloadFileByUUID(uuid: string, options?: any) {
        return FilestorageApiFp(this.configuration).downloadFileByUUID(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить данные файла
     * @param {string} uuid 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilestorageApi
     */
    public getFileInfoByUUID(uuid: string, xFields?: string, options?: any) {
        return FilestorageApiFp(this.configuration).getFileInfoByUUID(uuid, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Загрузить файл во временный каталог и получить данные
     * @param {any} file 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilestorageApi
     */
    public uploadFile(file: any, xFields?: string, options?: any) {
        return FilestorageApiFp(this.configuration).uploadFile(file, xFields, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoggingApi - axios parameter creator
 * @export
 */
export const LoggingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Получить список логинов в системе
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {number} [userId] 
         * @param {string} [userName] 
         * @param {'login' | 'logout'} [action] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLogins: async (currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, userId?: number, userName?: string, action?: 'login' | 'logout', dateFrom?: string, dateTo?: string, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/logging/logins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoggingApi - functional programming interface
 * @export
 */
export const LoggingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Получить список логинов в системе
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {number} [userId] 
         * @param {string} [userName] 
         * @param {'login' | 'logout'} [action] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLogins(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, userId?: number, userName?: string, action?: 'login' | 'logout', dateFrom?: string, dateTo?: string, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginLogList>> {
            const localVarAxiosArgs = await LoggingApiAxiosParamCreator(configuration).listLogins(currentPage, perPage, sortDesc, sortBy, userId, userName, action, dateFrom, dateTo, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoggingApi - factory interface
 * @export
 */
export const LoggingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Получить список логинов в системе
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {number} [userId] 
         * @param {string} [userName] 
         * @param {'login' | 'logout'} [action] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLogins(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, userId?: number, userName?: string, action?: 'login' | 'logout', dateFrom?: string, dateTo?: string, xFields?: string, options?: any): AxiosPromise<LoginLogList> {
            return LoggingApiFp(configuration).listLogins(currentPage, perPage, sortDesc, sortBy, userId, userName, action, dateFrom, dateTo, xFields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoggingApi - object-oriented interface
 * @export
 * @class LoggingApi
 * @extends {BaseAPI}
 */
export class LoggingApi extends BaseAPI {
    /**
     * Получить список логинов в системе
     * @param {number} [currentPage] 
     * @param {number} [perPage] 
     * @param {boolean} [sortDesc] 
     * @param {string} [sortBy] 
     * @param {number} [userId] 
     * @param {string} [userName] 
     * @param {'login' | 'logout'} [action] 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggingApi
     */
    public listLogins(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, userId?: number, userName?: string, action?: 'login' | 'logout', dateFrom?: string, dateTo?: string, xFields?: string, options?: any) {
        return LoggingApiFp(this.configuration).listLogins(currentPage, perPage, sortDesc, sortBy, userId, userName, action, dateFrom, dateTo, xFields, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientsApi - axios parameter creator
 * @export
 */
export const PatientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Приложить файл к пациенту
         * @param {number} id 
         * @param {any} file 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachFileToPatient: async (id: number, file: any, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling attachFileToPatient.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling attachFileToPatient.');
            }
            const localVarPath = `/patients/{id}/attachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DonorCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDonor: async (payload: DonorCreate, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling createDonor.');
            }
            const localVarPath = `/patients/donors/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FollowUpCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFollowUp: async (payload: FollowUpCreate, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling createFollowUp.');
            }
            const localVarPath = `/patients/followups/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatientCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatient: async (payload: PatientCreate, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling createPatient.');
            }
            const localVarPath = `/patients/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Создать данные о трансплантации
         * @param {number} id 
         * @param {Transplantation} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransplantation: async (id: number, payload: Transplantation, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createTransplantation.');
            }
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling createTransplantation.');
            }
            const localVarPath = `/patients/{id}/transplantation/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDonorById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDonorById.');
            }
            const localVarPath = `/patients/donors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFollowUpById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteFollowUpById.');
            }
            const localVarPath = `/patients/followups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePatientById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePatientById.');
            }
            const localVarPath = `/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Скачать данные пациента в .docx
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPatientById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadPatientById.');
            }
            const localVarPath = `/patients/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDonorById: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDonorById.');
            }
            const localVarPath = `/patients/donors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить список элементов для расчёта HCT-CI
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHCTItemsList: async (xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/hctitems/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientById: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPatientById.');
            }
            const localVarPath = `/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить данные о трансплантации
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransplantation: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTransplantation.');
            }
            const localVarPath = `/patients/{id}/transplantation/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {string} [externalId] 
         * @param {number} [patientId] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDonors: async (currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, externalId?: string, patientId?: number, showDeleted?: boolean, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/donors/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (externalId !== undefined) {
                localVarQueryParameter['externalId'] = externalId;
            }

            if (patientId !== undefined) {
                localVarQueryParameter['patientId'] = patientId;
            }

            if (showDeleted !== undefined) {
                localVarQueryParameter['showDeleted'] = showDeleted;
            }

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {number} [patientId] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFollowUps: async (currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, patientId?: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/followups/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (patientId !== undefined) {
                localVarQueryParameter['patientId'] = patientId;
            }

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить список файлов, приложенных к пациенту
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPatientAttachments: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling listPatientAttachments.');
            }
            const localVarPath = `/patients/{id}/attachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить список пациентов
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {string} [lastName] 
         * @param {number} [affiliationId] 
         * @param {number} [userId] 
         * @param {Array<number>} [structureId] 
         * @param {boolean} [showDeleted] 
         * @param {string} [createdDateTimeFrom] 
         * @param {string} [createdDateTimeTo] 
         * @param {boolean} [isTransplantationPerformed] 
         * @param {Array<number>} [statusId] 
         * @param {number} [whoDiagnosisId] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPatients: async (currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, lastName?: string, affiliationId?: number, userId?: number, structureId?: Array<number>, showDeleted?: boolean, createdDateTimeFrom?: string, createdDateTimeTo?: string, isTransplantationPerformed?: boolean, statusId?: Array<number>, whoDiagnosisId?: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (affiliationId !== undefined) {
                localVarQueryParameter['affiliationId'] = affiliationId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (structureId) {
                localVarQueryParameter['structureId'] = structureId.join(COLLECTION_FORMATS.csv);
            }

            if (showDeleted !== undefined) {
                localVarQueryParameter['showDeleted'] = showDeleted;
            }

            if (createdDateTimeFrom !== undefined) {
                localVarQueryParameter['createdDateTimeFrom'] = (createdDateTimeFrom as any instanceof Date) ?
                    (createdDateTimeFrom as any).toISOString() :
                    createdDateTimeFrom;
            }

            if (createdDateTimeTo !== undefined) {
                localVarQueryParameter['createdDateTimeTo'] = (createdDateTimeTo as any instanceof Date) ?
                    (createdDateTimeTo as any).toISOString() :
                    createdDateTimeTo;
            }

            if (isTransplantationPerformed !== undefined) {
                localVarQueryParameter['isTransplantationPerformed'] = isTransplantationPerformed;
            }

            if (statusId) {
                localVarQueryParameter['statusId'] = statusId.join(COLLECTION_FORMATS.csv);
            }

            if (whoDiagnosisId !== undefined) {
                localVarQueryParameter['whoDiagnosisId'] = whoDiagnosisId;
            }

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Список пациентов без прикрепления
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnattachedPatients: async (xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/patients/unattached`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Donor} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDonor: async (id: number, payload: Donor, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyDonor.');
            }
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling modifyDonor.');
            }
            const localVarPath = `/patients/donors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Изменить данные пациента
         * @param {number} id 
         * @param {PatientModify} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPatient: async (id: number, payload: PatientModify, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyPatient.');
            }
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling modifyPatient.');
            }
            const localVarPath = `/patients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Изменить внешний идентификатор пациента
         * @param {number} id 
         * @param {PatientModifyExternalId} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPatientExternalId: async (id: number, payload: PatientModifyExternalId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyPatientExternalId.');
            }
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling modifyPatientExternalId.');
            }
            const localVarPath = `/patients/{id}/external-id`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Изменить диагноз по ВОЗ пациента
         * @param {number} id 
         * @param {PatientModifyWHODiagnosisId} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPatientWHODiagnosisId: async (id: number, payload: PatientModifyWHODiagnosisId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyPatientWHODiagnosisId.');
            }
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling modifyPatientWHODiagnosisId.');
            }
            const localVarPath = `/patients/{id}/who-diagnosis-id`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Изменить данные о трансплантации
         * @param {number} id 
         * @param {Transplantation} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTransplantation: async (id: number, payload: Transplantation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyTransplantation.');
            }
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling modifyTransplantation.');
            }
            const localVarPath = `/patients/{id}/transplantation/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Установить прикрепреление пациента
         * @param {number} id 
         * @param {IdModel} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAffiliationToId: async (id: number, payload: IdModel, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling setAffiliationToId.');
            }
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling setAffiliationToId.');
            }
            const localVarPath = `/patients/{id}/affiliation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Обнулить прикрепреление пациента
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAffiliationToNull: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling setAffiliationToNull.');
            }
            const localVarPath = `/patients/{id}/affiliation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientsApi - functional programming interface
 * @export
 */
export const PatientsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Приложить файл к пациенту
         * @param {number} id 
         * @param {any} file 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachFileToPatient(id: number, file: any, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileInfo>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).attachFileToPatient(id, file, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DonorCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDonor(payload: DonorCreate, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdModel>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).createDonor(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {FollowUpCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFollowUp(payload: FollowUpCreate, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdModel>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).createFollowUp(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {PatientCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPatient(payload: PatientCreate, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdModel>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).createPatient(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Создать данные о трансплантации
         * @param {number} id 
         * @param {Transplantation} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransplantation(id: number, payload: Transplantation, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdModel>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).createTransplantation(id, payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDonorById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).deleteDonorById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFollowUpById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).deleteFollowUpById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePatientById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).deletePatientById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Скачать данные пациента в .docx
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPatientById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).downloadPatientById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDonorById(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Donor>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).getDonorById(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получить список элементов для расчёта HCT-CI
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHCTItemsList(xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HCTItem>>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).getHCTItemsList(xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientById(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Patient>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).getPatientById(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получить данные о трансплантации
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransplantation(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transplantation>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).getTransplantation(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {string} [externalId] 
         * @param {number} [patientId] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDonors(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, externalId?: string, patientId?: number, showDeleted?: boolean, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DonorList>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).listDonors(currentPage, perPage, sortDesc, sortBy, externalId, patientId, showDeleted, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {number} [patientId] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFollowUps(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, patientId?: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FollowUpList>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).listFollowUps(currentPage, perPage, sortDesc, sortBy, patientId, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получить список файлов, приложенных к пациенту
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPatientAttachments(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileInfo>>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).listPatientAttachments(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получить список пациентов
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {string} [lastName] 
         * @param {number} [affiliationId] 
         * @param {number} [userId] 
         * @param {Array<number>} [structureId] 
         * @param {boolean} [showDeleted] 
         * @param {string} [createdDateTimeFrom] 
         * @param {string} [createdDateTimeTo] 
         * @param {boolean} [isTransplantationPerformed] 
         * @param {Array<number>} [statusId] 
         * @param {number} [whoDiagnosisId] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPatients(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, lastName?: string, affiliationId?: number, userId?: number, structureId?: Array<number>, showDeleted?: boolean, createdDateTimeFrom?: string, createdDateTimeTo?: string, isTransplantationPerformed?: boolean, statusId?: Array<number>, whoDiagnosisId?: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatientList>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).listPatients(currentPage, perPage, sortDesc, sortBy, lastName, affiliationId, userId, structureId, showDeleted, createdDateTimeFrom, createdDateTimeTo, isTransplantationPerformed, statusId, whoDiagnosisId, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Список пациентов без прикрепления
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUnattachedPatients(xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientListItem>>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).listUnattachedPatients(xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Donor} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyDonor(id: number, payload: Donor, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).modifyDonor(id, payload, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Изменить данные пациента
         * @param {number} id 
         * @param {PatientModify} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyPatient(id: number, payload: PatientModify, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).modifyPatient(id, payload, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Изменить внешний идентификатор пациента
         * @param {number} id 
         * @param {PatientModifyExternalId} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyPatientExternalId(id: number, payload: PatientModifyExternalId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).modifyPatientExternalId(id, payload, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Изменить диагноз по ВОЗ пациента
         * @param {number} id 
         * @param {PatientModifyWHODiagnosisId} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyPatientWHODiagnosisId(id: number, payload: PatientModifyWHODiagnosisId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).modifyPatientWHODiagnosisId(id, payload, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Изменить данные о трансплантации
         * @param {number} id 
         * @param {Transplantation} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyTransplantation(id: number, payload: Transplantation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).modifyTransplantation(id, payload, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Установить прикрепреление пациента
         * @param {number} id 
         * @param {IdModel} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAffiliationToId(id: number, payload: IdModel, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultStatusModel>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).setAffiliationToId(id, payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Обнулить прикрепреление пациента
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAffiliationToNull(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultStatusModel>> {
            const localVarAxiosArgs = await PatientsApiAxiosParamCreator(configuration).setAffiliationToNull(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PatientsApi - factory interface
 * @export
 */
export const PatientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Приложить файл к пациенту
         * @param {number} id 
         * @param {any} file 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachFileToPatient(id: number, file: any, xFields?: string, options?: any): AxiosPromise<FileInfo> {
            return PatientsApiFp(configuration).attachFileToPatient(id, file, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DonorCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDonor(payload: DonorCreate, xFields?: string, options?: any): AxiosPromise<IdModel> {
            return PatientsApiFp(configuration).createDonor(payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FollowUpCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFollowUp(payload: FollowUpCreate, xFields?: string, options?: any): AxiosPromise<IdModel> {
            return PatientsApiFp(configuration).createFollowUp(payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PatientCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatient(payload: PatientCreate, xFields?: string, options?: any): AxiosPromise<IdModel> {
            return PatientsApiFp(configuration).createPatient(payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Создать данные о трансплантации
         * @param {number} id 
         * @param {Transplantation} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransplantation(id: number, payload: Transplantation, xFields?: string, options?: any): AxiosPromise<IdModel> {
            return PatientsApiFp(configuration).createTransplantation(id, payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDonorById(id: number, options?: any): AxiosPromise<void> {
            return PatientsApiFp(configuration).deleteDonorById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFollowUpById(id: number, options?: any): AxiosPromise<void> {
            return PatientsApiFp(configuration).deleteFollowUpById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePatientById(id: number, options?: any): AxiosPromise<void> {
            return PatientsApiFp(configuration).deletePatientById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Скачать данные пациента в .docx
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPatientById(id: number, options?: any): AxiosPromise<void> {
            return PatientsApiFp(configuration).downloadPatientById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDonorById(id: number, xFields?: string, options?: any): AxiosPromise<Donor> {
            return PatientsApiFp(configuration).getDonorById(id, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить список элементов для расчёта HCT-CI
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHCTItemsList(xFields?: string, options?: any): AxiosPromise<Array<HCTItem>> {
            return PatientsApiFp(configuration).getHCTItemsList(xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientById(id: number, xFields?: string, options?: any): AxiosPromise<Patient> {
            return PatientsApiFp(configuration).getPatientById(id, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить данные о трансплантации
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransplantation(id: number, xFields?: string, options?: any): AxiosPromise<Transplantation> {
            return PatientsApiFp(configuration).getTransplantation(id, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {string} [externalId] 
         * @param {number} [patientId] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDonors(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, externalId?: string, patientId?: number, showDeleted?: boolean, xFields?: string, options?: any): AxiosPromise<DonorList> {
            return PatientsApiFp(configuration).listDonors(currentPage, perPage, sortDesc, sortBy, externalId, patientId, showDeleted, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {number} [patientId] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFollowUps(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, patientId?: number, xFields?: string, options?: any): AxiosPromise<FollowUpList> {
            return PatientsApiFp(configuration).listFollowUps(currentPage, perPage, sortDesc, sortBy, patientId, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить список файлов, приложенных к пациенту
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPatientAttachments(id: number, xFields?: string, options?: any): AxiosPromise<Array<FileInfo>> {
            return PatientsApiFp(configuration).listPatientAttachments(id, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить список пациентов
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {string} [lastName] 
         * @param {number} [affiliationId] 
         * @param {number} [userId] 
         * @param {Array<number>} [structureId] 
         * @param {boolean} [showDeleted] 
         * @param {string} [createdDateTimeFrom] 
         * @param {string} [createdDateTimeTo] 
         * @param {boolean} [isTransplantationPerformed] 
         * @param {Array<number>} [statusId] 
         * @param {number} [whoDiagnosisId] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPatients(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, lastName?: string, affiliationId?: number, userId?: number, structureId?: Array<number>, showDeleted?: boolean, createdDateTimeFrom?: string, createdDateTimeTo?: string, isTransplantationPerformed?: boolean, statusId?: Array<number>, whoDiagnosisId?: number, xFields?: string, options?: any): AxiosPromise<PatientList> {
            return PatientsApiFp(configuration).listPatients(currentPage, perPage, sortDesc, sortBy, lastName, affiliationId, userId, structureId, showDeleted, createdDateTimeFrom, createdDateTimeTo, isTransplantationPerformed, statusId, whoDiagnosisId, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Список пациентов без прикрепления
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnattachedPatients(xFields?: string, options?: any): AxiosPromise<Array<PatientListItem>> {
            return PatientsApiFp(configuration).listUnattachedPatients(xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Donor} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDonor(id: number, payload: Donor, options?: any): AxiosPromise<void> {
            return PatientsApiFp(configuration).modifyDonor(id, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * Изменить данные пациента
         * @param {number} id 
         * @param {PatientModify} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPatient(id: number, payload: PatientModify, options?: any): AxiosPromise<void> {
            return PatientsApiFp(configuration).modifyPatient(id, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * Изменить внешний идентификатор пациента
         * @param {number} id 
         * @param {PatientModifyExternalId} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPatientExternalId(id: number, payload: PatientModifyExternalId, options?: any): AxiosPromise<void> {
            return PatientsApiFp(configuration).modifyPatientExternalId(id, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * Изменить диагноз по ВОЗ пациента
         * @param {number} id 
         * @param {PatientModifyWHODiagnosisId} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPatientWHODiagnosisId(id: number, payload: PatientModifyWHODiagnosisId, options?: any): AxiosPromise<void> {
            return PatientsApiFp(configuration).modifyPatientWHODiagnosisId(id, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * Изменить данные о трансплантации
         * @param {number} id 
         * @param {Transplantation} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTransplantation(id: number, payload: Transplantation, options?: any): AxiosPromise<void> {
            return PatientsApiFp(configuration).modifyTransplantation(id, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * Установить прикрепреление пациента
         * @param {number} id 
         * @param {IdModel} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAffiliationToId(id: number, payload: IdModel, xFields?: string, options?: any): AxiosPromise<ResultStatusModel> {
            return PatientsApiFp(configuration).setAffiliationToId(id, payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Обнулить прикрепреление пациента
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAffiliationToNull(id: number, xFields?: string, options?: any): AxiosPromise<ResultStatusModel> {
            return PatientsApiFp(configuration).setAffiliationToNull(id, xFields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientsApi - object-oriented interface
 * @export
 * @class PatientsApi
 * @extends {BaseAPI}
 */
export class PatientsApi extends BaseAPI {
    /**
     * Приложить файл к пациенту
     * @param {number} id 
     * @param {any} file 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public attachFileToPatient(id: number, file: any, xFields?: string, options?: any) {
        return PatientsApiFp(this.configuration).attachFileToPatient(id, file, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DonorCreate} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public createDonor(payload: DonorCreate, xFields?: string, options?: any) {
        return PatientsApiFp(this.configuration).createDonor(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FollowUpCreate} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public createFollowUp(payload: FollowUpCreate, xFields?: string, options?: any) {
        return PatientsApiFp(this.configuration).createFollowUp(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PatientCreate} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public createPatient(payload: PatientCreate, xFields?: string, options?: any) {
        return PatientsApiFp(this.configuration).createPatient(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Создать данные о трансплантации
     * @param {number} id 
     * @param {Transplantation} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public createTransplantation(id: number, payload: Transplantation, xFields?: string, options?: any) {
        return PatientsApiFp(this.configuration).createTransplantation(id, payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public deleteDonorById(id: number, options?: any) {
        return PatientsApiFp(this.configuration).deleteDonorById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public deleteFollowUpById(id: number, options?: any) {
        return PatientsApiFp(this.configuration).deleteFollowUpById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public deletePatientById(id: number, options?: any) {
        return PatientsApiFp(this.configuration).deletePatientById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Скачать данные пациента в .docx
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public downloadPatientById(id: number, options?: any) {
        return PatientsApiFp(this.configuration).downloadPatientById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public getDonorById(id: number, xFields?: string, options?: any) {
        return PatientsApiFp(this.configuration).getDonorById(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить список элементов для расчёта HCT-CI
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public getHCTItemsList(xFields?: string, options?: any) {
        return PatientsApiFp(this.configuration).getHCTItemsList(xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public getPatientById(id: number, xFields?: string, options?: any) {
        return PatientsApiFp(this.configuration).getPatientById(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить данные о трансплантации
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public getTransplantation(id: number, xFields?: string, options?: any) {
        return PatientsApiFp(this.configuration).getTransplantation(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [currentPage] 
     * @param {number} [perPage] 
     * @param {boolean} [sortDesc] 
     * @param {string} [sortBy] 
     * @param {string} [externalId] 
     * @param {number} [patientId] 
     * @param {boolean} [showDeleted] 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public listDonors(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, externalId?: string, patientId?: number, showDeleted?: boolean, xFields?: string, options?: any) {
        return PatientsApiFp(this.configuration).listDonors(currentPage, perPage, sortDesc, sortBy, externalId, patientId, showDeleted, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [currentPage] 
     * @param {number} [perPage] 
     * @param {boolean} [sortDesc] 
     * @param {string} [sortBy] 
     * @param {number} [patientId] 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public listFollowUps(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, patientId?: number, xFields?: string, options?: any) {
        return PatientsApiFp(this.configuration).listFollowUps(currentPage, perPage, sortDesc, sortBy, patientId, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить список файлов, приложенных к пациенту
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public listPatientAttachments(id: number, xFields?: string, options?: any) {
        return PatientsApiFp(this.configuration).listPatientAttachments(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить список пациентов
     * @param {number} [currentPage] 
     * @param {number} [perPage] 
     * @param {boolean} [sortDesc] 
     * @param {string} [sortBy] 
     * @param {string} [lastName] 
     * @param {number} [affiliationId] 
     * @param {number} [userId] 
     * @param {Array<number>} [structureId] 
     * @param {boolean} [showDeleted] 
     * @param {string} [createdDateTimeFrom] 
     * @param {string} [createdDateTimeTo] 
     * @param {boolean} [isTransplantationPerformed] 
     * @param {Array<number>} [statusId] 
     * @param {number} [whoDiagnosisId] 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public listPatients(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, lastName?: string, affiliationId?: number, userId?: number, structureId?: Array<number>, showDeleted?: boolean, createdDateTimeFrom?: string, createdDateTimeTo?: string, isTransplantationPerformed?: boolean, statusId?: Array<number>, whoDiagnosisId?: number, xFields?: string, options?: any) {
        return PatientsApiFp(this.configuration).listPatients(currentPage, perPage, sortDesc, sortBy, lastName, affiliationId, userId, structureId, showDeleted, createdDateTimeFrom, createdDateTimeTo, isTransplantationPerformed, statusId, whoDiagnosisId, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Список пациентов без прикрепления
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public listUnattachedPatients(xFields?: string, options?: any) {
        return PatientsApiFp(this.configuration).listUnattachedPatients(xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Donor} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public modifyDonor(id: number, payload: Donor, options?: any) {
        return PatientsApiFp(this.configuration).modifyDonor(id, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Изменить данные пациента
     * @param {number} id 
     * @param {PatientModify} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public modifyPatient(id: number, payload: PatientModify, options?: any) {
        return PatientsApiFp(this.configuration).modifyPatient(id, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Изменить внешний идентификатор пациента
     * @param {number} id 
     * @param {PatientModifyExternalId} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public modifyPatientExternalId(id: number, payload: PatientModifyExternalId, options?: any) {
        return PatientsApiFp(this.configuration).modifyPatientExternalId(id, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Изменить диагноз по ВОЗ пациента
     * @param {number} id 
     * @param {PatientModifyWHODiagnosisId} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public modifyPatientWHODiagnosisId(id: number, payload: PatientModifyWHODiagnosisId, options?: any) {
        return PatientsApiFp(this.configuration).modifyPatientWHODiagnosisId(id, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Изменить данные о трансплантации
     * @param {number} id 
     * @param {Transplantation} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public modifyTransplantation(id: number, payload: Transplantation, options?: any) {
        return PatientsApiFp(this.configuration).modifyTransplantation(id, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Установить прикрепреление пациента
     * @param {number} id 
     * @param {IdModel} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public setAffiliationToId(id: number, payload: IdModel, xFields?: string, options?: any) {
        return PatientsApiFp(this.configuration).setAffiliationToId(id, payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Обнулить прикрепреление пациента
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public setAffiliationToNull(id: number, xFields?: string, options?: any) {
        return PatientsApiFp(this.configuration).setAffiliationToNull(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RisksApi - axios parameter creator
 * @export
 */
export const RisksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Рассчитать риск для пациента
         * @param {number} patientId 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskResult: async (patientId: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            if (patientId === null || patientId === undefined) {
                throw new RequiredError('patientId','Required parameter patientId was null or undefined when calling getRiskResult.');
            }
            const localVarPath = `/risks/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить список элементов для расчёта риска
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRiskTemplate: async (xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/risks/template/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RisksApi - functional programming interface
 * @export
 */
export const RisksApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Рассчитать риск для пациента
         * @param {number} patientId 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRiskResult(patientId: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskResult>> {
            const localVarAxiosArgs = await RisksApiAxiosParamCreator(configuration).getRiskResult(patientId, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получить список элементов для расчёта риска
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRiskTemplate(xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RiskTemplate>>> {
            const localVarAxiosArgs = await RisksApiAxiosParamCreator(configuration).listRiskTemplate(xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RisksApi - factory interface
 * @export
 */
export const RisksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Рассчитать риск для пациента
         * @param {number} patientId 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskResult(patientId: number, xFields?: string, options?: any): AxiosPromise<RiskResult> {
            return RisksApiFp(configuration).getRiskResult(patientId, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить список элементов для расчёта риска
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRiskTemplate(xFields?: string, options?: any): AxiosPromise<Array<RiskTemplate>> {
            return RisksApiFp(configuration).listRiskTemplate(xFields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RisksApi - object-oriented interface
 * @export
 * @class RisksApi
 * @extends {BaseAPI}
 */
export class RisksApi extends BaseAPI {
    /**
     * Рассчитать риск для пациента
     * @param {number} patientId 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RisksApi
     */
    public getRiskResult(patientId: number, xFields?: string, options?: any) {
        return RisksApiFp(this.configuration).getRiskResult(patientId, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить список элементов для расчёта риска
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RisksApi
     */
    public listRiskTemplate(xFields?: string, options?: any) {
        return RisksApiFp(this.configuration).listRiskTemplate(xFields, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShippingCompaniesApi - axios parameter creator
 * @export
 */
export const ShippingCompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Создать новую транспортную компанию
         * @param {ShippingCompanyCreateModel} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShippingCompany: async (payload: ShippingCompanyCreateModel, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling createShippingCompany.');
            }
            const localVarPath = `/shipping-companies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShippingCompany: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteShippingCompany.');
            }
            const localVarPath = `/shipping-companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить данные о транспортной компании
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShippingCompanyById: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getShippingCompanyById.');
            }
            const localVarPath = `/shipping-companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить список транспортных компаний
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listShippingCompanies: async (xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/shipping-companies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Изменить данные транспортной компании
         * @param {number} id 
         * @param {ShippingCompanyModifyModel} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyShippingCompany: async (id: number, payload: ShippingCompanyModifyModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyShippingCompany.');
            }
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling modifyShippingCompany.');
            }
            const localVarPath = `/shipping-companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShippingCompaniesApi - functional programming interface
 * @export
 */
export const ShippingCompaniesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Создать новую транспортную компанию
         * @param {ShippingCompanyCreateModel} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createShippingCompany(payload: ShippingCompanyCreateModel, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdModel>> {
            const localVarAxiosArgs = await ShippingCompaniesApiAxiosParamCreator(configuration).createShippingCompany(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteShippingCompany(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ShippingCompaniesApiAxiosParamCreator(configuration).deleteShippingCompany(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получить данные о транспортной компании
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShippingCompanyById(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShippingCompanyFullModel>> {
            const localVarAxiosArgs = await ShippingCompaniesApiAxiosParamCreator(configuration).getShippingCompanyById(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получить список транспортных компаний
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listShippingCompanies(xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShippingCompanyFullModel>>> {
            const localVarAxiosArgs = await ShippingCompaniesApiAxiosParamCreator(configuration).listShippingCompanies(xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Изменить данные транспортной компании
         * @param {number} id 
         * @param {ShippingCompanyModifyModel} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyShippingCompany(id: number, payload: ShippingCompanyModifyModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ShippingCompaniesApiAxiosParamCreator(configuration).modifyShippingCompany(id, payload, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ShippingCompaniesApi - factory interface
 * @export
 */
export const ShippingCompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Создать новую транспортную компанию
         * @param {ShippingCompanyCreateModel} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShippingCompany(payload: ShippingCompanyCreateModel, xFields?: string, options?: any): AxiosPromise<IdModel> {
            return ShippingCompaniesApiFp(configuration).createShippingCompany(payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShippingCompany(id: number, options?: any): AxiosPromise<void> {
            return ShippingCompaniesApiFp(configuration).deleteShippingCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить данные о транспортной компании
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShippingCompanyById(id: number, xFields?: string, options?: any): AxiosPromise<ShippingCompanyFullModel> {
            return ShippingCompaniesApiFp(configuration).getShippingCompanyById(id, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить список транспортных компаний
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listShippingCompanies(xFields?: string, options?: any): AxiosPromise<Array<ShippingCompanyFullModel>> {
            return ShippingCompaniesApiFp(configuration).listShippingCompanies(xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Изменить данные транспортной компании
         * @param {number} id 
         * @param {ShippingCompanyModifyModel} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyShippingCompany(id: number, payload: ShippingCompanyModifyModel, options?: any): AxiosPromise<void> {
            return ShippingCompaniesApiFp(configuration).modifyShippingCompany(id, payload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShippingCompaniesApi - object-oriented interface
 * @export
 * @class ShippingCompaniesApi
 * @extends {BaseAPI}
 */
export class ShippingCompaniesApi extends BaseAPI {
    /**
     * Создать новую транспортную компанию
     * @param {ShippingCompanyCreateModel} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCompaniesApi
     */
    public createShippingCompany(payload: ShippingCompanyCreateModel, xFields?: string, options?: any) {
        return ShippingCompaniesApiFp(this.configuration).createShippingCompany(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCompaniesApi
     */
    public deleteShippingCompany(id: number, options?: any) {
        return ShippingCompaniesApiFp(this.configuration).deleteShippingCompany(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить данные о транспортной компании
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCompaniesApi
     */
    public getShippingCompanyById(id: number, xFields?: string, options?: any) {
        return ShippingCompaniesApiFp(this.configuration).getShippingCompanyById(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить список транспортных компаний
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCompaniesApi
     */
    public listShippingCompanies(xFields?: string, options?: any) {
        return ShippingCompaniesApiFp(this.configuration).listShippingCompanies(xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Изменить данные транспортной компании
     * @param {number} id 
     * @param {ShippingCompanyModifyModel} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingCompaniesApi
     */
    public modifyShippingCompany(id: number, payload: ShippingCompanyModifyModel, options?: any) {
        return ShippingCompaniesApiFp(this.configuration).modifyShippingCompany(id, payload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Получить статистику по анализам
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {'year' | 'quarter' | 'month' | 'week'} [timeStep] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysesStatistics: async (dateFrom?: string, dateTo?: string, timeStep?: 'year' | 'quarter' | 'month' | 'week', xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistics/analyses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }

            if (timeStep !== undefined) {
                localVarQueryParameter['timeStep'] = timeStep;
            }

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить статистику по входам в систему
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {'year' | 'quarter' | 'month' | 'week'} [timeStep] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginsStatistics: async (dateFrom?: string, dateTo?: string, timeStep?: 'year' | 'quarter' | 'month' | 'week', xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistics/logins/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }

            if (timeStep !== undefined) {
                localVarQueryParameter['timeStep'] = timeStep;
            }

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить статистику по пациентам
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {'year' | 'quarter' | 'month' | 'week'} [timeStep] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsStatistics: async (dateFrom?: string, dateTo?: string, timeStep?: 'year' | 'quarter' | 'month' | 'week', xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/statistics/patients/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }

            if (timeStep !== undefined) {
                localVarQueryParameter['timeStep'] = timeStep;
            }

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Получить статистику по анализам
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {'year' | 'quarter' | 'month' | 'week'} [timeStep] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalysesStatistics(dateFrom?: string, dateTo?: string, timeStep?: 'year' | 'quarter' | 'month' | 'week', xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Analyses>> {
            const localVarAxiosArgs = await StatisticsApiAxiosParamCreator(configuration).getAnalysesStatistics(dateFrom, dateTo, timeStep, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получить статистику по входам в систему
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {'year' | 'quarter' | 'month' | 'week'} [timeStep] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoginsStatistics(dateFrom?: string, dateTo?: string, timeStep?: 'year' | 'quarter' | 'month' | 'week', xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Logins>> {
            const localVarAxiosArgs = await StatisticsApiAxiosParamCreator(configuration).getLoginsStatistics(dateFrom, dateTo, timeStep, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Получить статистику по пациентам
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {'year' | 'quarter' | 'month' | 'week'} [timeStep] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientsStatistics(dateFrom?: string, dateTo?: string, timeStep?: 'year' | 'quarter' | 'month' | 'week', xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Patients>> {
            const localVarAxiosArgs = await StatisticsApiAxiosParamCreator(configuration).getPatientsStatistics(dateFrom, dateTo, timeStep, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Получить статистику по анализам
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {'year' | 'quarter' | 'month' | 'week'} [timeStep] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysesStatistics(dateFrom?: string, dateTo?: string, timeStep?: 'year' | 'quarter' | 'month' | 'week', xFields?: string, options?: any): AxiosPromise<Analyses> {
            return StatisticsApiFp(configuration).getAnalysesStatistics(dateFrom, dateTo, timeStep, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить статистику по входам в систему
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {'year' | 'quarter' | 'month' | 'week'} [timeStep] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginsStatistics(dateFrom?: string, dateTo?: string, timeStep?: 'year' | 'quarter' | 'month' | 'week', xFields?: string, options?: any): AxiosPromise<Logins> {
            return StatisticsApiFp(configuration).getLoginsStatistics(dateFrom, dateTo, timeStep, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить статистику по пациентам
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {'year' | 'quarter' | 'month' | 'week'} [timeStep] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsStatistics(dateFrom?: string, dateTo?: string, timeStep?: 'year' | 'quarter' | 'month' | 'week', xFields?: string, options?: any): AxiosPromise<Patients> {
            return StatisticsApiFp(configuration).getPatientsStatistics(dateFrom, dateTo, timeStep, xFields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
    /**
     * Получить статистику по анализам
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {'year' | 'quarter' | 'month' | 'week'} [timeStep] 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getAnalysesStatistics(dateFrom?: string, dateTo?: string, timeStep?: 'year' | 'quarter' | 'month' | 'week', xFields?: string, options?: any) {
        return StatisticsApiFp(this.configuration).getAnalysesStatistics(dateFrom, dateTo, timeStep, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить статистику по входам в систему
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {'year' | 'quarter' | 'month' | 'week'} [timeStep] 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getLoginsStatistics(dateFrom?: string, dateTo?: string, timeStep?: 'year' | 'quarter' | 'month' | 'week', xFields?: string, options?: any) {
        return StatisticsApiFp(this.configuration).getLoginsStatistics(dateFrom, dateTo, timeStep, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить статистику по пациентам
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {'year' | 'quarter' | 'month' | 'week'} [timeStep] 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getPatientsStatistics(dateFrom?: string, dateTo?: string, timeStep?: 'year' | 'quarter' | 'month' | 'week', xFields?: string, options?: any) {
        return StatisticsApiFp(this.configuration).getPatientsStatistics(dateFrom, dateTo, timeStep, xFields, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LaboratoryProfile} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLaboratoryProfile: async (payload: LaboratoryProfile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling addLaboratoryProfile.');
            }
            const localVarPath = `/users/laboratoryprofiles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Подписать на событие
         * @param {number} affiliationId 
         * @param {AffiliationSubscribe} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubscription: async (affiliationId: number, payload: AffiliationSubscribe, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'affiliationId' is not null or undefined
            if (affiliationId === null || affiliationId === undefined) {
                throw new RequiredError('affiliationId','Required parameter affiliationId was null or undefined when calling addSubscription.');
            }
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling addSubscription.');
            }
            const localVarPath = `/users/affiliations/{affiliation_id}/subscriptions`
                .replace(`{${"affiliation_id"}}`, encodeURIComponent(String(affiliationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Изменить пароль пользователя
         * @param {ChangePassword} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (payload: ChangePassword, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling changePassword.');
            }
            const localVarPath = `/users/changePassword/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AffiliationCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAffiliation: async (payload: AffiliationCreate, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling createAffiliation.');
            }
            const localVarPath = `/users/affiliations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StructureCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStructure: async (payload: StructureCreate, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling createStructure.');
            }
            const localVarPath = `/users/structures/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (payload: UserCreate, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling createUser.');
            }
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAffiliationById: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAffiliationById.');
            }
            const localVarPath = `/users/affiliations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStructureById: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteStructureById.');
            }
            const localVarPath = `/users/structures/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserById.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAffiliationById: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAffiliationById.');
            }
            const localVarPath = `/users/affiliations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructureAndChildrenIds: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getStructureAndChildrenIds.');
            }
            const localVarPath = `/users/structures/children/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructureAndParents: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getStructureAndParents.');
            }
            const localVarPath = `/users/structures/parents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructurebyId: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getStructurebyId.');
            }
            const localVarPath = `/users/structures/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructuresTypesRoles: async (xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/structures/types-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Возвращает минимальное поддерево на заданных вершинах
         * @param {Array<number>} [structureId] 
         * @param {boolean} [prune] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubtree: async (structureId?: Array<number>, prune?: boolean, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/structures/tree/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (structureId) {
                localVarQueryParameter['structureId'] = structureId.join(COLLECTION_FORMATS.csv);
            }

            if (prune !== undefined) {
                localVarQueryParameter['prune'] = prune;
            }

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (id: number, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserById.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [structureId] 
         * @param {Array<number>} [roleId] 
         * @param {Array<number>} [userId] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAffiliations: async (structureId?: Array<number>, roleId?: Array<number>, userId?: Array<number>, showDeleted?: boolean, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/affiliations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (structureId) {
                localVarQueryParameter['structureId'] = structureId.join(COLLECTION_FORMATS.csv);
            }

            if (roleId) {
                localVarQueryParameter['roleId'] = roleId.join(COLLECTION_FORMATS.csv);
            }

            if (userId) {
                localVarQueryParameter['userId'] = userId.join(COLLECTION_FORMATS.csv);
            }

            if (showDeleted !== undefined) {
                localVarQueryParameter['showDeleted'] = showDeleted;
            }

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoles: async (xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStructures: async (xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/structures/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Список всех событий для подписки
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubscriptionEvents: async (xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/affiliations/subscription-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {string} [searchText] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUser: async (currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, searchText?: string, showDeleted?: boolean, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (showDeleted !== undefined) {
                localVarQueryParameter['showDeleted'] = showDeleted;
            }

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Изменить данные организации
         * @param {number} id 
         * @param {StructureModify} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyStructureById: async (id: number, payload: StructureModify, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyStructureById.');
            }
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling modifyStructureById.');
            }
            const localVarPath = `/users/structures/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Изменить данные пользователя
         * @param {number} id 
         * @param {UserModify} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUserById: async (id: number, payload: UserModify, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyUserById.');
            }
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling modifyUserById.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LaboratoryProfile} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLaboratoryProfile: async (payload: LaboratoryProfile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling removeLaboratoryProfile.');
            }
            const localVarPath = `/users/laboratoryprofiles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Отписаться от события
         * @param {number} affiliationId 
         * @param {AffiliationSubscribe} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSubscription: async (affiliationId: number, payload: AffiliationSubscribe, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'affiliationId' is not null or undefined
            if (affiliationId === null || affiliationId === undefined) {
                throw new RequiredError('affiliationId','Required parameter affiliationId was null or undefined when calling removeSubscription.');
            }
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling removeSubscription.');
            }
            const localVarPath = `/users/affiliations/{affiliation_id}/subscriptions`
                .replace(`{${"affiliation_id"}}`, encodeURIComponent(String(affiliationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Сбросить пароль пользователя
         * @param {ResetPassword} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (payload: ResetPassword, xFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling resetPassword.');
            }
            const localVarPath = `/users/resetPassword/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFields !== undefined && xFields !== null) {
                localVarHeaderParameter['X-Fields'] = String(xFields);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Послать пригласительное письмо пользователю
         * @param {UserEmail} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvitationEmail: async (payload: UserEmail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling sendInvitationEmail.');
            }
            const localVarPath = `/users/sendInvitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Послать письмо для сброса пароля
         * @param {UserEmail} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetPasswordEmail: async (payload: UserEmail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling sendResetPasswordEmail.');
            }
            const localVarPath = `/users/sendResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof payload !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payload !== undefined ? payload : {}) : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LaboratoryProfile} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLaboratoryProfile(payload: LaboratoryProfile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).addLaboratoryProfile(payload, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Подписать на событие
         * @param {number} affiliationId 
         * @param {AffiliationSubscribe} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSubscription(affiliationId: number, payload: AffiliationSubscribe, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).addSubscription(affiliationId, payload, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Изменить пароль пользователя
         * @param {ChangePassword} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(payload: ChangePassword, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultStatusModel>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).changePassword(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AffiliationCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAffiliation(payload: AffiliationCreate, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdModel>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).createAffiliation(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {StructureCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStructure(payload: StructureCreate, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdModel>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).createStructure(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UserCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(payload: UserCreate, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdModel>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).createUser(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAffiliationById(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultStatusModel>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).deleteAffiliationById(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStructureById(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultStatusModel>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).deleteStructureById(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).deleteUserById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAffiliationById(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffiliationFull>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).getAffiliationById(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStructureAndChildrenIds(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdList>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).getStructureAndChildrenIds(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStructureAndParents(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StructureParentsModel>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).getStructureAndParents(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStructurebyId(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StructureFull>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).getStructurebyId(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStructuresTypesRoles(xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StructuresTypesRoles>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).getStructuresTypesRoles(xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Возвращает минимальное поддерево на заданных вершинах
         * @param {Array<number>} [structureId] 
         * @param {boolean} [prune] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubtree(structureId?: Array<number>, prune?: boolean, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StructureTree>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).getSubtree(structureId, prune, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(id: number, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFull>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).getUserById(id, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<number>} [structureId] 
         * @param {Array<number>} [roleId] 
         * @param {Array<number>} [userId] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAffiliations(structureId?: Array<number>, roleId?: Array<number>, userId?: Array<number>, showDeleted?: boolean, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AffiliationListItem>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).listAffiliations(structureId, roleId, userId, showDeleted, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRoles(xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Role>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).listRoles(xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStructures(xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StructureListItem>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).listStructures(xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Список всех событий для подписки
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSubscriptionEvents(xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriptionEvent>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).listSubscriptionEvents(xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {string} [searchText] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUser(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, searchText?: string, showDeleted?: boolean, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserList>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).listUser(currentPage, perPage, sortDesc, sortBy, searchText, showDeleted, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Изменить данные организации
         * @param {number} id 
         * @param {StructureModify} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyStructureById(id: number, payload: StructureModify, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).modifyStructureById(id, payload, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Изменить данные пользователя
         * @param {number} id 
         * @param {UserModify} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyUserById(id: number, payload: UserModify, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultStatusModel>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).modifyUserById(id, payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LaboratoryProfile} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeLaboratoryProfile(payload: LaboratoryProfile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).removeLaboratoryProfile(payload, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Отписаться от события
         * @param {number} affiliationId 
         * @param {AffiliationSubscribe} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeSubscription(affiliationId: number, payload: AffiliationSubscribe, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).removeSubscription(affiliationId, payload, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Сбросить пароль пользователя
         * @param {ResetPassword} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(payload: ResetPassword, xFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultStatusModel>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).resetPassword(payload, xFields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Послать пригласительное письмо пользователю
         * @param {UserEmail} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInvitationEmail(payload: UserEmail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).sendInvitationEmail(payload, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Послать письмо для сброса пароля
         * @param {UserEmail} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendResetPasswordEmail(payload: UserEmail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).sendResetPasswordEmail(payload, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {LaboratoryProfile} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLaboratoryProfile(payload: LaboratoryProfile, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).addLaboratoryProfile(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * Подписать на событие
         * @param {number} affiliationId 
         * @param {AffiliationSubscribe} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSubscription(affiliationId: number, payload: AffiliationSubscribe, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).addSubscription(affiliationId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * Изменить пароль пользователя
         * @param {ChangePassword} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(payload: ChangePassword, xFields?: string, options?: any): AxiosPromise<ResultStatusModel> {
            return UsersApiFp(configuration).changePassword(payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AffiliationCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAffiliation(payload: AffiliationCreate, xFields?: string, options?: any): AxiosPromise<IdModel> {
            return UsersApiFp(configuration).createAffiliation(payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StructureCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStructure(payload: StructureCreate, xFields?: string, options?: any): AxiosPromise<IdModel> {
            return UsersApiFp(configuration).createStructure(payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserCreate} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(payload: UserCreate, xFields?: string, options?: any): AxiosPromise<IdModel> {
            return UsersApiFp(configuration).createUser(payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAffiliationById(id: number, xFields?: string, options?: any): AxiosPromise<ResultStatusModel> {
            return UsersApiFp(configuration).deleteAffiliationById(id, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStructureById(id: number, xFields?: string, options?: any): AxiosPromise<ResultStatusModel> {
            return UsersApiFp(configuration).deleteStructureById(id, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserById(id: number, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).deleteUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAffiliationById(id: number, xFields?: string, options?: any): AxiosPromise<AffiliationFull> {
            return UsersApiFp(configuration).getAffiliationById(id, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructureAndChildrenIds(id: number, xFields?: string, options?: any): AxiosPromise<IdList> {
            return UsersApiFp(configuration).getStructureAndChildrenIds(id, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructureAndParents(id: number, xFields?: string, options?: any): AxiosPromise<Array<StructureParentsModel>> {
            return UsersApiFp(configuration).getStructureAndParents(id, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructurebyId(id: number, xFields?: string, options?: any): AxiosPromise<StructureFull> {
            return UsersApiFp(configuration).getStructurebyId(id, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructuresTypesRoles(xFields?: string, options?: any): AxiosPromise<StructuresTypesRoles> {
            return UsersApiFp(configuration).getStructuresTypesRoles(xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Возвращает минимальное поддерево на заданных вершинах
         * @param {Array<number>} [structureId] 
         * @param {boolean} [prune] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubtree(structureId?: Array<number>, prune?: boolean, xFields?: string, options?: any): AxiosPromise<StructureTree> {
            return UsersApiFp(configuration).getSubtree(structureId, prune, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(id: number, xFields?: string, options?: any): AxiosPromise<UserFull> {
            return UsersApiFp(configuration).getUserById(id, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [structureId] 
         * @param {Array<number>} [roleId] 
         * @param {Array<number>} [userId] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAffiliations(structureId?: Array<number>, roleId?: Array<number>, userId?: Array<number>, showDeleted?: boolean, xFields?: string, options?: any): AxiosPromise<Array<AffiliationListItem>> {
            return UsersApiFp(configuration).listAffiliations(structureId, roleId, userId, showDeleted, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoles(xFields?: string, options?: any): AxiosPromise<Array<Role>> {
            return UsersApiFp(configuration).listRoles(xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStructures(xFields?: string, options?: any): AxiosPromise<Array<StructureListItem>> {
            return UsersApiFp(configuration).listStructures(xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Список всех событий для подписки
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubscriptionEvents(xFields?: string, options?: any): AxiosPromise<Array<SubscriptionEvent>> {
            return UsersApiFp(configuration).listSubscriptionEvents(xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [currentPage] 
         * @param {number} [perPage] 
         * @param {boolean} [sortDesc] 
         * @param {string} [sortBy] 
         * @param {string} [searchText] 
         * @param {boolean} [showDeleted] 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUser(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, searchText?: string, showDeleted?: boolean, xFields?: string, options?: any): AxiosPromise<UserList> {
            return UsersApiFp(configuration).listUser(currentPage, perPage, sortDesc, sortBy, searchText, showDeleted, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Изменить данные организации
         * @param {number} id 
         * @param {StructureModify} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyStructureById(id: number, payload: StructureModify, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).modifyStructureById(id, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * Изменить данные пользователя
         * @param {number} id 
         * @param {UserModify} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUserById(id: number, payload: UserModify, xFields?: string, options?: any): AxiosPromise<ResultStatusModel> {
            return UsersApiFp(configuration).modifyUserById(id, payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LaboratoryProfile} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLaboratoryProfile(payload: LaboratoryProfile, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).removeLaboratoryProfile(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * Отписаться от события
         * @param {number} affiliationId 
         * @param {AffiliationSubscribe} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSubscription(affiliationId: number, payload: AffiliationSubscribe, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).removeSubscription(affiliationId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * Сбросить пароль пользователя
         * @param {ResetPassword} payload 
         * @param {string} [xFields] An optional fields mask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(payload: ResetPassword, xFields?: string, options?: any): AxiosPromise<ResultStatusModel> {
            return UsersApiFp(configuration).resetPassword(payload, xFields, options).then((request) => request(axios, basePath));
        },
        /**
         * Послать пригласительное письмо пользователю
         * @param {UserEmail} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvitationEmail(payload: UserEmail, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).sendInvitationEmail(payload, options).then((request) => request(axios, basePath));
        },
        /**
         * Послать письмо для сброса пароля
         * @param {UserEmail} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetPasswordEmail(payload: UserEmail, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).sendResetPasswordEmail(payload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {LaboratoryProfile} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public addLaboratoryProfile(payload: LaboratoryProfile, options?: any) {
        return UsersApiFp(this.configuration).addLaboratoryProfile(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Подписать на событие
     * @param {number} affiliationId 
     * @param {AffiliationSubscribe} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public addSubscription(affiliationId: number, payload: AffiliationSubscribe, options?: any) {
        return UsersApiFp(this.configuration).addSubscription(affiliationId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Изменить пароль пользователя
     * @param {ChangePassword} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changePassword(payload: ChangePassword, xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).changePassword(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AffiliationCreate} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createAffiliation(payload: AffiliationCreate, xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).createAffiliation(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StructureCreate} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createStructure(payload: StructureCreate, xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).createStructure(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserCreate} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(payload: UserCreate, xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).createUser(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteAffiliationById(id: number, xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).deleteAffiliationById(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteStructureById(id: number, xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).deleteStructureById(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserById(id: number, options?: any) {
        return UsersApiFp(this.configuration).deleteUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getAffiliationById(id: number, xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).getAffiliationById(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getStructureAndChildrenIds(id: number, xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).getStructureAndChildrenIds(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getStructureAndParents(id: number, xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).getStructureAndParents(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getStructurebyId(id: number, xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).getStructurebyId(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getStructuresTypesRoles(xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).getStructuresTypesRoles(xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Возвращает минимальное поддерево на заданных вершинах
     * @param {Array<number>} [structureId] 
     * @param {boolean} [prune] 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getSubtree(structureId?: Array<number>, prune?: boolean, xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).getSubtree(structureId, prune, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserById(id: number, xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).getUserById(id, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [structureId] 
     * @param {Array<number>} [roleId] 
     * @param {Array<number>} [userId] 
     * @param {boolean} [showDeleted] 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listAffiliations(structureId?: Array<number>, roleId?: Array<number>, userId?: Array<number>, showDeleted?: boolean, xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).listAffiliations(structureId, roleId, userId, showDeleted, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listRoles(xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).listRoles(xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listStructures(xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).listStructures(xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Список всех событий для подписки
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listSubscriptionEvents(xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).listSubscriptionEvents(xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [currentPage] 
     * @param {number} [perPage] 
     * @param {boolean} [sortDesc] 
     * @param {string} [sortBy] 
     * @param {string} [searchText] 
     * @param {boolean} [showDeleted] 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listUser(currentPage?: number, perPage?: number, sortDesc?: boolean, sortBy?: string, searchText?: string, showDeleted?: boolean, xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).listUser(currentPage, perPage, sortDesc, sortBy, searchText, showDeleted, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Изменить данные организации
     * @param {number} id 
     * @param {StructureModify} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public modifyStructureById(id: number, payload: StructureModify, options?: any) {
        return UsersApiFp(this.configuration).modifyStructureById(id, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Изменить данные пользователя
     * @param {number} id 
     * @param {UserModify} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public modifyUserById(id: number, payload: UserModify, xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).modifyUserById(id, payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LaboratoryProfile} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public removeLaboratoryProfile(payload: LaboratoryProfile, options?: any) {
        return UsersApiFp(this.configuration).removeLaboratoryProfile(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Отписаться от события
     * @param {number} affiliationId 
     * @param {AffiliationSubscribe} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public removeSubscription(affiliationId: number, payload: AffiliationSubscribe, options?: any) {
        return UsersApiFp(this.configuration).removeSubscription(affiliationId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Сбросить пароль пользователя
     * @param {ResetPassword} payload 
     * @param {string} [xFields] An optional fields mask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetPassword(payload: ResetPassword, xFields?: string, options?: any) {
        return UsersApiFp(this.configuration).resetPassword(payload, xFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Послать пригласительное письмо пользователю
     * @param {UserEmail} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public sendInvitationEmail(payload: UserEmail, options?: any) {
        return UsersApiFp(this.configuration).sendInvitationEmail(payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Послать письмо для сброса пароля
     * @param {UserEmail} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public sendResetPasswordEmail(payload: UserEmail, options?: any) {
        return UsersApiFp(this.configuration).sendResetPasswordEmail(payload, options).then((request) => request(this.axios, this.basePath));
    }
}


