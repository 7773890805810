

























































import Vue from 'vue';
import { Component, Ref } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import { UserFull, UserModify } from '@/client-axios';
import BackendService from '@/api/backendService';

@Component
export default class UsersEdit extends Vue {
  get userId() {
    return parseInt(this.$route.params.id, 10);
  }

  user: null | UserFull = null;

  created() {
    this.load();
  }

  loading = false;

  loadingError: null | Error = null;

  load() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.usersApi.getUserById(this.userId)
      .then(({ data }) => {
        this.loading = false;
        this.model.email = data.email;
        this.model.name = data.name;
        this.model.phone = data.phone;
        this.user = data;
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }

  model: UserModify = {
    email: '',
    phone: '',
    name: '',
  };

  @Ref()
  readonly form: FormModel;

  save() {
    (this.form.validate() as Promise<unknown>)
      .then(this.executeSave);
  }

  saving = false;

  savingError: null | Error = null;

  executeSave() {
    this.saving = true;
    this.savingError = null;
    return BackendService.usersApi.modifyUserById(this.userId, this.model)
      .then(() => {
        this.saving = false;
        this.$router.push({ name: 'users-details', params: { id: this.userId.toString() } });
      })
      .catch((error) => {
        this.saving = false;
        this.savingError = error;
      });
  }
}
