

























import Vue from 'vue';
import { Component, Ref } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import { Affiliation, PatientCreate } from '@/client-axios';
import BackendService from '@/api/backendService';
import { getEmptyDiagnosisData } from '@/utils/patient';
import { AuthConst } from '@/store/constants';
import PatientEditor from './PatientEditor.vue';

@Component({
  components: {
    PatientEditor,
  },
})
export default class AddPatient extends Vue {
  gutter = 20;

  model: PatientCreate = {
    firstName: '',
    lastName: '',
    middleName: undefined,
    birthDate: undefined,
    sexId: undefined,
    diagnosisData: getEmptyDiagnosisData(),
    externalId: undefined,
    affiliationId: undefined as unknown as number,
  };

  get currentAffiliation() {
    return this.$store.getters[AuthConst.Getters.currentAffiliation] as Affiliation | undefined;
  }

  created() {
    if (this.currentAffiliation?.roleName === this.$const.roles.Doctor) {
      this.model.affiliationId = this.currentAffiliation.id;
    }
  }

  @Ref()
  readonly form!: FormModel;

  validateForm() {
    return (this.form.validate() as Promise<unknown>);
  }

  adding = false;

  addingError: null | Error = null;

  add() {
    return this.validateForm()
      .then(this.executeAdd)
      .catch(() => {
        this.$message.warning('На форме присутствуют ошибки');
      });
  }

  executeAdd() {
    this.adding = true;
    return BackendService.patientApi.createPatient(this.model)
      .then(({ data }) => {
        this.adding = false;
        this.$router.push({ name: 'patients-details', params: { id: data.id?.toString() } });
      })
      .catch((error) => {
        this.adding = false;
        this.addingError = error;
      });
  }
}
