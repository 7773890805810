































import Vue from 'vue';
import { Component, Ref } from 'vue-property-decorator';
import BackendService from '@/api/backendService';
import { fakeDownload, getDownloadRequestConfig } from '@/utils/exporting';
import AnalyzesPending from './AnalyzesPending.vue';

@Component({
  components: {
    AnalyzesPending,
  },
})
export default class AnalyzesPendingToBeSent extends Vue {
  @Ref()
  readonly analyzesPending: AnalyzesPending;

  downloadingShippingList = false;

  downloadingShippingListError: null | Error = null;

  downloadShippingList(selectedAnalyzesIds: number[]) {
    if (selectedAnalyzesIds.length === 0) {
      return null;
    }

    this.downloadingShippingList = true;
    this.downloadingShippingListError = null;
    return BackendService.analysesApi.getShippingList(selectedAnalyzesIds, getDownloadRequestConfig())
      .then((response) => {
        fakeDownload(response);
        this.downloadingShippingList = false;
      })
      .catch((error) => {
        this.downloadingShippingListError = error;
        this.downloadingShippingList = false;
      });
  }

  settingStatusSent = false;

  settingStatusSentError: null | Error = null;

  setStatusSent(selectedAnalyzesIds: number[]) {
    this.settingStatusSent = true;
    this.settingStatusSentError = null;
    return BackendService.analysesApi.setAnalysisSentStatus(selectedAnalyzesIds)
      .then(() => {
        this.settingStatusSent = false;
        this.$emit('changed');
        return this.analyzesPending.load();
      })
      .catch((error) => {
        this.settingStatusSentError = error;
        this.settingStatusSent = false;
      });
  }
}
