















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class BooleanRadioGroup extends Vue {
  @Prop({ required: true })
  readonly value: boolean;
}
