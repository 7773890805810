










































































































import moment from 'moment';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
  ConsultationMessageCreate,
  ConsultationMessageListItem,
  UserFull,
} from '@/client-axios';
import BackendService from '@/api/backendService';
import { Pagination, Sorter } from '@/types/ant-design-vue-types';
import { getDefaultPagination as getDefaultRegistryPagination } from '@/utils/registry';
import FileList from '@/components/Form/FileList.vue';
import dateTime from '@/filters/date-time';
import { RootConst } from '@/store';

interface ExtendedConsultationMessageListItem extends ConsultationMessageListItem {
  createdUserData?: UserFull;
}

const getDefaultPagination = (): Pagination => ({
  ...getDefaultRegistryPagination(),
  hideOnSinglePage: true,
});

@Component({
  components: {
    FileList,
  },
})
export default class Consultations extends Vue {
  @Prop({ required: true, type: Number })
  readonly patientId: number;

  @Prop({ required: false, type: Boolean, default: true })
  readonly readonly: boolean;

  created() {
    this.load();
  }

  dateTime = dateTime;

  moment = moment;

  get currentRoleIsDoctor() {
    return this.$isInRole(this.$const.roles.Doctor);
  }

  get currentRoleIsConsultant() {
    return this.$isInRole(this.$const.roles.Consultant);
  }

  consultations: ExtendedConsultationMessageListItem[] = [];

  loading = false;

  loadingError: null | Error = null;

  pagination: Pagination = getDefaultPagination();

  load() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.consultationsApi.listConsultationMessages(
      this.pagination.current,
      this.pagination.pageSize,
      true,
      'createdDateTime',
      this.patientId,
    )
    .then(({ data }) => {
      this.consultations = (data.items ?? []).map((x) => ({
        ...x,
        createdUserData: undefined,
      }));
      this.pagination.total = data.totalCount;
      this.loading = false;

      this.markAllRead();
      this.loadUsers();
    })
    .catch((error) => {
      this.loading = false;
      this.loadingError = error;
    });
  }

  get showLoadMore() {
    return (this.pagination.total ?? 0) > this.consultations.length;
  }

  handleChange() {
    this.pagination = {
      ...this.pagination,
      pageSize: 2 * (this.pagination.pageSize ?? 10),
    };
    this.load();
  }

  handleInfiniteOnLoad() {
    if ((this.pagination.current ?? 1) * (this.pagination.pageSize ?? 10) >= (this.pagination.total ?? 0)) {
      return;
    }

    this.pagination.current = (this.pagination.current ?? 1) + 1;
    this.load();
  }

  newMessage: null | ConsultationMessageCreate = null;

  add() {
    this.newMessage = {
      patientId: this.patientId,
      message: '',
      isRequest: this.currentRoleIsDoctor,
      isAnswered: this.currentRoleIsConsultant,
      attachments: [],
    };
  }

  sending = false;

  sendingError: null | Error = null;

  send() {
    if (!this.newMessage) {
      throw new Error('Отсутствуют данные запроса');
    }

    this.sending = true;
    this.sendingError = null;
    return BackendService.consultationsApi.createConsultationMessage(this.newMessage)
      .then(() => {
        this.sending = false;
        this.newMessage = null;
        this.load();
        return this.$store.dispatch(RootConst.Actions.loadNotifications);
      })
      .catch((error) => {
        this.sending = false;
        this.sendingError = error;
      });
  }

  markingAsRead = false;

  markAsRead(id: number) {
    this.markingAsRead = true;
    return BackendService.consultationsApi.setReadStatusById(id)
      .then(() => {
        this.markingAsRead = false;
        const consultation = this.consultations.find((x) => x.id === id);
        if (consultation) {
          consultation.isRead = true;
        }
        return this.$store.dispatch(RootConst.Actions.loadNotifications);
      })
      .catch(() => {
        this.markingAsRead = false;
      });
  }

  markAllRead() {
    return this.consultations.filter((x) => !x.isRead && (this.currentRoleIsDoctor ? !x.isRequest : x.isRequest))
      .forEach((x) => this.markAsRead(x.id));
  }

  loadUsers() {
    this.consultations.forEach((consultation) => {
      BackendService.usersApi.getUserById(consultation.createdUser)
        .then(({ data }) => {
          consultation.createdUserData = data;
        });
    });
  }
}
