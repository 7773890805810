export interface IOrderable {
  order?: number;
}

export default function (a: IOrderable, b: IOrderable) {
  if (a.order) {
    if (b.order) {
      return a.order - b.order;
    }
    return 1;
  }
  if (b.order) {
    return -1;
  }
  return 0;
}
