















































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import {
  FileInfo,
  Patient,
} from '@/client-axios';
import BackendService from '@/api/backendService';
import FileList from '@/components/Form/FileList.vue';
import { fakeDownload, getDownloadRequestConfig } from '@/utils/exporting';
import { Tables } from '@/utils/tables';
import Risk from './Details/Risk.vue';
import Treatments from './Treatments/Treatments.vue';
import Consultations from './Details/Consultations.vue';
import Donors from './Details/Donors.vue';
import PatientGeneral from './Details/PatientGeneral.vue';
import PatientDiagnosis from './Details/PatientDiagnosis.vue';
import PatientAnalyzes from './Details/PatientAnalyzes.vue';
import PatientFollowUps from './Details/PatientFollowUps.vue';
import TransplantationDetails from './Details/TransplantationDetails.vue';

@Component({
  components: {
    PatientGeneral,
    PatientAnalyzes,
    PatientDiagnosis,
    PatientFollowUps,
    FileList,
    Risk,
    Treatments,
    Consultations,
    Donors,
    TransplantationDetails,
  },
})
export default class PatientDetails extends Vue {
  get patientId() {
    return parseInt(this.$route.params.id, 10);
  }

  created() {
    if (this.$route.hash) {
      this.currentTab = this.$route.hash.substring(1);
    }
    this.load();
    this.loadAttachments();
  }

  loading = false;

  loadingError: null | Error = null;

  patient: null | Patient = null;

  load() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.patientApi.getPatientById(this.patientId)
      .then(({ data }) => {
        if (!data.attachments) {
          data.attachments = [];
        }
        this.patient = data;
        this.loading = false;
      })
      .catch((error) => {
        this.loadingError = error;
        this.loading = false;
      });
  }

  currentTab = 'general';

  @Watch('currentTab')
  onCurrentTabChanged(newValue: string) {
    if (this.$route.hash !== `#${newValue}`) {
      this.$router.replace({
        name: this.$route.name as string,
        params: this.$route.params,
        hash: `#${newValue}`,
      });
    }
  }

  remove() {
    this.$confirm({
      content: 'Вы действительно хотите удалить пациента?',
      okText: 'Удалить',
      okType: 'danger',
      onOk: this.executeRemove,
      okButtonProps: {
        loading: this.removing,
      },
    });
  }

  removing = false;

  removingError: null | Error = null;

  executeRemove() {
    this.removing = false;
    this.removingError = null;
    return BackendService.patientApi.deletePatientById(this.patientId)
      .then(() => {
        this.removing = false;
        this.$router.push({ name: 'patients' });
      })
      .catch((error) => {
        this.removing = false;
        this.removingError = error;
        this.$message.error(`При выполнении операции произошла ошибка ${error.message}`);
      });
  }

  undoingRemove = false;

  undoRemove() {
    this.undoingRemove = false;
    return BackendService.entitiesApi.undoDelete({ id: this.patientId, table: Tables.Patients })
      .then(() => {
        this.undoingRemove = false;
        this.$router.go(0);
      })
      .catch((error) => {
        this.undoingRemove = false;
        this.$message.error(`При выполнении операции произошла ошибка ${error.message}`);
      });
  }

  get canEditTransplantation(): boolean {
    return this.$auth.permissions.has(this.$const.permissions.sections.transplantation, this.$const.permissions.actions.modify);
  }

  get canEdit(): boolean {
    return this.$auth.permissions.has(this.$const.permissions.sections.patient, this.$const.permissions.actions.modify);
  }

  get canDelete(): boolean {
    return this.$auth.permissions.has(this.$const.permissions.sections.patient, this.$const.permissions.actions.delete);
  }

  get canEditConsultations(): boolean {
    return this.$auth.permissions.has(this.$const.permissions.sections.consultation, this.$const.permissions.actions.modify);
  }

  downloading = false;

  downloadPatientData() {
    this.downloading = true;
    return BackendService.patientApi.downloadPatientById(this.patientId, getDownloadRequestConfig())
      .then((response) => {
        fakeDownload(response);
        this.downloading = false;
      })
      .catch((error: Error) => {
        this.downloading = false;
        this.$message.error(`Ошибка загрузки файла: ${error.message}`);
      });
  }

  attachments: FileInfo[] = [];

  loadingAttachments = false;

  loadingAttachmentsError: null | Error = null;

  loadAttachments() {
    this.loadingAttachments = true;
    this.loadingAttachmentsError = null;
    return BackendService.patientApi.listPatientAttachments(this.patientId)
      .then(({ data }) => {
        this.loadingAttachments = false;
        this.attachments = data;
      })
      .catch((error) => {
        this.loadingAttachments = false;
        this.loadingAttachmentsError = error;
      });
  }

  get attachmentUploadActionUrl() {
    return `/api/patients/${this.patientId}/attachments`;
  }

  transfer() {
    this.$confirm({
      title: 'Вы действительно хотите перевести пациента в другое лечебное учреждение?',
      content: 'После выполнения этой операции пациент откреплен от текущего лечебного учреждения '
        + 'и доступен для приема в другое лечебное учреждение',
      okText: 'Перевести',
      okButtonProps: {
        props: {
          type: 'primary',
          loading: this.transfering,
        },
      },
      onOk: () => this.executeTransfer(),
    });
  }

  transfering = false;

  transferingError : null | Error = null;

  executeTransfer() {
    this.transfering = true;
    this.transferingError = null;
    return BackendService.patientApi.setAffiliationToNull(this.patientId)
      .then(() => {
        this.transfering = false;
        this.$router.push({ name: 'patients' });
      })
      .catch((error) => {
        this.transferingError = error;
        this.transfering = false;
      });
  }
}
