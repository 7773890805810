
















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import BackendService from '@/api/backendService';
import { Affiliation, ConsultationMessageListItem } from '@/client-axios';
import { AuthConst } from '@/store/constants';

@Component
export default class Notifications extends Vue {
  created() {
    this.loadNotifications();
  }

  @Getter(AuthConst.Getters.currentAffiliation)
  currentAffiliation: Affiliation | undefined;

  get isDoctor() {
    return this.currentAffiliation?.roleName === this.$const.roles.Doctor;
  }

  notifications: ConsultationMessageListItem[] = [];

  loading = false;

  page = 1;

  size = 100;

  total = 0;

  loadNotifications() {
    this.loading = true;
    BackendService.consultationsApi.listConsultationMessages(
      this.page,
      this.size,
      undefined,
      undefined,
      undefined,
      this.isDoctor ? this.currentAffiliation?.id : undefined,
      undefined,
      undefined,
      !this.isDoctor,
    )
      .then(({ data }) => {
        this.notifications = data.items ?? [];
        this.total = data.totalCount;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  get showLoadMore(): boolean {
    return this.total > this.notifications.length;
  }

  increaseLoadSize() {
    this.size *= 2;
    this.loadNotifications();
  }

  getTooltip(item: ConsultationMessageListItem) {
    if (this.isDoctor) {
      if (item.isAnswered) {
        return 'Отвечено';
      }
      if (item.isRead) {
        return 'Прочитано';
      }
      return 'Не прочитано';
    }

    if (item.isAnswered) {
      return 'Отвечено';
    }
    if (item.isRead) {
      return 'Прочитано';
    }
    return 'Не прочитано';
  }

  // eslint-disable-next-line
  getMessageTitle(item: ConsultationMessageListItem) {
    if (item.isRequest) {
      return 'Запрос на консультацию';
    }
    return 'Ответ на консультацию';
  }
}
