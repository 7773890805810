
































































import { orderBy } from 'lodash';
import Vue from 'vue';
import { Component, Ref } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import BackendService from '@/api/backendService';
import { ClassifierItem, StructureListItem, StructureModify } from '@/client-axios';
import { RootConst } from '@/store';
import StructureTreeSelect from '@/components/StructureTreeSelect.vue';

@Component({
  components: {
    StructureTreeSelect,
  },
})
export default class StructuresEdit extends Vue {
  get structureId(): number {
    return parseInt(this.$route.params.id, 10);
  }

  created() {
    this.load();
    this.loadStructures();
  }

  loading = false;

  loadingError: null | Error = null;

  load() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.usersApi.getStructurebyId(this.structureId)
      .then(({ data }) => {
        this.model.parentId = data.parentId;
        this.model.title = data.title;
        this.model.address = data.address;
        this.model.typeId = data.typeId;
        this.model.city = data.city;
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }

  model: StructureModify = {
    parentId: undefined,
    title: '',
    address: undefined,
    typeId: undefined as unknown as number,
    city: '',
  };

  structures: StructureListItem[] = [];

  loadingStructures = false;

  loadingStructuresError: null | Error = null;

  loadStructures() {
    this.loadingStructures = true;
    return this.$store.dispatch(RootConst.Actions.loadStructures)
      .then((structures) => {
        this.loadingStructures = false;
        this.structures = structures;
      })
      .catch((error) => {
        this.loadingStructures = false;
        this.loadingStructuresError = error;
      });
  }

  filterStructuresString = '';

  get filteredStructures() {
    const structures = orderBy(this.structures, (x) => x.title);

    if (this.filterStructuresString) {
      const normalizedSearchString = this.filterStructuresString.trim().toLowerCase();
      return structures.filter((x) => x.title?.toLowerCase().includes(normalizedSearchString));
    }
    return structures;
  }

  typesFilter = (item: ClassifierItem): boolean => item.value !== 'root';

  @Ref()
  readonly form: FormModel;

  validate() {
    return this.form.validate() as Promise<unknown>;
  }

  save() {
    return this.validate()
      .then(this.executeSave);
  }

  saving = false;

  savingError: null | Error = null;

  executeSave() {
    this.saving = true;
    this.savingError = null;
    return BackendService.usersApi.modifyStructureById(this.structureId, this.model)
      .then(() => {
        this.saving = false;
        this.$router.push({ name: 'structures-details', params: { id: this.structureId.toString() } });
      })
      .catch((error) => {
        this.saving = false;
        this.savingError = error;
      });
  }
}
