
























































































  import BackendService from '@/api/backendService';
import {
Affiliation,
PatientListItem,
StructureListItem,
} from '@/client-axios';
import classifier from '@/filters/classifier';
import date from '@/filters/date';
import duration from '@/filters/duration';
import yesNo from '@/filters/yesNo';
import { RootConst } from '@/store';
import { AuthConst } from '@/store/constants';
import RootState from '@/store/rootState';
import { Pagination, Sorter, TableColumn } from '@/types/ant-design-vue-types';
import {
getActionsColumn,
getDefaultPagination,
getNumberColumn,
sortIsDesc,
} from '@/utils/registry';
import { Tables } from '@/utils/tables';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Store } from 'vuex';
import { Getter } from 'vuex-class';
import PatientsFilterEditor from './PatientsFilterEditor.vue';

  export interface PatientsFilter {
    fio?: string;
    userId?: number;
    structuresIds?: number[];
    showDeleted?: boolean;
    createdDateFrom?: string;
    createdDateTo?: string;
    statusIds?: number[];
    isTransplantationPerformed?: boolean,
    diagnosisId?: number;
  }

  const getEmptyFilter = (): PatientsFilter => ({
    fio: '',
    userId: undefined,
    structuresIds: undefined,
    showDeleted: undefined,
    createdDateFrom: undefined,
    createdDateTo: undefined,
    statusIds: [],
    isTransplantationPerformed: undefined,
    diagnosisId: undefined,
  });

  export const getPatientsColumns = (vue: Vue): TableColumn<PatientListItem>[] => [
    {
      title: 'ФИО',
      dataIndex: 'lastName',
      customRender: (_: string, row) => vue.$createElement(
        'router-link',
        {
          props: {
            to: { name: 'patients-details', params: { id: row.id } },
          },
        },
        [row.lastName, row.firstName, row.middleName].join(' '),
      ),
      sorter: true,
      defaultSortOrder: 'ascend',
    },
    {
      key: 'structureId',
      title: 'Лечебное учреждение',
      dataIndex: 'structureId',
      customRender: (structureId: number) => vue.$store.getters[RootConst.Getters.structureFullName](structureId, true),
      width: '25%',
    },
    {
      dataIndex: 'birthDate',
      title: 'Дата рождения',
      customRender: (birthDate: string) => vue.$createElement(
        'span',
        [
          date(birthDate),
          vue.$createElement('br'),
          '(',
          duration(birthDate),
          ')',
        ],
      ),
      sorter: true,
      width: '10%',
    },
    // {
    //   title: 'Пол',
    //   dataIndex: 'sexId',
    //   customRender: (sexId: number) => classifier(sexId, 'sex'),
    //   width: '10%',
    // },
    {
      title: 'Дата регистрации',
      dataIndex: 'createdDateTime',
      customRender: date,
      sorter: true,
      width: '10%',
    },
    {
      title: 'Дата HLA-типирования',
      dataIndex: 'hlaSampleDate',
      customRender: date,
      sorter: true,
      width: '10%',
    },
    {
      title: 'Статус',
      dataIndex: 'statusId',
      customRender: (statusId: number | undefined, row) => (
        statusId
        ? vue.$createElement('span', {}, [
          `${classifier(statusId)}`,
          vue.$createElement('br'),
          `(${date(row.statusDate)})`,
        ])
        : ''),
      sorter: true,
      width: '12%',
    },
    {
      title: 'Выполнена аллоТГСК',
      dataIndex: 'isTransplantationPerformed',
      customRender: yesNo,
      sorter: true,
      width: '10%',
    },
  ];

  @Component({
    components: {
      PatientsFilterEditor,
    },
  })
  export default class Patients extends Vue {
    get columns(): TableColumn<PatientListItem>[] {
      return [
        {
          ...getNumberColumn(this.pagination),
          width: '3em',
        },
        ...getPatientsColumns(this),
        getActionsColumn(),
      ];
    }

    pagination: Pagination = getDefaultPagination();

    sorter: Sorter = {
      field: 'lastName',
      order: 'ascend',
    };

    onTableStateChange(pagination: Pagination, _: unknown, sorter: Sorter) {
      this.pagination = pagination;
      this.sorter = sorter;
      this.loadPatients();
    }

    patientsFilter: PatientsFilter = getEmptyFilter();

    onFilterChange() {
      this.pagination = getDefaultPagination();
      this.loadPatients();
    }

    get store() {
      return this.$store as Store<RootState>;
    }

    get currentAffiliation() {
      return this.store.getters[AuthConst.Getters.currentAffiliation] as Affiliation | undefined;
    }

    get canCreate() {
      return this.$auth.permissions.has(this.$const.permissions.sections.patient, this.$const.permissions.actions.create);
    }

    get canEdit() {
      return this.$auth.permissions.has(this.$const.permissions.sections.patient, this.$const.permissions.actions.modify);
    }

    get canDelete() {
      return this.$auth.permissions.has(this.$const.permissions.sections.patient, this.$const.permissions.actions.delete);
    }

    @Getter(AuthConst.Getters.isPatientsAccessRestricted)
    readonly isPatientAccessRestricted: boolean;

    userStructuresWithChildrenIds: number[] = [];

    userStructuresWithSubstructuresLoading = false;

    loadUserStructuresWithSubstructures() {
      this.userStructuresWithSubstructuresLoading = true;
      return this.$store.dispatch(AuthConst.Actions.getAffiliationStructureWithChildrenIds)
        .then((ids: number[]) => {
          this.userStructuresWithChildrenIds = ids;
          this.userStructuresWithSubstructuresLoading = false;
        })
        .catch(() => {
          this.userStructuresWithSubstructuresLoading = false;
        });
    }

    structuresCanHavePatients: StructureListItem[] = [];

    loadStructuresCanHavePatients() {
      return this.$store.dispatch(RootConst.Actions.structuresCanHavePatients)
        .then((structuresCanHavePatients: StructureListItem[]) => {
          this.structuresCanHavePatients = structuresCanHavePatients.filter((x) => this.userStructuresWithChildrenIds.includes(x.id));
        });
    }

    autosetFilter() {
      if (this.structuresCanHavePatients.length === 1) {
        this.patientsFilter.structuresIds = this.structuresCanHavePatients.map((x) => x.id);
      }
    }

    @Getter(RootConst.Getters.structureRoot)
    readonly root: StructureListItem;

    patients: PatientListItem[] = [];

    loading = false;

    loadingError: null | Error = null;

    created() {
      this.loading = true;
      this.$store.dispatch(RootConst.Actions.loadStructures)
        .then(this.loadUserStructuresWithSubstructures)
        .then(this.loadStructuresCanHavePatients)
        .then(this.autosetFilter)
        .then(this.loadPatients);
    }

    loadPatients() {
      let structuresIds = this.patientsFilter.structuresIds ?? [];
      if (this.isPatientAccessRestricted && structuresIds.length === 0) {
        structuresIds = this.structuresCanHavePatients.map((x) => x.id);
        this.patientsFilter.structuresIds = structuresIds;
      }

      this.loading = true;
      this.loadingError = null;
      return BackendService.patientApi.listPatients(
        this.pagination.current,
        this.pagination.pageSize,
        this.sorter.order ? sortIsDesc(this.sorter.order) : undefined,
        this.sorter.order ? this.sorter.field : undefined,
        this.patientsFilter.fio ? this.patientsFilter.fio : undefined,
        undefined,
        this.patientsFilter.userId,
        structuresIds.length > 0 ? structuresIds : undefined,
        this.patientsFilter.showDeleted,
        this.patientsFilter.createdDateFrom,
        this.patientsFilter.createdDateTo,
        this.patientsFilter.isTransplantationPerformed,
        this.patientsFilter.statusIds?.length === 0 ? undefined : this.patientsFilter.statusIds,
        this.patientsFilter.diagnosisId,
      )
        .then(({ data }) => {
          this.patients = data.items;
          this.pagination.total = data.totalCount ?? 0;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.loadingError = error;
        });
    }

    clear() {
      this.patientsFilter = getEmptyFilter();
      this.autosetFilter();
      this.loadPatients();
    }

    removing = false;

    remove(row: PatientListItem) {
      this.$confirm({
        content: 'Вы действительно хотите удалить пациента?',
        okText: 'Да',
        cancelText: 'Отмена',
        onOk: () => this.executeRemove(row),
      });
    }

    executeRemove(row: PatientListItem) {
      this.removing = true;
      return BackendService.patientApi.deletePatientById(row.id)
        .then(() => {
          this.removing = false;
          this.loadPatients();
        })
        .catch((error) => {
          this.$message.error({ content: `Ошибка удаления ${error.message}` });
          this.removing = false;
        });
    }

    undoingRemove = false;

    undoRemove(row: PatientListItem) {
      this.undoingRemove = true;
      return BackendService.entitiesApi.undoDelete({
        id: row.id,
        table: Tables.Patients,
      })
        .then(() => {
          this.undoingRemove = false;
          this.loadPatients();
        })
        .catch(() => {
          this.undoingRemove = false;
        });
    }
  }
