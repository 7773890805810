











































import { Store } from 'vuex';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import RootState from '@/store/rootState';
import BackendService from '@/api/backendService';
import { AffiliationFull, SubscriptionEvent } from '@/client-axios';
import { RootConst } from '@/store';
import { roles } from '@/mixins/const-mixin';

@Component
export default class SubscriptionsEditor extends Vue {
  @Prop({ required: true, type: Number })
  readonly affiliationId: number;

  @Prop({ required: false, type: Boolean, default: false })
  readonly readonly: boolean;

  get userEmail() {
    return (this.$store as Store<RootState>).state.auth?.user?.userEmail;
  }

  get showSubscriptions() {
    return this.affiliation?.role.name === roles.Consultant;
  }

  created() {
    this.loadSubscriptions();
    this.loadAffiliation();
  }

  affiliation: AffiliationFull | null = null;

  loadingAffiliation = false;

  loadAffiliation() {
    this.loadingAffiliation = true;
    return BackendService.usersApi.getAffiliationById(this.affiliationId)
      .then(({ data }) => {
        this.loadingAffiliation = false;
        this.affiliation = data;
      })
      .catch((error: Error) => {
        this.loadingAffiliation = false;
        this.$message.error({ content: `Ошибка при загрузке информации о роли пользователя: ${error.message}` });
      });
  }

  get affiliationSubscriptionsNames(): string[] {
    return this.affiliation?.subscriptions.map((x) => x.name) ?? [];
  }

  subscriptions: SubscriptionEvent[] = [];

  loadingSubscriptions = false;

  loadSubscriptions() {
    this.loadingSubscriptions = true;
    return this.$store.dispatch(RootConst.Actions.loadSubscriptions)
      .then((data) => {
        this.subscriptions = data;
        this.loadingSubscriptions = false;
      })
      .catch((error) => {
        this.loadingSubscriptions = true;
        this.$message.error({ content: `Ошибка при загрузке доступных подписок: ${error.message}` });
      });
  }

  subscribing = false;

  subscribingId: null | string = null;

  onSubscriptionChecked(subscriptionName: string, checked: boolean) {
    if (!this.affiliation) {
      throw new Error('Данные пользователя отсутствуют');
    }

    let result: Promise<any>;

    this.subscribing = true;
    this.subscribingId = subscriptionName;

    if (checked) {
      result = BackendService.usersApi.addSubscription(this.affiliation.id, { name: subscriptionName })
        .then(() => {
          if (this.affiliation) {
            const subscription = this.subscriptions.find((x) => x.name === subscriptionName);
            if (subscription) {
              this.affiliation.subscriptions.push(subscription);
            }
          }
        });
    } else {
      result = BackendService.usersApi.removeSubscription(this.affiliation.id, { name: subscriptionName })
        .then(() => {
          if (this.affiliation) {
            const index = this.affiliation.subscriptions.findIndex((x) => x.name === subscriptionName);
            this.affiliation.subscriptions.splice(index, 1);
          }
        });
    }

    return result
      .then(() => {
        this.subscribingId = null;
        this.subscribing = false;
      })
      .catch((error: Error) => {
        this.$message.error({ content: `Ошибка изменения подписок: ${error.message}` });
      });
  }
}
