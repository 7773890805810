




























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import axios, { AxiosError } from 'axios';

export interface IApiError {
  message: string;
  errorName: string;
  errorTraceback: string;
}

@Component
export default class ApiError extends Vue {
  @Prop({ required: false, default: null })
  readonly error: Error | null | AxiosError;

  @Prop({ required: false, type: String, default: 'Ошибка выполнения операции' })
  readonly message: string;

  get description() {
    return this.error?.message;
  }

  get isAxiosError() {
    return axios.isAxiosError(this.error);
  }

  get stackTrace() {
    if (this.isAxiosError) {
      return ((this.error as AxiosError).response?.data as IApiError).errorTraceback;
    }
    return null;
  }

  showDetails = false;
}
