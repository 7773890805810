export enum Tables {
  Affiliations = 'affiliations',
  AnalysisResults = 'analysis_results',
  Courses = 'courses',
  Donors = 'donors',
  Patients = 'patients',
  Structures = 'structures',
  ShippingCompanies = 'shipping_companies',
  Users = 'users',
}

export default {};
