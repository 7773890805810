
































import Vue from 'vue';
import { Component, Ref } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import BackendService from '@/api/backendService';
import { UserEmail } from '@/client-axios';

@Component
export default class ForgotPassword extends Vue {
  model: UserEmail = {
    email: '',
  };

  executingSendResetPassword = false;

  executingSendResetPasswordError: null | Error = null;

  success: null | boolean = null;

  executeSendResetPassword() {
    this.executingSendResetPassword = true;
    this.executingSendResetPasswordError = null;
    this.success = false;
    return BackendService.usersApi.sendResetPasswordEmail(this.model)
      .then(() => {
        this.success = true;
        this.executingSendResetPassword = false;
      })
      .catch((error) => {
        this.executingSendResetPassword = false;
        this.executingSendResetPasswordError = error;
      });
  }

  @Ref()
  readonly form: FormModel;

  sendResetPassword() {
    (this.form.validate() as Promise<unknown>)
      .then(this.executeSendResetPassword);
  }
}
