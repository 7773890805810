




























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DocumentsList extends Vue {
  data = [
    {
      title: 'Инструкция по использованию системы ОМЛ-инфо',
      href: '/manual_OML_info_1_0.pdf',
      description: 'Детальное описание основных операций системы',
    },
  ];
}
