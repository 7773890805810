






















































import Vue from 'vue';
import { Component, Ref } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import BackendService from '@/api/backendService';
import { ShippingCompanyCreateModel } from '@/client-axios';

@Component
export default class ShippingCompaniesCreate extends Vue {
  create() {
    this.validateForm()
      .then(this.executeCreate);
  }

  @Ref()
  readonly form!: FormModel;

  validateForm() {
    return (this.form.validate() as Promise<unknown>);
  }

  model: ShippingCompanyCreateModel = {
    name: '',
    email: '',
    phone: '',
  };

  creating = false;

  creatingError: null | Error = null;

  executeCreate() {
    this.creating = true;
    this.creatingError = null;
    return BackendService.shippingCompanies.createShippingCompany(this.model)
      .then(({ data }) => {
        this.creating = false;
        this.$router.push({ name: 'shipping-companies-details', params: { id: data.id.toString() } });
      })
      .catch((error) => {
        this.creating = false;
        this.creatingError = error;
      });
  }
}
