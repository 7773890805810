


















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Pagination } from 'ant-design-vue';
import { ShippingCompanyFullModel } from '@/client-axios';
import BackendService from '@/api/backendService';
import { TableColumn } from '@/types/ant-design-vue-types';
import { getActionsColumn, getDefaultPagination, getNumberColumn } from '@/utils/registry';

@Component
export default class ShippingCompanies extends Vue {
  get canCreate() {
    return this.$auth.permissions.has(this.$const.permissions.sections.shippingCompany, this.$const.permissions.actions.create);
  }

  get canEdit() {
    return this.$auth.permissions.has(this.$const.permissions.sections.shippingCompany, this.$const.permissions.actions.modify);
  }

  get canDelete() {
    return this.$auth.permissions.has(this.$const.permissions.sections.shippingCompany, this.$const.permissions.actions.delete);
  }

  created() {
    this.load();
  }

  shippingCompanies: ShippingCompanyFullModel[] = [];

  loading = false;

  loadingError: null | Error = null;

  load() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.shippingCompanies.listShippingCompanies()
      .then(({ data }) => {
        this.shippingCompanies = data;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }

  pagination = getDefaultPagination();

  onTableStateChange(pagination: Pagination) {
    this.pagination = pagination;
  }

  get columns(): TableColumn<ShippingCompanyFullModel>[] {
    return [
      getNumberColumn(this.pagination),
      {
        dataIndex: 'name',
        title: 'Название',
      },
      {
        dataIndex: 'email',
        title: 'Email',
      },
      {
        dataIndex: 'phone',
        title: 'Телефон',
      },
      getActionsColumn(),
    ];
  }

  remove(item: ShippingCompanyFullModel) {
    this.$confirm({
      content: 'Вы действительно хотите удалить транспортную компания?',
      cancelText: 'Отмена',
      okText: 'Удалить',
      okButtonProps: {
        props: {
          loading: this.removing,
          type: 'danger',
        },
      },
      onOk: () => this.executeRemove(item),
    });
  }

  removing = false;

  removingError: null | Error = null;

  executeRemove(item: ShippingCompanyFullModel) {
    this.removing = true;
    this.removingError = null;
    return BackendService.shippingCompanies.deleteShippingCompany(item.id)
      .then(() => {
        this.removing = false;

        const index = this.shippingCompanies.findIndex((x) => x.id === item.id);
        this.shippingCompanies.splice(index, 1);
      })
      .catch((error) => {
        this.removingError = error;
        this.removing = false;
      });
  }
}
