import 'core-js/stable';

import Vue from 'vue';
import PortalVue from 'portal-vue';
import Antd from 'ant-design-vue';
import moment from 'moment';
import { AxiosError } from 'axios';
import VueBarcodeScanner, { VueBarcodeScannerOptions } from 'vue-barcode-scanner';
import App from './App.vue';
import router from './router';
import store, { RootConst } from './store';
import AntdGlobalMixin from './mixins/antd-mixin';
import ConstMixin from './mixins/const-mixin';
import AuthenticationMixin from './mixins/auth-mixin';
import date from './filters/date';
import dateTime from './filters/date-time';
import leucocytes from './filters/leucocytes';
import yesNo from './filters/yesNo';
import classifier from './filters/classifier';
import placeholder from './filters/placeholder';
import readableFileSize from './filters/readableFileSize';
import duration from './filters/duration';

import Loading from './components/Loading.vue';
import ClassifierSelect from './components/Form/ClassifierSelect.vue';
import DatePicker from './components/Form/DatePicker.vue';
import BooleanRadioGroup from './components/Form/BooleanRadioGroup.vue';
import ExcelDownloadIcon from './components/Icons/ExcelDownloadIcon.vue';
import AnalysisStatusTag from './components/AnalysisStatusTag.vue';
import ApiError from './components/ApiError.vue';

import 'ant-design-vue/dist/antd.css';
import './assets/styles.less';
import { AuthConst } from './store/constants';
import { CurrentUserInfo } from './client-axios';

moment.locale('ru');

Vue.config.productionTip = false;

Vue.use(Antd);
Vue.use(PortalVue);

const scannerOptions: VueBarcodeScannerOptions = {
  sensitivity: 300,
  requiredAttr: true,
  callbackAfterTimeout: true,
};
Vue.use(VueBarcodeScanner, scannerOptions);

Vue.mixin(AntdGlobalMixin);
Vue.mixin(ConstMixin);
Vue.mixin(AuthenticationMixin);

Vue.filter('date', date);
Vue.filter('date-time', dateTime);
Vue.filter('leucocytes', leucocytes);
Vue.filter('yesNo', yesNo);
Vue.filter('classifier', classifier);
Vue.filter('placeholder', placeholder);
Vue.filter('readableFileSize', readableFileSize);
Vue.filter('duration', duration);

Vue.component('Loading', Loading);
Vue.component('DatePicker', DatePicker);
Vue.component('ClassifierSelect', ClassifierSelect);
Vue.component('BooleanRadioGroup', BooleanRadioGroup);

Vue.component('ExcelDownloadIcon', ExcelDownloadIcon);

Vue.component('AnalysisStatusTag', AnalysisStatusTag);
Vue.component('ApiError', ApiError);

router.beforeEach((to, from, next) => {
  document.title = 'ОМЛ-Инфо';
  if (to.meta?.title) {
    document.title = `${document.title} | ${to.meta.title}`;
  }

  next();
});

store.commit(RootConst.Mutations.setStatus, {
  type: 'info',
  message: 'Получение информации о пользователе...',
});

store.dispatch(AuthConst.Actions.getCurrent)
  .catch((error: AxiosError) => {
    if (error.response?.status === 401) {
      store.commit(RootConst.Mutations.setStatus, null);
      if (!router.currentRoute.meta?.auth?.allowAnonymous) {
        router.push({
          name: 'account-login',
          query: {
            returnUrl: router.currentRoute.fullPath,
          },
        });
      }
    } else {
      store.commit(RootConst.Mutations.setStatus, {
        type: 'error',
        message: error.message,
      });
    }
    throw error;
  })
  .then((userData: CurrentUserInfo) => {
    if (!userData.userAffiliations || userData.userAffiliations.length === 0) {
      const message = 'У пользователя не указаны роли';
      store.commit(RootConst.Mutations.setStatus, {
        type: '403',
        message: message,
      });
      throw new Error(message);
    }
  })
  .then(() => store.dispatch(RootConst.Actions.initialize));

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
