




























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Donor } from '@/client-axios';
import BackendService from '@/api/backendService';
import PatientInfo from './PatientInfo.vue';

@Component({
  components: {
    PatientInfo,
  },
})
export default class DonorInfo extends Vue {
  @Prop({ required: true, type: Number })
  readonly donorId: number;

  @Prop({ required: false, default: null })
  readonly donor: Donor | null;

  created() {
    if (!this.donor) {
      this.load();
    }
  }

  loading = false;

  loadingError: null | Error = null;

  donorInfo: null | Donor = null;

  @Watch('donorId')
  load() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.patientApi.getDonorById(this.donorId)
      .then(({ data }) => {
        this.loading = false;
        this.donorInfo = data;
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }

  get info() {
    return this.donor ?? this.donorInfo;
  }

  get fio() {
    return [this.info?.lastName, this.info?.firstName, this.info?.middleName].filter((x) => !!x).join(' ');
  }
}
