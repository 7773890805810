import { ComponentOptions } from 'vue';

export interface ValidationRule {
  message: string | (() => string);
  required?: boolean;
  min?: number;
  max?: number;
  type?: string;
  pattern?: RegExp;
  // validator?: (rule: any, value: any, callback: () => any) => any;
}

export interface AntdParameters {
  table: {
    locale: {
      emptyText: string,
    },
  },
  form: {
    rules: {
      required: ValidationRule,
    },
  },
}

declare module 'vue/types/vue' {
  interface Vue {
    $antd: AntdParameters,
  }
}

export const RequiredRule: ValidationRule = {
  required: true,
  message: 'Необходимо заполнить',
};

const mixin: ComponentOptions<Vue> = {
  computed: {
    $antd(): AntdParameters {
      return {
        table: {
          locale: {
            emptyText: 'Записей не найдено',
          },
        },
        form: {
          rules: {
            required: RequiredRule,
          },
        },
      };
    },
  },
};

export default mixin;
