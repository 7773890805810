import { StructureListItem } from '@/client-axios';

export interface INode {
  children: INode[] | undefined;
  parentId?: number;
}

export interface TreeNode extends INode {
  title: string;
  value: string | number;
  children: this[];
  disabled?: boolean;
  disableCheckbox?: boolean;
  selectable?: boolean;
  isLeaf?: boolean;
}

export interface StructureTreeNode extends TreeNode {
  typeId: number;
}

export const structureTreeNodeMapFunction = (item: StructureListItem, children: StructureTreeNode[]): StructureTreeNode => ({
  typeId: item.typeId,
  parentId: item.parentId,
  title: item.title ?? '',
  value: item.id,
  children: children,
});

export const getStructuresLeafs = <T extends INode>(
  id: number | null,
  data: StructureListItem[],
  addedItems: Set<string | number>,
  mapFunction: (item: StructureListItem, children: T[]) => T,
  filterFunction: (item: StructureListItem, children: T[]) => boolean = () => true,
): T[] => {
  if (addedItems.size === data.length) {
    return [];
  }
  const leafsItems = data.filter((x) => x.parentId === id);
  leafsItems.forEach((x) => {
    addedItems.add(x.id);
  });
  const leafs: T[] = leafsItems.map((x) => {
    const children = getStructuresLeafs(x.id, data, addedItems, mapFunction, filterFunction);
    const leaf = mapFunction(x, children);
    return leaf;
  });
  return leafs;
};

export function getStructuresTree(structures: StructureListItem[]): StructureTreeNode[] {
  return getStructuresLeafs<StructureTreeNode>(null,
    structures,
    new Set(),
    structureTreeNodeMapFunction,
    () => true);
}

interface FilterTreeResult<T> {
  tree: T[];
  somethingRemoved: boolean;
}

// function internalFilterTree<T extends TreeNode>(filterFunction: (i: T) => boolean, items: T[]): FilterTreeResult<T> {
//   const result: FilterTreeResult<T> = {
//     tree: [],
//     somethingRemoved: false,
//   };
//   items.forEach((item) => {
//     const nodesStack: T[] = [];
//     nodesStack.push(item);
//     const newNodesChildren: T[] = [];
//     while (nodesStack.length > 0) {
//       const node = nodesStack.pop();
//       if (node) {
//         console.log(`visit ${node.title}`);
//         const filterNode = filterFunction(node);
//         if (filterNode) {
//           if (node === item) {
//             console.log(`push ${node.title} to TREE`);
//             result.tree.push(node);
//           } else {
//             newNodesChildren.push(node);
//             console.log(`push ${node.title} as CHILD`);
//           }
//         } else {
//           result.somethingRemoved = true;
//           console.log(`remove ${node.title}`);
//         }
//         nodesStack.push(...node.children);
//       }
//     }
//     item.children = newNodesChildren;
//   });
//   return result;
// }

export const filterTree = (
  items: StructureListItem[],
  filterFunction: (item: StructureListItem) => boolean,
): StructureTreeNode[] => {
  const filteredItems = items.filter(filterFunction);
  if (items.length === filteredItems.length) {
    return items.map((x) => structureTreeNodeMapFunction(x, []));
  }

  const resultTreeItems: StructureListItem[] = [...filteredItems];
  const addedItemsIds = new Set<number>(resultTreeItems.map((x) => x.id));
  let treeRootId: number | null = null;
  filteredItems.forEach((item) => {
    let id = item.parentId;
    while (id) {
      /* eslint-disable no-loop-func */
      const i = items.find((x) => x.id === id);
      if (i) {
        if (!addedItemsIds.has(i.id as number)) {
          resultTreeItems.push(i);
          addedItemsIds.add(i.id);
          id = i.parentId;
        } else {
          id = undefined;
        }
      } else {
        treeRootId = id;
        id = undefined;
      }
    }
  });

  return getStructuresLeafs(treeRootId, resultTreeItems, new Set(), structureTreeNodeMapFunction);
};

export interface GetChildrenResult {
  items: StructureListItem[];
  ids: number[];
}

export function getStructureAndChildrenIds(structures: StructureListItem[], id: number): number[] {
  const childrenIdsSet = new Set<number>([id]);
  const addChildrenIdsToSet = (itemId: number): number[] => {
    childrenIdsSet.add(itemId);
    const lChildrenIds = structures.filter((x) => x.parentId && itemId === x.parentId).map((x) => x.id);
    lChildrenIds.forEach((x) => addChildrenIdsToSet(x));
    return lChildrenIds;
  };
  addChildrenIdsToSet(id);
  return Array.from(childrenIdsSet);
}

export function getStructureAndChildren(structures: StructureListItem[], id: number): GetChildrenResult {
  const ids = getStructureAndChildrenIds(structures, id);
  return {
    ids: ids,
    items: structures.filter((x) => ids.includes(x.id)),
  };
}
