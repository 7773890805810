










































































































































import moment from 'moment';
import { groupBy, orderBy } from 'lodash';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
  CourseDayItem,
  CourseSummary,
} from '@/client-axios';
import BackendService from '@/api/backendService';

const START_HOUR = 8;

@Component
export default class TreatmentCourseDetails extends Vue {
  @Prop({ required: true, type: Number })
  readonly courseId: number;

  created() {
    this.load();
  }

  @Watch('courseId')
  onCourseIdChanged() {
    this.load();
  }

  loading = false;

  loadingError: null | Error = null;

  courseSummary: CourseSummary | null = null;

  load() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.coursesApi.getCourseSummaryApi(this.courseId)
      .then(({ data }) => {
        this.courseSummary = data;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }

  get drugsByDays() {
    if (!this.courseSummary) {
      return [];
    }

    return groupBy(this.courseSummary.drugDays, (x) => x.day);
  }

  groupDrugsByAdministration = (drugs: CourseDayItem[]) => groupBy(drugs, (y) => y.administration);

  orderBy = (arr: CourseDayItem[]) => orderBy(arr, (x) => x.order);

  hours = new Array(24).fill(0).map((_, index) => ((index + START_HOUR) % 24));

  get days() {
    if (!this.courseSummary) {
      return [];
    }

    const { startDate } = this.courseSummary;
    const result = Array.from(new Set(this.courseSummary.drugDays.map((x) => x.day)))
      .map((day) => {
        const date = moment(startDate).add(day - 1, 'days').format('DD.MM');
        return {
          day,
          date,
        };
      });

    return orderBy(result, (x) => x.day);
  }

  getTimesWithWord = (times: number) => {
    switch (times) {
      case 1:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
        return `${times} раз`;
      case 2:
      case 3:
      case 4:
        return `${times} раза`;
      default:
        return 'не принимается';
    }
  }
}
