















































































































































































































import JsBarcode from 'jsbarcode';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { Getter } from 'vuex-class';
import BackendService from '@/api/backendService';
import {
  Affiliation,
  AnalysisResultListItem,
  Classifier,
  StructureListItem,
  TemplateAnalysisList,
} from '@/client-axios';
import date from '@/filters/date';
import classifier from '@/filters/classifier';
import { Pagination, Sorter, TableColumn } from '@/types/ant-design-vue-types';
import {
  getActionsColumn,
  getDefaultPagination,
  getNumberColumn,
  sortIsDesc,
} from '@/utils/registry';
import { Tables } from '@/utils/tables';
import focusDirective from '@/directives/focus';
import { RootConst } from '@/store';
import { AuthConst } from '@/store/constants';
import RootState from '@/store/rootState';
import { roles } from '@/mixins/const-mixin';
import StructureTreeSelect from '@/components/StructureTreeSelect.vue';
import AnalyzesPendingToBeMailed from './AnalyzesPendingToBeMailed.vue';
import AnalyzesPendingToBeSent from './AnalyzesPendingToBeSent.vue';

interface AnalyzesFilter {
  templateIds?: number[];
  statusId?: number;
  sampleDateFrom?: string;
  sampleDateTo?: string;
  barCode?: string;
  structuresIds?: number[];
  showDeleted?: boolean;
}

const getEmptyFilter = (): AnalyzesFilter => ({
  templateIds: [],
  statusId: undefined,
  sampleDateFrom: undefined,
  sampleDateTo: undefined,
  barCode: undefined,
  structuresIds: undefined,
  showDeleted: undefined,
});

@Component({
  components: {
    StructureTreeSelect,
    AnalyzesPendingToBeMailed,
    AnalyzesPendingToBeSent,
  },
  directives: {
    focus: focusDirective,
  },
})
export default class Analyzes extends Vue {
  get canDelete() {
    return this.$auth.permissions.has(this.$const.permissions.sections.analysis, this.$const.permissions.actions.delete);
  }

  // eslint-disable-next-line
  get columns(): TableColumn<AnalysisResultListItem>[] {
    return [
      getNumberColumn(this.pagination),
      {
        title: 'Штрихкод',
        dataIndex: 'barCode',
        customRender: (barCode: string, row) => (
          <router-link to={{ name: 'analyzes-details', params: { id: row.id } }} title="Перейти к исследованию">
            {barCode}
          </router-link>
        ),
      },
      {
        title: 'Тип',
        dataIndex: 'templateAnalysisId',
        customRender: (templateId: number) => this.templatesAnalyzes.find((x) => x.id === templateId)?.name,
      },
      {
        title: 'Статус',
        dataIndex: 'currentStatusId',
        customRender: (currentStatusId: number) => (
          <analysis-status-tag
            status-id={currentStatusId}
            />
        ),
      },
      {
        title: 'Дата забора',
        dataIndex: 'sampleDate',
        sorter: true,
        defaultSortOrder: 'descend',
        customRender: date,
      },
      {
        title: 'Биоматериал',
        dataIndex: 'biomaterialId',
        customRender: (biomaterialId: number) => classifier(biomaterialId, 'biomaterial'),
      },
      {
        title: 'Субъект',
        dataIndex: 'patientFIO',
        customRender: (fio: string, row) => {
          const patientLink = (
            <router-link
              to={{ name: 'patients-details', hash: row.donorId ? '#donors' : undefined, params: { id: row.patientId } }}
              title="Перейти к пациенту"
              >
              {fio}
            </router-link>
          );
          if (row.donorId) {
            return (
              <span>
                донор <br/>
                {patientLink}
              </span>
            );
          }
          return patientLink;
        },
      },
      getActionsColumn(),
    ];
  }

  customRow(row: AnalysisResultListItem) {
    return {
      on: {
        dblclick: () => {
          this.$router.push({ name: 'analyzes-details', params: { id: row.id?.toString() ?? '' } });
        },
      },
    };
  }

  pagination: Pagination = getDefaultPagination();

  sorter: Sorter = {
    field: 'sampleDate',
    order: 'descend',
  };

  onTableStateChange(pagination: Pagination, _: unknown, sorter: Sorter) {
    this.pagination = pagination;
    this.sorter = sorter;
    this.load();
  }

  onFilterChanged() {
    this.pagination = getDefaultPagination();
    this.load();
  }

  get store() {
    return this.$store as Store<RootState>;
  }

  get templates() {
    return this.store.state.analyzesTemplates;
  }

  get statuses() {
    return (this.store.getters[RootConst.Getters.classifierByName]('analysisStatus') as Classifier)?.items ?? [];
  }

  get filteredTemplates() {
    return this.templates;
  }

  @Getter(AuthConst.Getters.isPatientsAccessRestricted)
  readonly isPatientAccessRestricted: boolean;

  userStructuresWithChildrenIds: number[] = [];

  loadUserStructuresWithSubstructures() {
    return this.$store.dispatch(AuthConst.Actions.getAffiliationStructureWithChildrenIds)
      .then((ids: number[]) => {
        this.userStructuresWithChildrenIds = ids;
      });
  }

  structuresCanHavePatients: StructureListItem[] = [];

  loadStructuresCanHavePatients() {
    return this.$store.dispatch(RootConst.Actions.structuresCanHavePatients)
      .then((structuresCanHavePatients: StructureListItem[]) => {
        this.structuresCanHavePatients = structuresCanHavePatients.filter((x) => this.userStructuresWithChildrenIds.includes(x.id));
      });
  }

  autosetFilter() {
    if (this.structuresCanHavePatients.length === 1) {
      this.filter.structuresIds = this.structuresCanHavePatients.map((x) => x.id);
    }
  }

  created() {
    this.loadUserStructuresWithSubstructures()
      .then(this.loadStructuresCanHavePatients)
      .then(this.autosetFilter)
      .then(this.load);

    this.$barcodeScanner.init(this.onBarcodeScanned);
  }

  filter: AnalyzesFilter = getEmptyFilter();

  clear() {
    this.filter = getEmptyFilter();
    this.load();
  }

  loading = false;

  loadingError: null | Error = null;

  analyzes: AnalysisResultListItem[] = [];

  load() {
    const templatesIds = this.filter.templateIds ?? [];

    let structuresIds = this.filter.structuresIds ?? [];
    if (this.isPatientAccessRestricted && structuresIds.length === 0) {
      structuresIds = this.structuresCanHavePatients.map((x) => x.id);
      this.filter.structuresIds = structuresIds;
    }

    this.loading = true;
    this.loadingError = null;
    return BackendService.analysesApi.listAnalyses(
      this.pagination.current,
      this.pagination.pageSize,
      this.sorter.order ? sortIsDesc(this.sorter.order) : undefined,
      this.sorter.order ? this.sorter.field : undefined,
      undefined,
      templatesIds.length === 0 ? undefined : templatesIds,
      undefined,
      this.filter.structuresIds,
      this.filter.statusId,
      this.filter.sampleDateFrom ?? undefined,
      this.filter.sampleDateTo ?? undefined,
      this.filter.barCode,
      this.filter.showDeleted,
    )
      .then(({ data }) => {
        this.analyzes = data.items ?? [];
        this.pagination.total = data.totalCount;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }

  get templatesAnalyzes(): TemplateAnalysisList[] {
    return (this.$store as Store<RootState>).state.analyzesTemplates;
  }

  removing = false;

  remove(row: AnalysisResultListItem) {
    this.$confirm({
      content: 'Вы действительно хотите удалить анализ?',
      okText: 'Да',
      cancelText: 'Отмена',
      onOk: () => this.executeRemove(row),
    });
  }

  executeRemove(row: AnalysisResultListItem) {
    this.removing = true;
    return BackendService.analysesApi.deleteAnalysisById(row.id)
      .then(() => {
        this.removing = false;
        this.load();
      })
      .catch((error) => {
        this.$message.error({ content: `Ошибка удаления ${error.message}` });
        this.removing = false;
      });
  }

  undoingRemove = false;

  undoRemove(row: AnalysisResultListItem) {
    this.undoingRemove = true;
    return BackendService.entitiesApi.undoDelete({
      id: row.id,
      table: Tables.AnalysisResults,
    })
      .then(() => {
        this.undoingRemove = false;
        this.load();
      })
      .catch(() => {
        this.undoingRemove = false;
      });
  }

  @Getter(AuthConst.Getters.currentAffiliation)
  readonly currentAffiliation: Affiliation | undefined;

  get displayPendingAnalyzesBlock(): boolean {
    return !!this.currentAffiliation?.roleName && [
      this.$const.roles.Doctor,
      this.$const.roles.DepartmentHead,
    ].includes(this.currentAffiliation.roleName);
  }

  get showAnalyzesReceivedButton(): boolean {
    return !!this.currentAffiliation?.roleName && [
      roles.LaboratoryStaff,
      roles.LaboratoryAdministrator,
      roles.ChiefAdministrator,
      roles.Consultant,
    ].includes(this.currentAffiliation.roleName);
  }

  analyzesToBeMailed: AnalysisResultListItem[] = [];

  analyzesToBeSent: AnalysisResultListItem[] = [];

  onBarcodeScanned(barcode: string) {
    if (this.$const.barcodeIsValid(barcode)) {
      this.showBarcodeModal = true;
      this.barcode = barcode;
    }
  }

  showBarcodeModal = false;

  barcode = '';

  @Watch('barcode')
  onBarcodeChanged() {
    if (this.barcode) {
      JsBarcode('#barcode', this.barcode, {
        format: this.$const.barcodeFormat,
        lineColor: '#000',
        width: 3,
        height: 40,
        displayValue: true,
        margin: 0,
      });

      this.searchAnalysisByBarcode();
    }
  }

  analysisByBarcode: AnalysisResultListItem | null = null;

  searchingAnalysisByBarcode = false;

  searchAnalysisByBarcode() {
    if (!this.barcode) {
      return Promise.resolve();
    }

    this.searchingAnalysisByBarcode = true;
    return BackendService.analysesApi.listAnalyses(
      1,
      1,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      this.barcode,
    )
      .then(({ data }) => {
        this.searchingAnalysisByBarcode = false;

        if (data.items && data.items[0]) {
          [this.analysisByBarcode] = data.items;
          this.secondsToForwardToAnalysisDetails = 3;
          setTimeout(this.tickForwardTimer, 1000);
        }
      })
      .catch(() => {
        this.searchingAnalysisByBarcode = false;
      });
  }

  secondsToForwardToAnalysisDetails: null | number = null;

  tickForwardTimer() {
    if (this.secondsToForwardToAnalysisDetails === null) return;

    if (this.secondsToForwardToAnalysisDetails === 0) {
      this.secondsToForwardToAnalysisDetails = null;
      if (this.analysisByBarcode?.id) {
        this.$router.push({ name: 'analyzes-details', params: { id: this.analysisByBarcode?.id.toString() } });
      }
    } else {
      this.secondsToForwardToAnalysisDetails -= 1;

      setTimeout(this.tickForwardTimer, 1000);
    }
  }
}
