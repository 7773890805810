







































import moment from 'moment';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FollowUp } from '@/client-axios';
import BackendService from '@/api/backendService';

@Component({
  filters: {
    duration: (val: number) => (val === 12 ? '1 год' : moment.duration(val, 'month').humanize()),
  },
})
export default class FollowUpTimeline extends Vue {
  @Prop({ required: true })
  readonly value: FollowUp[]

  @Prop({ required: false, type: Boolean, default: true })
  readonly readonly: boolean;

  removing = false;

  remove(followup: FollowUp) {
    this.$confirm({
      title: 'Вы действительно хотите удалить состояние?',
      cancelText: 'Отмена',
      okText: 'Подтвердить',
      okType: 'danger',
      okButtonProps: {
        props: {
          loading: this.removing,
        },
      },
      onOk: () => this.executeRemove(followup),
    });
  }

  executeRemove(followup: FollowUp) {
    this.removing = true;
    return BackendService.patientApi.deleteFollowUpById(followup.id)
      .then(() => {
        const index = this.value.findIndex((x) => x.id === followup.id);
        if (index !== -1) {
          this.value.splice(index, 1);
        }

        this.removing = false;
      })
      .catch(() => {
        this.removing = false;
      });
  }
}
