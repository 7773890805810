


















































































































































import { Store } from 'vuex';
import { Getter } from 'vuex-class';
import Vue from 'vue';
import { Component, Ref } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import BackendService from '@/api/backendService';
import {
  Affiliation,
  AffiliationListItem,
  CurrentUserInfo,
  RoleListItem,
  ShippingCompanyFullModel,
  ShippingNotificationEmailModel,
  StructureListItem,
} from '@/client-axios';
import { fakeDownload, getDownloadRequestConfig } from '@/utils/exporting';
import { RootConst } from '@/store';
import { AuthConst } from '@/store/constants';
import RootState from '@/store/rootState';
import { roles } from '@/mixins/const-mixin';
import AnalyzesPending from './AnalyzesPending.vue';

@Component({
  inheritAttrs: false,
  components: {
    AnalyzesPending,
  },
})
export default class AnalyzesPendingToBeMailed extends Vue {
  created() {
    this.$store.dispatch(RootConst.Actions.loadRoles);
  }

  @Getter(AuthConst.Getters.currentAffiliation)
  readonly currentAffilation: Affiliation | undefined;

  get currentUser(): CurrentUserInfo | undefined {
    return (this.$store as Store<RootState>).state.auth?.user ?? undefined;
  }

  @Ref()
  readonly analyzesPending: AnalyzesPending;

  downloadingShippingList = false;

  downloadingShippingListError: null | Error = null;

  downloadShippingList(selectedAnalyzesIds: number[]) {
    if (selectedAnalyzesIds.length === 0) {
      return null;
    }

    this.downloadingShippingList = true;
    this.downloadingShippingListError = null;
    return BackendService.analysesApi.getShippingList(selectedAnalyzesIds, getDownloadRequestConfig())
      .then((response) => {
        fakeDownload(response);
        this.downloadingShippingList = false;
      })
      .catch((error) => {
        this.downloadingShippingListError = error;
        this.downloadingShippingList = false;
      });
  }

  downloadingLabels = false;

  downloadingLabelsError: null | Error = null;

  downloadLabels(selectedAnalyzesIds: number[]) {
    if (selectedAnalyzesIds.length === 0) {
      return null;
    }

    this.downloadingLabels = true;
    this.downloadingLabelsError = null;
    return BackendService.analysesApi.getAnalysisLabels(selectedAnalyzesIds, getDownloadRequestConfig())
      .then((response) => {
        fakeDownload(response);
        this.downloadingLabels = false;
      })
      .catch((error) => {
        this.downloadingLabelsError = error;
        this.downloadingLabels = false;
      });
  }

  shippingCompanies: ShippingCompanyFullModel[] = [];

  loadingShippingCompanies = false;

  loadingShippingCompaniesError: null | Error = null;

  loadShippingCompanies() {
    this.loadingShippingCompanies = true;
    this.loadingShippingCompaniesError = null;
    return BackendService.shippingCompanies.listShippingCompanies()
      .then(({ data }) => {
        this.shippingCompanies = data;
        this.loadingShippingCompanies = false;
      })
      .catch((error) => {
        this.loadingShippingCompaniesError = error;
        this.loadingShippingCompanies = false;
      });
  }

  contactPersons: AffiliationListItem[] = [];

  loadingContactPersons = false;

  loadContactPersons() {
    if (!this.currentAffilation?.structureId) {
      throw new Error('Текущая организация не определена');
    }

    const contactPersonRole: RoleListItem | undefined = this.$store.getters[RootConst.Getters.roleByName](roles.ContactPerson);
    if (!contactPersonRole) {
      throw new Error('Невозможно найти роль "Контактное лицо"');
    }

    this.loadingContactPersons = true;
    return BackendService.usersApi.listAffiliations([this.currentAffilation.structureId], [contactPersonRole.id])
      .then(({ data }) => {
        this.loadingContactPersons = false;
        this.contactPersons = data;
      })
      .catch((error) => {
        this.loadingContactPersons = false;
      });
  }

  selectContactPerson(affiliation: AffiliationListItem) {
    if (this.sendNotificationModel) {
      this.sendNotificationModel.contactPersonName = affiliation.user.name;
      this.sendNotificationModel.contactPersonEmail = affiliation.user.email;
      this.sendNotificationModel.contactPersonPhone = affiliation.user.phone ?? '';
    }
  }

  @Ref()
  readonly form: FormModel;

  sendNotificationModel: null | ShippingNotificationEmailModel = null;

  sendNotification(selectedAnalyzesIds: number[]) {
    this.sendNotificationModel = {
      analysesIds: selectedAnalyzesIds,
      shippingCompanyId: undefined as unknown as number,
      contactPersonName: this.currentUser?.userName ?? '',
      contactPersonPhone: this.currentUser?.userPhone ?? '',
      contactPersonEmail: this.currentUser?.userEmail ?? '',
      contactHours: '8-18',
      comment: '',
    };

    this.loadContactPersons()
      .then(this.addCurrentUserToContactPersons);

    this.loadShippingCompanies()
      .then(this.autoselectShippingCompany);
  }

  addCurrentUserToContactPersons() {
    if (!this.currentAffilation || !this.currentUser) {
      return;
    }

    this.contactPersons = [
      this.getCurrentUserAsContactPerson(),
      ...this.contactPersons,
    ];
  }

  getCurrentUserAsContactPerson(): AffiliationListItem {
    if (!this.currentAffilation || !this.currentUser) {
      throw new Error('Данные текущего пользователя отсутствуют');
    }

    return {
      id: this.currentAffilation.id,
      userId: this.currentUser.userId,
      structureId: this.currentAffilation.structureId as unknown as number,
      roleId: this.currentAffilation.roleId as unknown as number,
      user: {
        id: this.currentUser.userId as unknown as number,
        email: this.currentUser.userEmail ?? '',
        name: this.currentUser.userName ?? '',
        phone: this.currentUser.userPhone,
        isBlocked: undefined,
        recordStatus: 'A',
      },
      structure: {
        id: this.currentAffilation.structureId,
        title: this.currentAffilation.structureName,
        typeId: -1,
        recordStatus: 'A',
        } as StructureListItem,
      role: {
        id: this.currentAffilation.roleId,
        name: this.currentAffilation.roleName,
        displayName: this.currentAffilation.roleName,
      } as RoleListItem,
      recordStatus: 'A',
    };
  }

  autoselectShippingCompany() {
    if (this.sendNotificationModel && this.shippingCompanies[0]) {
      this.sendNotificationModel.shippingCompanyId = this.shippingCompanies[0].id;
    }
  }

  sendingNotification = false;

  sendingNotificationError: null | Error = null;

  executeSendNotification() {
    if (!this.sendNotificationModel) {
      throw new Error('Данные для отправки отсутствуют');
    }

    this.sendingNotification = true;
    this.sendingNotificationError = null;
    return BackendService.analysesApi.notifyShippingCompany(this.sendNotificationModel)
      .then(() => {
        this.sendingNotification = false;
        this.$emit('changed');
        return this.analyzesPending.reload();
      })
      .catch((error) => {
        this.sendingNotificationError = error;
        this.sendingNotification = false;
      });
  }

  sendNotificationConfirmed() {
    (this.form.validate() as Promise<unknown>)
      .then(this.executeSendNotification)
      .then(this.sendNotificationCanceled);
  }

  sendNotificationCanceled() {
    this.sendNotificationModel = null;
  }
}
