






































import Vue from 'vue';
import Component from 'vue-class-component';
import BackendService from '@/api/backendService';
import { ShippingCompanyCreateModel } from '@/client-axios';

@Component
export default class ShippingCompaniesDetails extends Vue {
  get canEdit() {
    return this.$auth.permissions.has(this.$const.permissions.sections.shippingCompany, this.$const.permissions.actions.modify);
  }

  get shippingCompanyId() {
    return parseInt(this.$route.params.id, 10);
  }

  created() {
    this.load();
  }

  shippingCompany: null | ShippingCompanyCreateModel = null;

  loading = false;

  loadingError: null | Error = null;

  load() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.shippingCompanies.getShippingCompanyById(this.shippingCompanyId)
      .then(({ data }) => {
        this.shippingCompany = data;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }

  get emails(): string[] {
    return this.shippingCompany?.email.split(';') ?? [];
  }
}
