import moment from 'moment';

export interface CalculcatedGrouppedData {
  groupName: string;
  city?: string;
  [key: string]: number | string | undefined;
  total: number;
}

export declare type PeriodType = 'week' | 'month' | 'quarter' | 'year';

export interface IStatisticsFilter {
  dateFrom?: string;
  dateTo?: string;
  periodType?: PeriodType;
}

export const periodTypeName = (value: PeriodType): string => {
  switch (value) {
    case 'week':
      return 'по неделям';
    case 'month':
      return 'по месяцам';
    case 'quarter':
      return 'по кварталам';
    case 'year':
      return 'по годам';
    default:
      throw new Error(`Неизвестный период ${value}`);
  }
};

export const getEmptyFilter = (): IStatisticsFilter => ({
  dateFrom: undefined,
  dateTo: undefined,
  periodType: undefined,
});

export const getColumnTitleByPeriodType = (periodType: PeriodType, timeStep: string): string => {
  switch (periodType) {
    case 'week':
      return [
        moment(timeStep, 'YYYY-W').startOf('week').format('DD.MM'),
        moment(timeStep, 'YYYY-W').endOf('week').format('DD.MM'),
      ].join(' - ');
    case 'month':
      return moment(timeStep).format('MMMM YYYY');
    case 'quarter':
      return moment(timeStep, 'YYYY-Q').format('Qo квартал YYYY');
    case 'year':
      return timeStep;
    default:
      return timeStep;
  }
};
