







































































































































import { FormModel } from 'ant-design-vue';
import { Store } from 'vuex';
import Vue from 'vue';
import { Component, Ref } from 'vue-property-decorator';
import {
  AffiliationCreate,
  AffiliationListItem,
  ClassifierItem,
  LaboratoryProfile,
  Role,
  StructureFull,
} from '@/client-axios';
import BackendService from '@/api/backendService';
import RootState from '@/store/rootState';
import { RootConst } from '@/store';
import { TableColumn } from '@/types/ant-design-vue-types';
import { getActionsColumn } from '@/utils/registry';
import StructureAffiliationEditor from './StructureAffiliationEditor.vue';

@Component({
  components: {
    StructureAffiliationEditor,
  },
})
export default class StructuresDetails extends Vue {
  get canEdit() {
    return this.$auth.permissions.has(this.$const.permissions.sections.structure, this.$const.permissions.actions.modify);
  }

  get canDelete() {
    return this.$auth.permissions.has(this.$const.permissions.sections.structure, this.$const.permissions.actions.delete);
  }

  get canCreateAffiliations() {
    return this.$auth.permissions.has(this.$const.permissions.sections.affiliation, this.$const.permissions.actions.create);
  }

  get structureId() {
    return parseInt(this.$route.params.id, 10);
  }

  structure: null | StructureFull = null;

  created() {
    this.$store.dispatch(RootConst.Actions.loadRoles);
    this.load()
      .then(this.loadParentStructure);
    this.loadAffiliations();
  }

  get roles(): Role[] {
    return (this.$store as Store<RootState>).state.roles;
  }

  loading = false;

  loadingError: null | Error = null;

  load() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.usersApi.getStructurebyId(this.structureId)
      .then(({ data }) => {
        this.structure = data;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }

  parentStructure: null | StructureFull = null;

  loadParentStructure() {
    if (!this.structure?.parentId) {
      return Promise.resolve();
    }
    return BackendService.usersApi.getStructurebyId(this.structure?.parentId)
      .then(({ data }) => {
        this.parentStructure = data;
      });
  }

  get showParentStructure() {
    const store = (this.$store as Store<RootState>);
    const rootType: ClassifierItem = store.getters[RootConst.Getters.classifierItemByName]('structureTypes', 'root');
    return this.structure?.parentId && this.parentStructure?.typeId !== rootType.id;
  }

  get structureIsLaboratory() {
    const store = (this.$store as Store<RootState>);
    const laboratory: ClassifierItem = store.getters[RootConst.Getters.classifierItemByName]('structureTypes', 'laboratory');
    return laboratory.id === this.structure?.typeId;
  }

  remove() {
    this.$confirm({
      title: 'Вы действительно хотите удалить организацию?',
      okText: 'Удалить',
      okButtonProps: {
        props: {
          type: 'danger',
          loading: this.removing,
        },
      },
      onOk: this.executeRemove,
    });
  }

  removing = false;

  removingError: null | Error = null;

  executeRemove() {
    this.removing = true;
    this.removingError = null;
    return BackendService.usersApi.deleteStructureById(this.structureId)
      .then(() => {
        this.removing = false;
      })
      .catch((error) => {
        this.removing = false;
        this.removingError = error;
      });
  }

  get analyzesTemplates() {
    return (this.$store as Store<RootState>).state.analyzesTemplates;
  }

  saving = false;

  savingId: null | number = null;

  onAnalyzesTemplateChecked(templateId: number, checked: boolean) {
    this.saving = true;
    this.savingId = templateId;
    const profile: LaboratoryProfile = {
      laboratoryId: this.structureId,
      profileId: templateId,
    };
    const method = checked ? BackendService.usersApi.addLaboratoryProfile : BackendService.usersApi.removeLaboratoryProfile;
    return method.call(BackendService.usersApi, profile)
      .then(() => {
        this.saving = false;
        this.savingId = null;

        if (!this.structure) {
          throw new Error('Организация не загружена');
        }

        if (!this.structure.templatesIds) {
          this.structure.templatesIds = [];
        }

        if (checked) {
          this.structure.templatesIds.push(templateId);
        } else {
          const index = this.structure.templatesIds.findIndex((x) => x === templateId);
          this.structure.templatesIds.splice(index, 1);
        }
      })
      .catch(() => {
        this.saving = false;
      });
  }

  loadingAffiliations = false;

  affiliations: AffiliationListItem[] = [];

  loadAffiliations() {
    this.loadingAffiliations = true;
    return BackendService.usersApi.listAffiliations([this.structureId])
      .then(({ data }) => {
        this.affiliations = data;
        this.loadingAffiliations = false;
      })
      .catch(() => {
        this.loadingAffiliations = false;
      });
  }

  get persons() {
    return this.affiliations;
  }

  // eslint-disable-next-line
  get affiliationsColumns(): TableColumn<AffiliationListItem>[] {
    const columns: TableColumn<AffiliationListItem>[] = [
      {
        dataIndex: 'user.name',
        title: 'ФИО',
      },
      {
        dataIndex: 'user.email',
        title: 'Email',
        customRender: (email: string, row) => (
          <router-link to={{ name: 'users-details', params: { id: row.userId } }}>
            {email}
          </router-link>
        ),
      },
      {
        dataIndex: 'user.phone',
        title: 'Телефон',
      },
      {
        dataIndex: 'role.displayName',
        title: 'Роль',
      },
    ];
    if (this.canDelete) {
      columns.push(getActionsColumn());
    }
    return columns;
  }

  newPerson: null | AffiliationCreate = null;

  addPerson() {
    this.newPerson = {
      userId: undefined as unknown as number,
      structureId: this.structureId,
      roleId: undefined as unknown as number,
    };
  }

  @Ref()
  readonly addPersonForm: FormModel;

  validateAddPersonForm() {
    return this.addPersonForm.validate() as Promise<unknown>;
  }

  addPersonConfirm() {
    return this.validateAddPersonForm()
      .then(this.executeAddPerson);
  }

  executeAddPerson() {
    if (!this.newPerson) {
      throw new Error('Данные нового контактного лица отсутствуют');
    }

    return BackendService.usersApi.createAffiliation(this.newPerson)
      .then(() => {
        this.loadAffiliations();
        this.addPersonCancel();
      });
  }

  addPersonCancel() {
    this.newPerson = null;
  }

  removePerson(item: AffiliationListItem) {
    this.$confirm({
      title: 'Вы действительно хотите удалить связь?',
      onOk: () => this.executeRemovePerson(item),
    });
  }

  removingPerson = false;

  executeRemovePerson(item: AffiliationListItem) {
    this.removingPerson = true;
    return BackendService.usersApi.deleteAffiliationById(item.id)
      .then(() => {
        const index = this.affiliations.findIndex((x) => x.id === item.id);
        this.affiliations.splice(index, 1);
        this.removingPerson = false;
      })
      .catch(() => {
        this.removingPerson = false;
      });
  }
}
