






























































import moment from 'moment';
import Vue from 'vue';

import { Component, Prop } from 'vue-property-decorator';
import { FollowUpCreate, FollowUpEventCreate } from '@/client-axios';
import { TableColumn } from '@/types/ant-design-vue-types';

export const predefinedFollowUpMonths = [2, 6, 12, 2 * 12, 3 * 12, 4 * 12, 5 * 12];

@Component
export default class FollowUpEditor extends Vue {
  @Prop({ required: true })
  readonly value: FollowUpCreate;

  created() {
    this.addEvent();
  }

  predefinedMonths = predefinedFollowUpMonths.map((months) => ({
    value: months,
    label: months === 12 ? '1 год' : moment.duration(months, 'months').humanize(),
  }));

  // eslint-disable-next-line
  get eventsColumns(): TableColumn<FollowUpEventCreate>[] {
    return [
      {
        key: 'number',
        title: '#',
        customRender: (_: undefined, __, index) => (index + 1).toString(),
        align: 'right',
      },
      {
        dataIndex: 'typeId',
        title: 'Событие',
        scopedSlots: { customRender: 'type' },
        width: '55%',
      },
      {
        dataIndex: 'date',
        title: 'Дата',
        scopedSlots: { customRender: 'date' },
        width: '35%',
      },
      {
        key: 'action',
        width: '5%',
        customRender: (_: undefined, row, index) => (
          <a-button title="Удалить" type="link" size="small" onClick={this.removeEvent.bind(this, index)}>
            <a-icon type="delete" />
          </a-button>
        ),
      },
    ];
  }

  addEvent() {
    if (!this.value.events) {
      this.value.events = [];
    }

    this.value.events.push({
      typeId: undefined as unknown as number,
      date: undefined,
    });
  }

  removeEvent(index: number) {
    if (!this.value.events) {
      return;
    }

    this.value.events.splice(index, 1);
  }
}
