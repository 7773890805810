





















































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import BackendService from '@/api/backendService';
import { Analyses, Logins, Patients } from '@/client-axios';
import {
  getEmptyFilter,
  IStatisticsFilter,
} from '@/utils/statistics';
import { RootConst } from '@/store';
import StatisticsGroup from './StatisticsGroup.vue';
import StatisticsChart, { DataType } from './StatisticsChart.vue';
import StatisticsFilter from './StatisticsFilter.vue';
import ChartTypeDropdown from './ChartTypeDropdown.vue';

interface TotalCounts {
  patients: null | number;
  analyzes: null | number;
  users: null | number;
  structures: null | number;
}

@Component({
  components: {
    StatisticsGroup,
    StatisticsFilter,
    StatisticsChart,
    ChartTypeDropdown,
  },
})
export default class Statistics extends Vue {
  created() {
    this.loadStatistics();
    this.loadTotals();
  }

  onFilterApply() {
    this.loadStatistics();
  }

  loadStatistics() {
    const newFilter: IStatisticsFilter = { ...this.patientsFilter };
    return Promise.all([
      this.loadPatientsStatistics(newFilter),
      this.loadAnalyzesStatistics(newFilter),
      this.loadLoginsStatistics(newFilter),
    ])
      .then(() => {
        this.appliedFilter = newFilter;
      });
  }

  totals: TotalCounts = {
    patients: null,
    analyzes: null,
    users: null,
    structures: null,
  };

  loadTotals() {
    BackendService.patientApi.listPatients(1, 1)
      .then(({ data }) => {
        this.totals.patients = data.totalCount;
      });
    BackendService.analysesApi.listAnalyses(1, 1)
      .then(({ data }) => {
        this.totals.analyzes = data.totalCount;
      });
    BackendService.usersApi.listUser(1, 1)
      .then(({ data }) => {
        this.totals.users = data.totalCount;
      });
    this.$store.dispatch(RootConst.Actions.loadStructures)
      .then((structures) => {
        this.totals.structures = structures.length;
      });
  }

  patientsFilter: IStatisticsFilter = getEmptyFilter();

  appliedFilter: IStatisticsFilter = getEmptyFilter();

  clearPatientsFilter() {
    this.patientsFilter = getEmptyFilter();
  }

  patientsData: Patients | null = null;

  patientsLoading = false;

  patientsLoadingError: null | Error = null;

  loadPatientsStatistics(filter: IStatisticsFilter) {
    this.patientsLoading = true;
    this.patientsLoadingError = null;
    return BackendService.statisticsApi.getPatientsStatistics(
      filter.dateFrom,
      filter.dateTo,
      filter.periodType,
    )
      .then(({ data }) => {
        this.patientsData = data;
        this.patientsLoading = false;
      })
      .catch((error) => {
        this.patientsLoading = false;
        this.patientsLoadingError = error;
      });
  }

  showPatientsChart = false;

  patientsDataChartType: DataType = 'basic';

  analyzesData: Analyses | null = null;

  analyzesLoading = false;

  analyzesLoadingError: null | Error = null;

  loadAnalyzesStatistics(filter: IStatisticsFilter) {
    this.analyzesLoading = true;
    return BackendService.statisticsApi.getAnalysesStatistics(
      filter.dateFrom,
      filter.dateTo,
      filter.periodType,
    )
      .then(({ data }) => {
        this.analyzesData = data;
      })
      .catch((error) => {
        this.analyzesLoadingError = error;
      })
      .then(() => {
        this.analyzesLoading = false;
      });
  }

  showAnalyzesChart = false;

  analyzesDataChartType: DataType = 'basic';

  loginsData: Logins | null = null;

  loginsLoading = false;

  loginsLoadingError: null | Error = null;

  loadLoginsStatistics(filter: IStatisticsFilter) {
    this.loginsLoading = true;
    return BackendService.statisticsApi.getLoginsStatistics(
      filter.dateFrom,
      filter.dateTo,
      filter.periodType,
    )
      .then(({ data }) => {
        this.loginsData = data;
        this.loginsLoading = false;
      })
      .catch((error) => {
        this.loginsLoading = false;
        this.loginsLoadingError = error;
      });
  }

  showLoginsChart = false;

  loginsDataChartType: DataType = 'basic';
}
