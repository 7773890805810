









































import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import { getActionsColumn } from '@/utils/registry';
import { Affiliation, PatientListItem } from '@/client-axios';
import BackendService from '@/api/backendService';
import { AuthConst } from '@/store/constants';
import { TableColumn } from '@/types/ant-design-vue-types';
import { getPatientsColumns } from './Patients.vue';

@Component
export default class PatientTransfer extends Vue {
  get patientsColumns(): TableColumn<PatientListItem>[] {
    return [
      ...getPatientsColumns(this).filter((x) => x.key !== 'structureId'),
      getActionsColumn(),
    ];
  }

  created() {
    this.load();
  }

  patients: PatientListItem[] = [];

  loading = false;

  loadingError: null | Error = null;

  load() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.patientApi.listUnattachedPatients()
      .then(({ data }) => {
        this.patients = data;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }

  @Getter(AuthConst.Getters.currentAffiliation)
  readonly currentAffiliation: Affiliation | undefined;

  activePatientId: null | number = null;

  finishTransfer(patientId: number) {
    this.activePatientId = patientId;
  }

  finishingTransfer = false;

  finishingTransferError: null | Error = null;

  executeFinishTransfer() {
    if (!this.currentAffiliation) {
      throw new Error('Текущая связь пользователя не определена');
    }

    if (!this.activePatientId) {
      throw new Error('Пациент не указан');
    }

    this.finishingTransfer = true;
    this.finishingTransferError = null;
    return BackendService.patientApi.setAffiliationToId(this.activePatientId, { id: this.currentAffiliation.id })
      .then(({ data }) => {
        if (!data.succeeded) {
          throw new Error(data.message);
        }
        this.finishingTransfer = false;
        this.finishTransferCancel();
        this.load();
      })
      .catch((error) => {
        this.finishingTransfer = false;
        this.finishingTransferError = error;
      });
  }

  finishTransferCancel() {
    this.activePatientId = null;
  }
}
