import { AxiosRequestConfig, AxiosResponse } from 'axios';

export const getDownloadRequestConfig = (): AxiosRequestConfig => ({ responseType: 'blob' });

const fileNameRegExp = /filename\*?=([^']*'')?([^;]*)/;

export const getFileName = (response: AxiosResponse) => {
  const contentDisposition = (response.headers['content-disposition'] as string | undefined);
  if (!contentDisposition) {
    return 'file';
  }

  const matches = fileNameRegExp.exec(contentDisposition);
  if (matches && matches.length > 2) {
    return decodeURIComponent(matches[2]);
  }
  return 'file';
};

export function fakeDownload(response: AxiosResponse<unknown>, fileName?: string) {
  if (!fileName) {
    fileName = getFileName(response);
  }

  const actualData = new Blob([response.data as Blob]);
  const fileURL = window.URL.createObjectURL(actualData);
  const fileLink = document.createElement('a');
  fileLink.href = fileURL;
  fileLink.setAttribute('download', fileName);
  document.body.appendChild(fileLink);
  fileLink.click();
  fileLink.remove();
}

export default {
  getDownloadRequestConfig,
  getFileName,
  fakeDownload,
};
