






















import Vue from 'vue';

import { Component, Ref } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import { Patient, PatientModify } from '@/client-axios';
import BackendService from '@/api/backendService';
import PatientEditor from './PatientEditor.vue';

@Component({
  components: {
    PatientEditor,
  },
})
export default class EditPatient extends Vue {
  get patientId() {
    return parseInt(this.$route.params.id, 10);
  }

  created() {
    this.load();
  }

  loading = false;

  loadingError: null | Error = null;

  patient: null | Patient = null;

  load() {
    this.loadingError = null;
    this.loading = true;
    return BackendService.patientApi.getPatientById(this.patientId)
      .then(({ data }) => {
        this.loading = false;
        this.patient = data;
        this.model = {
          firstName: data.firstName,
          lastName: data.lastName,
          middleName: data.middleName,
          externalId: data.externalId,
          birthDate: data.birthDate,
          sexId: data.sexId,
          diagnosisData: data.diagnosisData,
          affiliationId: data.affiliationId,
        };
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }

  model: null | PatientModify = null;

  @Ref()
  readonly form!: FormModel;

  validateForm() {
    return (this.form.validate() as Promise<unknown>);
  }

  saving = false;

  savingError: null | Error = null;

  save() {
    return this.validateForm()
      .then(this.executeSave)
      .catch(() => {
        this.$message.warning('На форме присутствуют ошибки');
      });
  }

  executeSave() {
    if (!this.model) {
      throw new Error('Данные отсутствуют');
    }

    this.savingError = null;
    this.saving = true;
    return BackendService.patientApi.modifyPatient(this.patientId, this.model)
      .then(() => {
        this.saving = false;
        this.$router.push({ name: 'patients-details', params: { id: this.$route.params.id } });
      })
      .catch((error) => {
        this.saving = false;
        this.savingError = error;
      });
  }
}
