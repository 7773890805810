




















































































import { orderBy } from 'lodash';
import Vue from 'vue';
import { Component, Ref, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { FormModel } from 'ant-design-vue';
import { ClassifierItem, StructureCreate, StructureListItem } from '@/client-axios';
import BackendService from '@/api/backendService';
import { RootConst } from '@/store';
import classifiers from '@/utils/classifiers';
import StructureTreeSelect from '@/components/StructureTreeSelect.vue';

@Component({
  components: {
    StructureTreeSelect,
  },
})
export default class StructuresCreate extends Vue {
  model: StructureCreate = {
    parentId: undefined as unknown as number,
    title: '',
    typeId: undefined as unknown as number,
    address: '',
    city: '',
  };

  @Ref()
  readonly form: FormModel;

  create() {
    (this.form.validate() as Promise<unknown>)
      .then(this.executeCreate);
  }

  creating = false;

  creatingError: null | Error = null;

  executeCreate() {
    this.creating = true;
    this.creatingError = null;
    return BackendService.usersApi.createStructure(this.model)
      .then(() => {
        this.creating = false;
        this.$router.push({ name: 'structures' });
      })
      .catch((error) => {
        this.creating = false;
        this.creatingError = error;
      });
  }

  created() {
    this.loadStructures()
      .then(() => {
        this.model.parentId = this.root.id;
      });
  }

  structures: StructureListItem[] = [];

  loadingStructures = false;

  loadingStructuresError: null | Error = null;

  loadStructures() {
    this.loadingStructures = true;
    return this.$store.dispatch(RootConst.Actions.loadStructures)
      .then((structures) => {
        this.loadingStructures = false;
        this.structures = structures;
      })
      .catch((error) => {
        this.loadingStructures = false;
        this.loadingStructuresError = error;
      });
  }

  filterStructuresString = '';

  get filteredStructures() {
    const structures = orderBy(this.structures, (x) => x.title);

    if (this.filterStructuresString) {
      const normalizedSearchString = this.filterStructuresString.trim().toLowerCase();
      return structures.filter((x) => x.title?.toLowerCase().includes(normalizedSearchString));
    }
    return structures;
  }

  typesFilter = (item: ClassifierItem): boolean => item.value !== classifiers.structureTypes.root;

  @Getter(RootConst.Getters.structureRoot)
  readonly root: StructureListItem;

  @Watch('model.parentId')
  onParentIdChanged() {
    const parentStructure = this.structures.find((x) => x.id === this.model.parentId);
    if (parentStructure) {
      this.model.city = parentStructure.city;
    }
  }
}
