





















  import { Component, Vue } from 'vue-property-decorator';
import DocumentsList from './DocumentsList.vue';

  @Component({
    components: {
      DocumentsList,
    },
  })
  export default class Home extends Vue {

  }
