

















import { Getter } from 'vuex-class';
import { Store } from 'vuex';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ClassifierItem, Affiliation, StructureListItem } from '@/client-axios';
import { RootConst } from '@/store';
import RootState from '@/store/rootState';
import {
  filterTree,
  getStructuresLeafs,
  StructureTreeNode,
} from '@/utils/structures';
import { AuthConst } from '@/store/constants';

@Component
export default class StructureTreeSelect extends Vue {
  @Prop({ required: true })
  readonly value: number[] | number;

  @Prop({ required: false, type: Boolean, default: false })
  readonly canHavePatientsOnly: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly currentAffiliationOnly: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly multiple: boolean;

  onValueChange(value: number[] | number) {
    this.$emit('input', value);
  }

  created() {
    this.loadStructures()
      .then(() => {
        if (this.currentAffiliationOnly) {
          return this.loadUserStructuresWithSubstructures();
        }
        return Promise.resolve();
      })
      .then(() => {
        this.structuresTree = this.buildTree();
      });
  }

  loadingStructures = false;

  loadStructures() {
    this.loadingStructures = true;
    return this.$store.dispatch(RootConst.Actions.loadStructures)
      .then(() => {
        this.loadingStructures = false;
      })
      .catch(() => {
        this.loadingStructures = false;
      });
  }

  @Getter(AuthConst.Getters.currentAffiliation)
  readonly currentAffiliation: Affiliation | undefined;

  userStructuresWithChildrenIds: number[] = [];

  userStructuresWithSubstructuresLoading = false;

  loadUserStructuresWithSubstructures() {
    this.userStructuresWithSubstructuresLoading = true;
    return this.$store.dispatch(AuthConst.Actions.getAffiliationStructureWithChildrenIds)
      .then((ids: number[]) => {
        this.userStructuresWithChildrenIds = ids;
        this.userStructuresWithSubstructuresLoading = false;
      })
      .catch(() => {
        this.userStructuresWithSubstructuresLoading = false;
      });
  }

  searchStructuresTreeValue = '';

  get structures(): StructureListItem[] {
    return (this.$store as Store<RootState>).state.structures ?? [];
  }

  structuresTree: StructureTreeNode[] = [];

  buildTree(): StructureTreeNode[] {
    let { structures } = this;

    if (this.currentAffiliationOnly) {
      structures = structures.filter((x) => this.userStructuresWithChildrenIds.includes(x.id));
    }

    if (this.canHavePatientsOnly) {
      const structureTypesCanHavePatients: ClassifierItem[] = this.$store.getters[RootConst.Getters.structureTypesCanHavePatients];
      const allowedTypesIds = structureTypesCanHavePatients.map((x) => x.id);
      return filterTree(structures, (item) => allowedTypesIds.includes(item.typeId));
    }

    const tree = getStructuresLeafs<StructureTreeNode>(null, structures, new Set(), (item, children) => ({
      parentId: item.parentId,
      typeId: item.typeId,
      title: item.title,
      value: item.id,
      children: children,
    }));
    if (tree.length === 0 && structures.length !== 0) {
      return structures.map((x) => ({
        parentId: x.parentId,
        typeId: x.typeId,
        title: x.title,
        value: x.id,
        children: [],
      }));
    }

    return tree;
  }
}
