

























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class EditableCell extends Vue {
  edit = false;

  @Prop({ required: true })
  readonly value: string;

  @Prop({ required: false, type: Boolean, default: false })
  readonly loading: boolean;

  onValueChange(event: { target: HTMLInputElement }) {
    this.$emit('input', event.target.value);
  }

  apply() {
    this.$emit('apply', this.value);
    this.edit = false;
  }
}
