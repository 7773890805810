import { Pagination, SortOrder, TableColumn } from '@/types/ant-design-vue-types';

export const sortIsDesc = (order: SortOrder | undefined) => (order ? order === 'descend' : undefined);

export const getNumberColumn = <T>(pagination?: Pagination): TableColumn<T> => ({
  title: '#',
  key: 'index',
  align: 'right',
  width: '5em',
  customRender: (_: undefined, __:T, index: number) => {
    const base = pagination ? (((pagination.current ?? 1) - 1) * (pagination.pageSize ?? 10)) : 0;
    return (base + index + 1).toString();
  },
});

export const getActionsColumn = <T>(): TableColumn<T> => ({
  title: '',
  key: 'actions',
  align: 'center',
  width: '1em',
  scopedSlots: { customRender: 'actions' },
});

export const getDefaultPagination = (): Partial<Pagination> => ({
  total: 0,
  current: 1,
  pageSize: 10,
  hideOnSinglePage: false,
  showTotal: (total: number, range: number[]) => `${range[0]}-${range[1]} из ${total}`,
});

export default {
  sortIsDesc,
};
