import { ComponentOptions } from 'vue';
import store from '@/store';
import { AuthConst } from '@/store/constants';
import { Affiliation } from '@/client-axios';
import { RequiredRule, ValidationRule } from './antd-mixin';
import {
  PermissionAction,
  PermissionScope,
  PermissionSection,
  roles,
} from './const-mixin';

export interface AuthMixinObject {
  permissions: {
    has: (section: PermissionSection, action?: PermissionAction, scope?: PermissionScope) => boolean;
  };
  passwordRules: ValidationRule[];
}

const mixin: ComponentOptions<Vue> = {
  computed: {
    $auth(): AuthMixinObject {
      return {
        permissions: {
          has(section: PermissionSection, action?: PermissionAction, scope?: PermissionScope): boolean {
            const user = store.state.auth?.user;
            if (!user) {
              return false;
            }

            const sectionPermissions = user.permissions.filter((x) => x.section === section);
            if (sectionPermissions.length === 0) {
              return false;
            }

            if (!action) {
              return true;
            }

            const sectionActionPermissions = sectionPermissions.filter((x) => x.action === action);
            if (sectionActionPermissions.length === 0) {
              return false;
            }

            if (!scope) {
              return true;
            }

            const sectionActionScopePermissions = sectionActionPermissions.filter((x) => x.scope === scope);
            return sectionActionScopePermissions.length > 0;
          },
        },
        passwordRules: [
          RequiredRule,
          {
            min: 6,
            message: 'Пароль не может быть короче 6 символов',
          },
        ],
      };
    },
  },
  methods: {
    $isInRole(role: string): boolean {
      return (this.$store.getters[AuthConst.Getters.currentAffiliation] as Affiliation | undefined)?.roleName === role;
    },
    $isAdmin() {
      return this.$isInRole(roles.ChiefAdministrator);
    },
  },
};

export default mixin;

declare module 'vue/types/vue' {
  interface Vue {
    $auth: AuthMixinObject,
    $isInRole: (role: string) => boolean,
    $isAdmin: () => boolean,
  }
}
