import { Module } from 'vuex';
import BackendService from '@/api/backendService';
import { Affiliation, CurrentUserInfo, StructureListItem } from '@/client-axios';
import { roles } from '@/mixins/const-mixin';
import { getStructureAndChildrenIds } from '@/utils/structures';
import RootState from './rootState';
import Const from './auth.constants';
import { AuthState } from './auth.state';
import { RootConst } from './constants';

const LOCAL_STORAGE_SELECTED_AFFILIATION_KEY = 'selectedAffiliation';

const getStoredAffiliation = (): number | null => {
  const str = window.localStorage.getItem(LOCAL_STORAGE_SELECTED_AFFILIATION_KEY);
  if (str) {
    return parseInt(str, 10);
  }
  return null;
};

const setStoredAffiliation = (affiliation: number | null) => {
  if (affiliation) {
    window.localStorage.setItem(LOCAL_STORAGE_SELECTED_AFFILIATION_KEY, affiliation.toString());
  } else {
    window.localStorage.removeItem(LOCAL_STORAGE_SELECTED_AFFILIATION_KEY);
  }
};

const roleOrders: { [name: string]: number } = {
  [roles.ChiefAdministrator]: 0,
  [roles.ClinicAdministrator]: 1,
  [roles.DepartmentHead]: 2,
  [roles.Doctor]: 3,
  [roles.LaboratoryAdministrator]: 4,
  [roles.LaboratoryStaff]: 5,
  [roles.TransplantationClinicStaff]: 6,
  [roles.Consultant]: 7,
  [roles.ContactPerson]: 8,
};

const calculateAffiliationOrder = (affiliation: Affiliation): number => (
  affiliation.roleName ? roleOrders[affiliation.roleName] : 0
);

const rolesThatRestrictPatientsAccess = [
  roles.Doctor,
  roles.DepartmentHead,
  roles.ClinicAdministrator,
  roles.ContactPerson,
];

const authModule: Module<AuthState, RootState> = {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    [Const.Getters.isAuthenticated](state): boolean {
      return !!state.user;
    },
    [Const.Getters.currentAffiliation](state): Affiliation | undefined {
      return state.user?.currentAffiliation ?? undefined;
    },
    [Const.Getters.permissions](state): Set<string> {
      return new Set(state.user?.permissions?.map((x) => [x.section, x.action, x.scope].join('.')));
    },
    [Const.Getters.isPatientsAccessRestricted](state, getters): boolean {
      const currentAffiliation: Affiliation | undefined = getters[Const.Getters.currentAffiliation];
      return !!currentAffiliation?.roleName && rolesThatRestrictPatientsAccess.includes(currentAffiliation.roleName);
    },
  },
  mutations: {
    [Const.Mutations.setUser](state, payload: CurrentUserInfo | null) {
      state.user = payload;
    },
    // [Const.Mutations.selectAffiliation](state, payload: number | null) {
    //   state.currentAffiliationId = payload;
    //   setStoredAffiliation(payload);
    // },
  },
  actions: {
    [Const.Actions.login](context, payload: { userName: string; password: string; }) {
      return BackendService.accountApi.login({
        email: payload.userName,
        password: payload.password,
      })
      .then(({ data: result }) => {
        if (result.succeeded) {
          return context.dispatch(Const.Actions.getCurrent)
            .then(() => result);
        }
        return result;
      });
    },
    [Const.Actions.logout](context) {
      return BackendService.accountApi.logout()
        .then(() => {
          context.commit(Const.Mutations.setUser, null);
          // context.commit(Const.Mutations.selectAffiliation, null);
        });
    },
    [Const.Actions.getCurrent](context) {
      return BackendService.accountApi.current()
        .then(({ data }) => {
          // data.available.affiliations = orderBy(data.available.affiliations ?? [], calculateAffiliationOrder);

          context.commit(Const.Mutations.setUser, data);

          // let selectedAffiliationId = getStoredAffiliation();
          // if (selectedAffiliationId) {
          //   if (this.state.auth?.user?.affiliations?.every((x) => x.id !== selectedAffiliationId)) {
          //     selectedAffiliationId = null;
          //   }
          // }

          // if (!selectedAffiliationId) {
          //   selectedAffiliationId = context.state.user?.affiliations ? context.state.user?.affiliations[0]?.id : null;
          // }

          // context.commit(Const.Mutations.selectAffiliation, selectedAffiliationId);

          return data;
        });
    },
    [Const.Actions.getAffiliationStructureWithChildrenIds](context): Promise<number[]> {
      const currentAffiliation = context.getters[Const.Getters.currentAffiliation] as Affiliation | undefined;
      if (!currentAffiliation) {
        throw new Error('Текущая аффиляция отсутствует');
      }
      return context.dispatch(RootConst.Actions.loadStructures, undefined, { root: true })
        .then((structures: StructureListItem[]) => getStructureAndChildrenIds(structures, currentAffiliation.structureId));
    },
    [Const.Actions.selectAffiliation](context, affiliationId: number): Promise<unknown> {
      return BackendService.accountApi.switchAffiliation({ id: affiliationId })
        .then(({ data }) => {
          if (data.succeeded) {
            context.dispatch(Const.Actions.getCurrent);
          } else {
            throw new Error(data.message);
          }
        });
    },
  },
};

export default authModule;
