









































































































































































































import moment from 'moment';
import { Store } from 'vuex';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
  ClassifierItem,
  CourseCreate,
  CourseTemplateListItem,
  Patient,
} from '@/client-axios';
import RootState from '@/store/rootState';
import BackendService from '@/api/backendService';
import { RootConst } from '@/store';

@Component
export default class TreatmentCourseEditor extends Vue {
  @Prop({ required: true })
  readonly value: CourseCreate;

  @Prop({ required: true })
  readonly patient: Patient;

  get sex() {
    return (this.$store as Store<RootState>).getters[RootConst.Getters.classifierItemById](this.patient.sexId) as ClassifierItem;
  }

  get isFemale() {
    return this.sex.value === 'female';
  }

  get isMale() {
    return this.sex.value === 'male';
  }

  created() {
    this.loadTemplates();
    this.onWeightAndHeightChanged();
  }

  templates: CourseTemplateListItem[] = [];

  loadingTemplates = false;

  loadingTemplatesError: Error | null = null;

  loadTemplates() {
    this.loadingTemplates = true;
    this.loadingTemplatesError = null;
    return BackendService.coursesApi.listCourseTemplates()
      .then(({ data }) => {
        this.templates = data;
        this.loadingTemplates = false;
      })
      .catch((error) => {
        this.loadingTemplates = false;
        this.loadingTemplatesError = error;
      });
  }

  filterTemplateString = '';

  get displayedTemplates() {
    const normalizedSearchString = this.filterTemplateString.toLowerCase().trim();
    if (!normalizedSearchString) {
      return this.templates;
    }
    return this.templates.filter((x) => x.name.toLowerCase().includes(normalizedSearchString));
  }

  get weightAndHeight() {
    return [this.value.weight, this.value.height];
  }

  @Watch('weightAndHeight')
  onWeightAndHeightChanged() {
    if (this.value.weight && this.value.height) {
      if (this.isMale) {
        this.value.idealWeight = 50 + 0.91 * (this.value.height - 152);
      }
      if (this.isFemale) {
        this.value.idealWeight = 45 + 0.91 * (this.value.height - 152);
      }

      this.value.surfaceArea = 0.007184 * (this.value.weight ** 0.425) * (this.value.height ** 0.725);
    } else {
      this.value.surfaceArea = undefined;
      this.value.idealWeight = undefined;
    }
  }

  get creatinineAndIdealWeight() {
    return [this.value.creatinine, this.value.idealWeight];
  }

  @Watch('creatinineAndIdealWeight')
  onCreatinineChange() {
    if (this.value.creatinine && this.value.idealWeight) {
      const age = moment.duration(moment().diff(this.patient.birthDate)).years();
      const F = this.isMale ? 1.23 : 1.04;
      const minWeight = Math.max(0, Math.min(this.value.weight ?? this.value.idealWeight, this.value.idealWeight));
      this.value.clearance = ((140 - age) * minWeight * F) / this.value.creatinine;
    }
  }

  @Watch('value.courseTemplateId')
  onTemplateChanged() {
    if (this.value.courseTemplateId) {
      this.countCoursesWithTemplate()
        .then((count) => {
          this.value.courseNumber = count + 1;
        });
    }
  }

  countingCoursesWithTemplate = false;

  coursesCountWithTemplate: null | number = null;

  countCoursesWithTemplate() {
    this.countingCoursesWithTemplate = true;
    return BackendService.coursesApi.listCourses(
      undefined,
      undefined,
      undefined,
      undefined,
      this.patient.id,
      this.value.courseTemplateId,
    )
    .then(({ data }) => {
      this.coursesCountWithTemplate = data.totalCount;
      this.countingCoursesWithTemplate = false;
      return data.totalCount;
    })
    .catch((error) => {
      this.countingCoursesWithTemplate = false;
      throw error;
    });
  }

  get templateIsOther() {
    const selectedTemplate = this.templates.find((x) => this.value.courseTemplateId === x.id);
    return selectedTemplate?.code === 'other';
  }
}
