import { ComponentOptions } from 'vue';

export interface ConstRoles {
  ChiefAdministrator: string;
  Doctor: string;
  DepartmentHead: string;
  ClinicAdministrator: string;
  TransplantationClinicStaff: string;
  LaboratoryStaff: string;
  LaboratoryAdministrator: string;
  Consultant: string;
  ContactPerson: string;
  Observer: string;
}

export const ShortDateValueFormat = 'YYYY-MM-DD';

export enum PermissionAction {
  view = 'view',
  create = 'create',
  modify = 'modify',
  delete = 'delete',
}

export enum PermissionSection {
  patient = 'patient',
  analysis = 'analysis',
  transplantation = 'transplantation',
  consultation = 'consultation',
  user = 'user',
  structure = 'structure',
  affiliation = 'affiliation',
  shippingCompany = 'shipping-company',
  statistics = 'statistics',
  dump = 'dump',
}

export enum PermissionScope {
  ownPatients = 'own-patients',
  ownStructure = 'own-structure',
  ownStructureWithChildren = 'own-structure-with-children',
  system = 'system',
}

export const permissions = {
  actions: PermissionAction,
  sections: PermissionSection,
  scopes: PermissionScope,
};

export const roles = {
  ChiefAdministrator: 'ChiefAdministrator',
  Doctor: 'Doctor',
  DepartmentHead: 'DepartmentHead',
  ClinicAdministrator: 'ClinicAdministrator',
  TransplantationClinicStaff: 'TransplantationClinicStaff',
  LaboratoryStaff: 'LaboratoryStaff',
  LaboratoryAdministrator: 'LaboratoryAdministrator',
  Consultant: 'Consultant',
  ContactPerson: 'ContactPerson',
  Observer: 'Observer',
};

export interface Const {
  squareMetersString: string;
  shortDateValueFormat: string;
  roles: typeof roles;
  barcodeFormat: string;
  barcodeIsValid: (barcode: string) => boolean;
  permissions: typeof permissions;
}

declare module 'vue/types/vue' {
  interface Vue {
    $const: Const,
  }
}

const mixin: ComponentOptions<Vue> = {
  computed: {
    $const(): Const {
      return {
        squareMetersString: 'м\u00B2',
        barcodeFormat: 'EAN8',
        barcodeIsValid(barcode: string): boolean {
          return !!barcode && /[0-9]{7,8}/.test(barcode);
        },
        shortDateValueFormat: ShortDateValueFormat,
        roles: roles,
        permissions: permissions,
      };
    },
  },
};

export default mixin;
