import {
  Configuration,
  PatientsApi,
  AnalysesApi,
  ClassifierApi,
  FilestorageApi,
  CoursesApi,
  RisksApi,
  UsersApi,
  AccountApi,
  DumpApi,
  ConsultationsApi,
  StatisticsApi,
  LoggingApi,
  ShippingCompaniesApi,
  EntitiesApi,
  DiagnosisClassificationApi,
 } from '../client-axios';

const API_BASE_URL = '/api';

export interface HelloWorldResponse {
  message: string;
  status: string;
}

const config: Configuration = {
  basePath: API_BASE_URL,
};

const BackendService = {
  patientApi: new PatientsApi(config),
  analysesApi: new AnalysesApi(config),
  classifiersApi: new ClassifierApi(config),
  fileStorageApi: new FilestorageApi(config),
  coursesApi: new CoursesApi(config),
  risksApi: new RisksApi(config),
  usersApi: new UsersApi(config),
  accountApi: new AccountApi(config),
  dumpApi: new DumpApi(config),
  consultationsApi: new ConsultationsApi(config),
  statisticsApi: new StatisticsApi(config),
  loggingApi: new LoggingApi(config),
  shippingCompanies: new ShippingCompaniesApi(config),
  entitiesApi: new EntitiesApi(config),
  diagnosisClassificationApi: new DiagnosisClassificationApi(config),
};

export default BackendService;
