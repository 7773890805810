import store from '@/store/index';

export default function (val: string | number | undefined, name?: string) {
  if (typeof val === 'string') {
    val = parseInt(val, 10);
  }
  if (name) {
    const classifier = store.state.classifiers.find((x) => x.name === name);
    if (classifier) {
      return classifier.items?.find((x) => x.id === val)?.displayValue;
    }
    return '';
  }
  return store.state.classifiers.map((x) => x.items).flat().find((x) => x?.id === val)?.displayValue ?? '';
}
