









































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FileInfo, TemplateTestField, TestResultField } from '@/client-axios';
import FileList from '@/components/Form/FileList.vue';

export function displayReferenceValues(field: TemplateTestField): string | undefined {
  if (field.analysisResultType?.type === 'options') {
    return field.analysisResultType?.optionGroup?.options?.find((x) => x.isNormal)?.value;
  }

  if (!field.referenceLowerLimit && !field.referenceUpperLimit) {
    return '';
  }

  const result: string[] = [];
  if (field.referenceLowerLimit) {
    result.push(`от ${field.referenceLowerLimit?.toString()}`);
  }
  if (field.referenceUpperLimit) {
    result.push(`до ${field.referenceUpperLimit?.toString()}`);
  }
  return `(${result.join(' ')})`;
}

@Component({
  components: {
    FileList,
  },
})
export default class TestField extends Vue {
  @Prop({ required: true })
  readonly value!: TestResultField;

  @Prop({ required: true })
  readonly field!: TemplateTestField;

  get isTextarea() {
    return this.field.analysisResultType?.type === 'text';
  }

  get isNumber() {
    return this.field.analysisResultType?.type === 'float';
  }

  get isOptions() {
    return this.field.analysisResultType?.type === 'options';
  }

  get isRadio() {
    return this.isOptions && (this.field.analysisResultType?.optionGroup?.options?.length ?? 0) < 3;
  }

  get isSelect() {
    return this.isOptions && !this.isRadio;
  }

  get isString() {
    return this.field.analysisResultType?.type === 'string';
  }

  get isFile() {
    return this.field.analysisResultType?.type === 'file';
  }

  get valueHasAttachment() {
    return this.value.attachment && Object.keys(this.value.attachment).length !== 0;
  }

  onInputText(value: string) {
    this.$emit('input', {
      ...this.value,
      textValue: value,
    } as TestResultField);
  }

  onInputNumber(value: number) {
    this.$emit('input', {
      ...this.value,
      numberValue: value,
    } as TestResultField);
  }

  onInputOption(value: number) {
    this.$emit('input', {
      ...this.value,
      optionId: value,
    } as TestResultField);
  }

  onUpload(file: FileInfo | null) {
    const testField: TestResultField = {
      ...this.value,
      attachment: file ?? undefined,
    };
    this.$emit('input', testField);
  }

  get unit() {
    if (!this.field.analysisResultType?.unit) {
      return '';
    }

    return `${this.field.analysisResultType.unit.delimiter ?? ''}${this.field.analysisResultType.unit.value ?? ''}`;
  }

  get referenceValues() {
    return displayReferenceValues(this.field);
  }
}
