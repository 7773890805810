




































import { orderBy } from 'lodash';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { HCTItem } from '@/client-axios';
import BackendService from '@/api/backendService';

@Component
export default class HctCalculator extends Vue {
  created() {
    this.loadItems();
  }

  @Prop({ required: true, type: Array })
  readonly value: number[];

  @Prop({ required: false, type: Boolean, default: false })
  readonly readonly: boolean;

  items: HCTItem[] = [];

  loadingItems = false;

  loadItems() {
    this.loadingItems = true;
    BackendService.patientApi.getHCTItemsList()
      .then(({ data }) => {
        this.items = orderBy(data, (x) => x.order);
        this.loadingItems = false;
      })
      .catch(() => {
        this.loadingItems = false;
      });
  }

  onItemChecked(item: HCTItem, checked: boolean) {
    if (checked) {
      this.value.push(item.id);
    } else {
      const index = this.value.findIndex((x) => x === item.id);
      this.value.splice(index, 1);
    }
  }

  get currentHct() {
    return this.items.filter((x) => this.value.includes(x.id)).reduce((accum, curr) => accum + curr.weightedScore, 0);
  }

  @Watch('currentHct')
  onHctChanged() {
    this.$emit('update:calculatedValue', this.currentHct);
  }
}
