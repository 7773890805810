


















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import BackendService from '@/api/backendService';
import { fakeDownload, getDownloadRequestConfig } from '@/utils/exporting';

@Component
export default class Dump extends Vue {
  downloadingPatientsWide = false;

  downloadingError: null | Error = null;

  downloadPatientsWide() {
    if (this.downloadingPatientsWide) {
      return Promise.resolve();
    }

    this.downloadingPatientsWide = true;
    this.downloadingError = null;
    return BackendService.dumpApi.downloadPatientsWide(getDownloadRequestConfig())
      .then((response) => {
        fakeDownload(response);
        this.downloadingPatientsWide = false;
      })
      .catch((error) => {
        this.downloadingError = error;
        this.downloadingPatientsWide = false;
      });
  }

  downloadingPatientsAnalysesWide = false;

  downloadPatientsAnalysesWide() {
    if (this.downloadingPatientsAnalysesWide) {
      return Promise.resolve();
    }

    this.downloadingPatientsAnalysesWide = true;
    this.downloadingError = null;
    return BackendService.dumpApi.downloadPatientsAnalysesWide(getDownloadRequestConfig())
      .then((response) => {
        fakeDownload(response);
        this.downloadingPatientsAnalysesWide = false;
      })
      .catch((error) => {
        this.downloadingPatientsAnalysesWide = false;
        this.downloadingError = error;
      });
  }

  downloadingAnalysesLong = false;

  downloadAnalysesLong() {
    if (this.downloadingAnalysesLong) {
      return Promise.resolve();
    }

    this.downloadingAnalysesLong = true;
    this.downloadingError = null;
    return BackendService.dumpApi.downloadAnalysesLong(getDownloadRequestConfig())
      .then((response) => {
        fakeDownload(response);
        this.downloadingAnalysesLong = false;
      })
      .catch((error) => {
        this.downloadingAnalysesLong = false;
        this.downloadingError = error;
      });
  }

  downloadingUsers = false;

  downloadUsers() {
    if (this.downloadingUsers) {
      return Promise.resolve();
    }

    this.downloadingUsers = true;
    this.downloadingError = null;
    return BackendService.dumpApi.downloadUsers(getDownloadRequestConfig())
      .then((response) => {
        fakeDownload(response);
        this.downloadingUsers = false;
      })
      .catch((error) => {
        this.downloadingUsers = false;
        this.downloadingError = error;
      });
  }

  downloadingAnalysesStatuses = false;

  downloadAnalysesStatuses() {
    if (this.downloadingAnalysesStatuses) {
      return Promise.resolve();
    }

    this.downloadingAnalysesStatuses = true;
    this.downloadingError = null;
    return BackendService.dumpApi.downloadAnalysesStatuses(getDownloadRequestConfig())
      .then((response) => {
        fakeDownload(response);
        this.downloadingAnalysesStatuses = false;
      })
      .catch((error) => {
        this.downloadingAnalysesStatuses = false;
        this.downloadingError = error;
      });
  }
}
