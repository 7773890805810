import { AxiosResponse } from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';
import {
  Classifier,
  ClassifierItem,
  ConsultationMessageList,
  Affiliation,
  StructureListItem,
  SubscriptionEvent,
} from '@/client-axios';
import BackendService from '@/api/backendService';
import { roles } from '@/mixins/const-mixin';
import AuthModule from './auth.module';
import RootState from './rootState';
import { RootConst, AuthConst, AuthModuleNamespace } from './constants';

export { RootConst, AuthConst };

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  state: {
    status: null,
    classifiers: [],
    analyzesTemplates: [],
    structures: null,
    notifications: null,
    roles: [],
    subscriptions: [],
    diagnosises: null,
  },
  getters: {
    [RootConst.Getters.status]: (state) => state.status,
    [RootConst.Getters.notifications]: (state) => state.notifications,
    [RootConst.Getters.template]: (state) => (id: number | undefined) => state.analyzesTemplates.find((x) => x.id === id),
    [RootConst.Getters.classifierById]: (state) => (id: number | undefined) => state.classifiers.find((x) => x.id === id),
    [RootConst.Getters.classifierByName]: (state) => (name: string | undefined) => state.classifiers.find((x) => x.name === name),
    [RootConst.Getters.classifierItemById]: (state) => (itemId: number) => state.classifiers.map((x) => x.items ?? [])
      .flat()
      .find((x) => x.id === itemId),
    [RootConst.Getters.classifierItemByName]: (state, getters) => (classifierName: string, itemName: string) => {
      const classifier: Classifier = getters.classifierByName(classifierName);
      return classifier?.items?.find((x) => x.value === itemName);
    },
    [RootConst.Getters.structureTypesCanHavePatients]: (_, getters): ClassifierItem[] => {
      const structureTypes = getters[RootConst.Getters.classifierByName]('structureTypes') as Classifier;
      const allowedStructureTypesValues = ['clinic', 'department'];
      return (structureTypes.items ?? [])
        .filter((x) => x.value && allowedStructureTypesValues.includes(x.value));
    },
    [RootConst.Getters.structureRoot]: (state, getters) => {
      const rootType = getters[RootConst.Getters.classifierItemByName]('structureTypes', 'root') as ClassifierItem;
      return (state.structures ?? []).find((x) => x.typeId === rootType.id);
    },
    [RootConst.Getters.structureFullName]: (state, getters) => (structureId: number, includeCity?: boolean): string => {
      const structureRoot: StructureListItem = getters[RootConst.Getters.structureRoot];
      if (structureId === structureRoot.id) {
        return structureRoot.title;
      }

      let city = '';
      const names = [];
      let lStructureId = structureId;
      while (lStructureId !== structureRoot.id) {
        // eslint-disable-next-line
        const structure = (state.structures ?? []).find((x) => x.id === lStructureId);
        if (!structure || !structure?.parentId) {
          break;
        }

        names.push(structure.title);
        city = city || structure.city;
        lStructureId = structure.parentId;
      }

      if (includeCity && city) {
        names.push(city);
      }

      return names.reverse().join(' / ');
    },
    [RootConst.Getters.roleByName]: (state) => (name: string) => state.roles.find((x) => x.name === name),
  },
  mutations: {
    [RootConst.Mutations.setStatus](state, payload) {
      state.status = payload;
    },
    [RootConst.Mutations.setClassifiers](state, payload) {
      state.classifiers = payload;
    },
    [RootConst.Mutations.setAnalyzesTemplates](state, payload) {
      state.analyzesTemplates = payload;
    },
    [RootConst.Mutations.setStructures](state, payload) {
      state.structures = payload;
    },
    [RootConst.Mutations.setNotifications](state, payload) {
      state.notifications = payload;
    },
    [RootConst.Mutations.setRoles](state, payload) {
      state.roles = payload;
    },
    [RootConst.Mutations.setSubscriptions](state, payload) {
      state.subscriptions = payload;
    },
    [RootConst.Mutations.setDiagnosises](state, payload) {
      state.diagnosises = payload;
    },
  },
  actions: {
    [RootConst.Actions.initialize](context) {
      context.commit(RootConst.Mutations.setStatus, {
        type: 'info',
        message: 'Получение словарей...',
      });
      return Promise.all([
        context.dispatch(RootConst.Actions.loadClassifiers),
        context.dispatch(RootConst.Actions.loadAnalyzesTemplates),
        context.dispatch(RootConst.Actions.loadStructures),
      ])
      .then(() => {
        context.commit(RootConst.Mutations.setStatus, null);
      });
    },
    [RootConst.Actions.loadClassifiers](context) {
      return BackendService.classifiersApi.listClassifiers()
        .then(({ data }) => {
          context.commit('setClassifiers', data);
        });
    },
    [RootConst.Actions.loadAnalyzesTemplates](context) {
      return BackendService.analysesApi.listAnalysisTemplates()
        .then(({ data }) => {
          context.commit('setAnalyzesTemplates', data);
        });
    },
    [RootConst.Actions.loadStructures](context, payload: { force: boolean } = { force: false }) {
      if (context.state.structures && !payload.force) {
        return Promise.resolve(context.state.structures);
      }
      return BackendService.usersApi.listStructures()
        .then(({ data }) => {
          context.commit(RootConst.Mutations.setStructures, data);
          return data;
        });
    },
    [RootConst.Actions.loadDiagnosises](context, payload: { force: boolean } = { force: false }) {
      if (context.state.diagnosises && !payload.force) {
        return Promise.resolve(context.state.diagnosises);
      }
      return BackendService.diagnosisClassificationApi.treeWHOClassification('')
        .then(({ data }) => {
          context.commit(RootConst.Mutations.setDiagnosises, data.slice(0, 10));
          return data;
        });
    },
    [RootConst.Actions.loadNotifications](context) {
      const currentAffiliation = context.getters[AuthConst.Getters.currentAffiliation] as Affiliation | undefined;
      const currentRole = currentAffiliation?.roleName;
      let method: Promise<AxiosResponse<ConsultationMessageList>>;
      if (currentRole === roles.Doctor) {
        method = BackendService.consultationsApi.listConsultationMessages(
          1,
          1,
          undefined,
          undefined,
          undefined,
          currentAffiliation?.id,
          false,
          undefined,
          false,
        );
      } else if (currentRole === roles.Consultant) {
        method = BackendService.consultationsApi.listConsultationMessages(
          1,
          1,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          false,
          true,
        );
      } else {
        return Promise.resolve()
          .then(() => {
            context.commit(RootConst.Mutations.setNotifications, 0);
          });
      }

      return method.then(({ data }) => {
        context.commit(RootConst.Mutations.setNotifications, data.totalCount);
      });
    },
    [RootConst.Actions.loadRoles](context) {
      if (context.state.roles.length !== 0) {
        return Promise.resolve(context.state.roles);
      }
      return BackendService.usersApi.listRoles()
        .then(({ data }) => {
          context.commit(RootConst.Mutations.setRoles, data);
          return data;
        });
    },
    [RootConst.Actions.loadSubscriptions](context): Promise<SubscriptionEvent[]> {
      if (context.state.subscriptions.length !== 0) {
        return Promise.resolve(context.state.subscriptions);
      }
      return BackendService.usersApi.listSubscriptionEvents()
        .then(({ data }) => {
          context.commit(RootConst.Mutations.setSubscriptions, data);
          return data;
        });
    },
    [RootConst.Actions.structuresCanHavePatients]: (context): Promise<StructureListItem[]> => {
      const structureTypesCanHavePatients: ClassifierItem[] = context.getters[RootConst.Getters.structureTypesCanHavePatients];
      const structuresCanHavePatients = (context.state.structures ?? [])
        .filter((x) => structureTypesCanHavePatients.some((y) => y.id === x.typeId));
      const isPatientsAccessRestricted = context.getters[AuthConst.Getters.isPatientsAccessRestricted];
      if (isPatientsAccessRestricted) {
        context.dispatch(AuthConst.Actions.getAffiliationStructureWithChildrenIds)
          .then((userStructuresWithChildrenIds: number[]) => structuresCanHavePatients
            .filter((x) => userStructuresWithChildrenIds.includes(x.id)));
      }
      return Promise.resolve(structuresCanHavePatients);
    },
  },
  modules: {
    [AuthModuleNamespace]: AuthModule,
  },
});
