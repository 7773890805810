

































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FileInfo } from '@/client-axios';
import BackendService from '@/api/backendService';
import readableFileSize from '@/filters/readableFileSize';

export type AntdFileUploadInfoStatusEnum = 'uploading' | 'done' | 'error' | 'removed';

export interface AntdFileUploadInfo<T> {
  name: string;
  status: AntdFileUploadInfoStatusEnum;
  response?: T;
  xhr: XMLHttpRequest;
}

@Component
export default class FileList extends Vue {
  @Prop({ required: false, type: String, default: '/api/filestorage/upload' })
  readonly uploadActionUrl: string;

  @Prop({ required: true })
  readonly value: FileInfo[] | FileInfo | null;

  @Prop({ required: false, type: Boolean, default: false })
  readonly readonly: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly simplified: boolean;

  @Prop({ required: false, type: Number, default: 10 * 1024 * 1024 })
  readonly maxFileSize: number;

  get maxFileSizeMessage(): string {
    return `Размер файла не должен превышать ${readableFileSize(this.maxFileSize)}`;
  }

  @Prop({ required: false, type: Boolean, default: true })
  readonly showRestrictionsInfo: boolean;

  get isValueArray() {
    return Array.isArray(this.value);
  }

  get listData() {
    if (this.isValueArray) {
      return this.value ?? [];
    }
    return this.value ? [this.value] : [];
  }

  get showUploadButton() {
    return !this.readonly && (this.isValueArray || !this.value);
  }

  beforeUpload(file: File, fileList: Array<unknown>) {
    if (file.size > this.maxFileSize) {
      fileList.splice(0, 1);
      this.$message.error(this.maxFileSizeMessage);
      return false;
    }
    return true;
  }

  handleChange(payload: { file: AntdFileUploadInfo<FileInfo>, fileList: Array<unknown> }) {
    if (payload.file.status === 'done' && payload.file.response) {
      if (this.isValueArray) {
        (this.value as FileInfo[]).push(payload.file.response);
      } else {
        this.$emit('input', payload.file.response);
      }
      payload.fileList.splice(0, 1);
    }
  }

  onRemoveFile(file: FileInfo) {
    return this.$confirm({
      title: `Вы действительно хотите удалить файл ${file.fileName}?`,
      okText: 'Удалить',
      okType: 'danger',
      onOk: () => this.removeFile(file),
    });
  }

  removeFile(file: FileInfo) {
    return this.executeRemove(file)
      .then(() => {
        if (this.isValueArray) {
          const fileIndex = (this.value as FileInfo[]).findIndex((x) => x.uuid === file.uuid);
          (this.value as FileInfo[]).splice(fileIndex, 1);
        } else {
          this.$emit('input', null);
        }

        this.$emit('remove', file);
      });
  }

  removing = false;

  executeRemove(file: FileInfo) {
    this.removing = true;
    return BackendService.fileStorageApi.deleteFileByUUID(file.uuid)
      .then(() => {
        this.removing = false;
      })
      .catch(() => {
        this.removing = false;
      });
  }
}
