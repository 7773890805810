











































































import { Store } from 'vuex';
import Vue from 'vue';
import Component from 'vue-class-component';
import {
  AnalysisReceivedResponse,
  AnalysisResultListItem,
  ClassifierItem,
  TemplateAnalysisList,
} from '@/client-axios';
import BackendService from '@/api/backendService';
import focusDirective from '@/directives/focus';
import { TableColumn } from '@/types/ant-design-vue-types';
import { getActionsColumn, getNumberColumn } from '@/utils/registry';
import date from '@/filters/date';
import RootState from '@/store/rootState';
import { RootConst } from '@/store';
import classifiers from '@/utils/classifiers';

@Component({
  directives: {
    focus: focusDirective,
  },
  filters: {
    analyzesCountWord: (count: number) => {
      if (count === 1) {
        return `${count} исследования`;
      }
      if (count > 10 && count < 20) {
        return `${count} исследований`;
      }
      switch (count % 10) {
        case 1:
          return `${count} исследования`;
        default:
          return `${count} исследований`;
      }
    },
  },
})
export default class AnalyzesReceived extends Vue {
  analyzes: AnalysisResultListItem[] = [];

  get sentStatus(): ClassifierItem {
    return this.$store.getters[RootConst.Getters.classifierItemByName]('analysisStatus', classifiers.analysisStatus.sent);
  }

  get receivedStatus(): ClassifierItem {
    return this.$store.getters[RootConst.Getters.classifierItemByName]('analysisStatus', classifiers.analysisStatus.delivered);
  }

  get showStatusMismatchAlert() {
    if (this.markingResult) {
      return false;
    }
    if (this.analyzes.some((x) => x.currentStatusId !== this.sentStatus.id)) {
      return true;
    }
    return false;
  }

  created() {
    this.$barcodeScanner.init(this.onBarcodeScanned);
  }

  onBarcodeScanned(barcode: string) {
    if (this.$const.barcodeIsValid(barcode)) {
      this.barCode = barcode;
      this.loadAnalysis();
    }
  }

  barCode = '';

  loading = false;

  loadingError: null | Error = null;

  loadAnalysis() {
    if (this.barCode === '') {
      return Promise.resolve();
    }

    this.loading = true;
    this.loadingError = null;
    return BackendService.analysesApi.listAnalyses(
      1,
      1,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      this.barCode,
    )
    .then(({ data }) => {
      this.loading = false;
      if (data.items[0]) {
        if (this.analyzes.every((x) => x.id !== data.items[0].id)) {
          this.analyzes.push(data.items[0]);
        }
      }
    })
    .catch((error) => {
      this.loading = false;
      this.loadingError = error;
    });
  }

  get templatesAnalyzes(): TemplateAnalysisList[] {
    return (this.$store as Store<RootState>).state.analyzesTemplates;
  }

  get analyzesColumns(): TableColumn<AnalysisResultListItem>[] {
    return [
      getNumberColumn(),
      {
        dataIndex: 'barCode',
        title: 'Штрихкод',
        customRender: (barCode: string, record) => (
          <router-link
            to={{ name: 'analyzes-details', params: { id: record.id } }}
            title="Перейти к исследованию"
            target="_blank"
            >
            {barCode}
          </router-link>
        ),
      },
      {
        title: 'Тип',
        dataIndex: 'templateAnalysisId',
        customRender: (templateId: number) => this.templatesAnalyzes.find((x) => x.id === templateId)?.name,
      },
      {
        dataIndex: 'patientFIO',
        title: 'Субъект',
        customRender: (patientFIO: string, row) => `${(row.donorId ? 'донор' : '')} ${patientFIO}`,
      },
      {
        dataIndex: 'sampleDate',
        title: 'Дата забора',
        customRender: date,
      },
      {
        title: 'Статус',
        dataIndex: 'currentStatusId',
        customRender: (currentStatusId: number) => (
          <analysis-status-tag
            status-id={currentStatusId}
            />
        ),
      },
      getActionsColumn(),
    ];
  }

  remove(row: AnalysisResultListItem) {
    const idx = this.analyzes.findIndex((x) => x.id === row.id);
    this.analyzes.splice(idx, 1);
  }

  markingReceived = false;

  markingReceivedError: null | Error = null;

  markingResult: null | AnalysisReceivedResponse = null;

  markReceived() {
    this.markingReceived = true;
    this.markingReceivedError = null;
    return BackendService.analysesApi.setAnalysisReceivedStatus({
        barCodes: this.analyzes.map((x) => x.barCode ?? ''),
      })
      .then(({ data }) => {
        this.markingReceived = false;
        this.markingResult = data;

        this.analyzes.forEach((x) => {
          if (x.barCode && data.pass.includes(x.barCode)) {
            x.currentStatusId = this.receivedStatus.id;
          }
        });
      })
      .catch((error) => {
        this.markingReceived = false;
        this.markingReceivedError = error;
      });
  }
}
