























































































import Vue from 'vue';
import { Component, Ref } from 'vue-property-decorator';
import { Store } from 'vuex';
import { FormModel } from 'ant-design-vue';
import { AuthModuleNamespace } from '@/store/constants';
import RootState from '@/store/rootState';
import { RootConst } from '@/store';
import {
  ChangePassword,
  ClassifierItem,
  StructureFull,
} from '@/client-axios';
import BackendService from '@/api/backendService';
import SubscriptionsEditor from '../Users/SubscriptionsEditor.vue';

@Component({
  components: {
    SubscriptionsEditor,
  },
})
export default class User extends Vue {
  get user() {
    return (this.$store as Store<RootState>).state[AuthModuleNamespace]?.user;
  }

  get laboratoryType(): ClassifierItem {
    return this.$store.getters[RootConst.Getters.classifierItemByName]('structureTypes', 'laboratory');
  }

  created() {
    this.loadStructures();
  }

  structures: StructureFull[] = [];

  loadStructures() {
    return Promise.all(this.user?.userAffiliations?.map((x) => BackendService.usersApi.getStructurebyId(x.structureId as number)
      .then(({ data }) => {
        this.structures.push(data);
      })) ?? []);
  }

  get structuresDictionary() {
    return Object.fromEntries(this.structures.filter((x) => x.typeId === this.laboratoryType.id).map((x) => [x.id, x]));
  }

  get templates() {
    const templates = (this.$store as Store<RootState>).state.analyzesTemplates;
    return Object.fromEntries(templates.map((x) => [x.id, x]));
  }

  @Ref()
  readonly changePasswordForm: FormModel;

  changePasswordModel: null | ChangePassword = null;

  changePassword() {
    if (!this.user?.userEmail) {
      throw new Error('EMail не указан');
    }

    this.changePasswordModel = {
      email: this.user.userEmail,
      password: '',
    };
  }

  validateForm() {
    return this.changePasswordForm.validate() as Promise<unknown>;
  }

  changingPassword = false;

  changingPasswordError: null | Error = null;

  changePasswordConfirmed() {
    return this.validateForm()
      .then(this.executeChangePassword);
  }

  executeChangePassword() {
    if (!this.changePasswordModel) {
      throw new Error('Данные для смены пароля отсутствуют');
    }

    this.changingPassword = true;
    this.changingPasswordError = null;
    return BackendService.usersApi.changePassword(this.changePasswordModel)
      .then(() => {
        this.changingPassword = false;
        this.changePasswordCanceled();
      })
      .catch((error) => {
        this.changingPassword = false;
        this.changingPasswordError = error;
      });
  }

  changePasswordCanceled() {
    this.changePasswordModel = null;
  }
}
