































































































































































































































import Vue from 'vue';
import { Store } from 'vuex';
import {
  Component,
  Prop,
  Ref,
  Watch,
} from 'vue-property-decorator';
import { uniqBy } from 'lodash';
import {
  AffiliationFull,
  AffiliationListItem,
  ClassifierItem,
  PatientModify,
} from '@/client-axios';
import { getEmptyDiagnosisData } from '@/utils/patient';
import { leucocytesUnit } from '@/filters/leucocytes';
import { RootConst } from '@/store';
import RootState from '@/store/rootState';
import { AuthConst } from '@/store/constants';
import BackendService from '@/api/backendService';
import { ValidationRule } from '@/mixins/antd-mixin';
import DiagnosisTreeSelect from '@/components/DiagnosisTreeSelect.vue';
import HctCalculator from './HctCalculator.vue';

@Component({
  components: {
    HctCalculator,
    DiagnosisTreeSelect,
  },
})
export default class PatientEditor extends Vue {
  @Prop({ required: false, default: null })
  readonly patientId: number | null;

  @Prop({ required: true })
  readonly value: PatientModify;

  patientStructureId: null | number = null;

  created() {
    this.loadCurrentUserAffiliationAndChildren();
    this.loadAffiliation()
      .then(() => {
        this.patientStructureId = this.affiliation?.structureId ?? null;
      });
  }

  @Watch('patientStructureId')
  onPatientStructureIdChanged() {
    const doctorAffiliationsIds = this.doctorAffiliations.map((x) => x.id);
    if (!doctorAffiliationsIds.includes(this.value.affiliationId)) {
      this.value.affiliationId = undefined as unknown as number;
    }
  }

  affiliation: null | AffiliationFull = null;

  loadingAffiliation = false;

  loadAffiliation() {
    if (!this.value.affiliationId) {
      return Promise.resolve();
    }

    this.loadingAffiliation = true;
    return BackendService.usersApi.getAffiliationById(this.value.affiliationId)
      .then(({ data }) => {
        this.affiliation = data;
        this.loadingAffiliation = false;
      })
      .catch(() => {
        this.loadingAffiliation = false;
      });
  }

  getStructureFullName(id: number): string {
    return this.$store.getters[RootConst.Getters.structureFullName](id, true);
  }

  get affiliationDisabled() {
    return this.affiliations.length === 1;
  }

  get affiliations(): AffiliationListItem[] {
    let result = this.currentAffiliationsAndChildren.filter((x) => x.role.name === this.$const.roles.Doctor);
    if (this.affiliation) {
      result = [...result.filter((x) => x.id !== this.affiliation?.id), { ...this.affiliation, recordStatus: 'A' }];
    }
    return result;
  }

  leucocytesUnit = leucocytesUnit;

  gutter = 20;

  hctciRules: ValidationRule[] = [
    {
      type: 'integer',
      min: 0,
      message: 'Значение не может быть меньше 0',
    },
  ];

  get leucocytesRules(): ValidationRule[] {
    return [
      this.$antd.form.rules.required,
      {
        type: 'number',
        min: 0,
        message: 'Значение не может быть меньше 0',
      },
    ];
  }

  addDiagnosisData() {
    this.value.diagnosisData = {
      ...getEmptyDiagnosisData(),
      id: undefined as unknown as number,
      patientId: undefined as unknown as number,
    };
  }

  get patientAffiliationUserName() {
    return this.affiliation?.user?.name;
  }

  get store() {
    return this.$store as Store<RootState>;
  }

  currentAffiliationsAndChildren: AffiliationListItem[] = [];

  loadCurrentUserAffiliationAndChildren() {
    return this.$store.dispatch(AuthConst.Actions.getAffiliationStructureWithChildrenIds)
      .then((ids: number[]) => {
        if (!this.store.state.structures) {
          throw new Error('Организации не загружены');
        }

        const allowedStructureTypesIds = (this.store.getters[RootConst.Getters.structureTypesCanHavePatients] as ClassifierItem[])
          .map((x) => x.id);
        const currentStructureAndChildren = this.store.state.structures
          .filter((x) => ids.includes(x.id) && allowedStructureTypesIds.includes(x.typeId))
          .map((x) => x.id);
        return BackendService.usersApi.listAffiliations(currentStructureAndChildren)
          .then(({ data: affiliations }) => {
            this.currentAffiliationsAndChildren = affiliations;
          });
      });
  }

  get structuresAffiliations() {
    return uniqBy(this.affiliations, (x) => x.structureId);
  }

  get doctorAffiliations() {
    return this.affiliations.filter((x) => x.structureId === this.patientStructureId && x.role.name === this.$const.roles.Doctor);
  }

  @Ref()
  readonly hctCalculator: HctCalculator;

  showHctCalculcator = false;

  onHctCalculated() {
    if (this.value.diagnosisData) {
      this.value.diagnosisData.HCTCI = this.hctCalculator.currentHct;
    }

    this.showHctCalculcator = false;
  }
}
