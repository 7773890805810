








































































import moment from 'moment';
import Vue from 'vue';
import { Component, Ref, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { FormModel } from 'ant-design-vue';
import BackendService from '@/api/backendService';
import { AnalysisResultCreate, Classifier, TemplateAnalysisList } from '@/client-axios';
import { RootConst } from '@/store';
import RootState from '@/store/rootState';
import PatientInfo from '@/components/PatientInfo.vue';
import DonorInfo from '@/components/DonorInfo.vue';
import AnalysisEditor from './AnalysisEditor.vue';

@Component({
  components: {
    AnalysisEditor,
    PatientInfo,
    DonorInfo,
  },
})
export default class AddAnalysis extends Vue {
  get patientId() {
    if (this.$route.params.patientId) {
      return parseInt(this.$route.params.patientId, 10);
    }
    return undefined;
  }

  get donorId() {
    if (this.$route.query.donorId) {
      return parseInt(this.$route.query.donorId as string, 10);
    }
    return undefined;
  }

  get templateId() {
    const templateCode = this.$route.query.template as string;
    if (templateCode) {
      const template = this.analyzesTemplates.find((x) => x.name?.includes(templateCode));
      if (template) {
        return template.id;
      }
    }
    return undefined;
  }

  get analyzesTemplates() {
    return (this.$store as Store<RootState>).state.analyzesTemplates as TemplateAnalysisList[];
  }

  get biomaterials() {
    return ((this.$store as Store<RootState>).getters[RootConst.Getters.classifierByName]('biomaterial') as Classifier).items ?? [];
  }

  mounted() {
    this.model = {
      patientId: this.patientId,
      donorId: this.donorId,
      sampleDate: moment().format(this.$const.shortDateValueFormat),
      biomaterialId: undefined,
      templateAnalysisId: this.templateId as number,
    };
  }

  model: AnalysisResultCreate = {
    patientId: undefined,
    donorId: undefined,
    sampleDate: undefined,
    biomaterialId: undefined,
    templateAnalysisId: undefined as unknown as number,
  };

  @Ref()
  readonly form!: FormModel;

  validateForm() {
    return (this.form.validate() as Promise<unknown>);
  }

  get selectedTemplate() {
    return this.analyzesTemplates.find((x) => x.id === this.model.templateAnalysisId);
  }

  @Watch('model.templateAnalysisId')
  onTemplateSelected() {
    if (this.selectedTemplate?.biomaterialId) {
      this.model.biomaterialId = this.selectedTemplate.biomaterialId;
    }
  }

  adding = false;

  addingError: null | Error = null;

  add() {
    return this.validateForm()
      .then(this.executeAdd)
      .catch(() => {
        this.$message.warning('На форме присутствуют ошибки');
      });
  }

  executeAdd() {
    if (!this.model.donorId && !this.model.patientId) {
      throw new Error('Идентификатор пациента или донора должен быть указан');
    }

    this.adding = true;
    this.addingError = null;
    return BackendService.analysesApi.createAnalysis(this.model)
      .then(({ data }) => {
        this.adding = false;

        if (this.model.patientId) {
          this.$router.push({ name: 'patients-details', params: { id: this.model.patientId.toString() }, hash: '#analyzes' });
        } else {
          this.$router.replace({ name: 'analyzes-details', params: { id: data.id.toString() ?? '' } });
        }
      })
      .catch((error) => {
        this.adding = false;
        this.addingError = error;
      });
  }
}
