import { render, staticRenderFns } from "./PatientAnalyzes.vue?vue&type=template&id=5feade9a&"
import script from "./PatientAnalyzes.vue?vue&type=script&lang=tsx&"
export * from "./PatientAnalyzes.vue?vue&type=script&lang=tsx&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports