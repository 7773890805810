































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Location } from 'vue-router';
import { AuthConst } from '@/store/constants';
import { Affiliation, ResultStatusModel } from '@/client-axios';
import { RootConst } from '@/store';

@Component
export default class Login extends Vue {
  model = {
    userName: '',
    password: '',
  };

  created() {
    if (this.$store.getters[AuthConst.Getters.isAuthenticated]) {
      this.onAuthenticationSucceeded();
    }
  }

  loggingIn = false;

  loginError: null | Error = null;

  login() {
    this.loggingIn = true;
    this.loginError = null;
    this.loginResult = null;
    this.$store.dispatch(AuthConst.Actions.login, this.model)
      .then((result: ResultStatusModel) => {
        this.loggingIn = false;

        if (result.succeeded) {
          this.$store.dispatch(RootConst.Actions.initialize)
            .then(this.onAuthenticationSucceeded);
        } else {
          this.loginResult = result;
        }
      })
      .catch((error) => {
        this.loggingIn = false;
        this.loginError = error;
      });
  }

  onAuthenticationSucceeded() {
    if (this.$route.query.returnUrl && typeof this.$route.query.returnUrl === 'string') {
      this.$router.push(this.$route.query.returnUrl);
    } else {
      const route = this.getRouteByRole();
      if (route.name !== this.$route.name) {
        this.$router.push(route);
      }
    }
  }

  loginResult: null | ResultStatusModel = null;

  getRouteByRole(): Location {
    const currentRole = (this.$store.getters[AuthConst.Getters.currentAffiliation] as Affiliation | undefined)?.roleName;
    switch (currentRole) {
      case this.$const.roles.Doctor:
      case this.$const.roles.DepartmentHead:
      case this.$const.roles.ClinicAdministrator:
      case this.$const.roles.TransplantationClinicStaff:
        return { name: 'patients' };
      case this.$const.roles.LaboratoryAdministrator:
      case this.$const.roles.LaboratoryStaff:
        return { name: 'analyzes' };
      default:
        return { name: 'home' };
    }
  }
}
