import Const from './auth.constants';

export const AuthModuleNamespace = 'auth';

export const RootConst = {
  Getters: {
    status: 'status',
    template: 'template',
    classifierById: 'classifierById',
    classifierByName: 'classifierByName',
    classifierItemById: 'classifierItemById',
    classifierItemByName: 'classifierItemByName',
    structureTypesCanHavePatients: 'structureTypesCanHavePatients',
    structureRoot: 'structureRoot',
    structureFullName: 'structureFullName',
    notifications: 'notifications',
    roleByName: 'roleByName',
  },
  Mutations: {
    setStatus: 'setStatus',
    setClassifiers: 'setClassifiers',
    setAnalyzesTemplates: 'setAnalyzesTemplates',
    setStructures: 'setStructures',
    setNotifications: 'setNotifications',
    setRoles: 'setRoles',
    setSubscriptions: 'setSubscriptions',
    setDiagnosises: 'setDiagnosises',
  },
  Actions: {
    initialize: 'initialize',
    loadClassifiers: 'loadClassifiers',
    loadAnalyzesTemplates: 'loadAnalyzesTemplates',
    loadStructures: 'loadStructures',
    loadNotifications: 'loadNotifications',
    loadRoles: 'loadRoles',
    loadSubscriptions: 'loadSubscriptions',
    getAffiliationStructureWithChildrenIds: 'getAffiliationStructureWithChildrenIds',
    structuresCanHavePatients: 'structuresCanHavePatients',
    loadDiagnosises: 'loadDiagnosises',
  },
};

export const AuthConst = {
  Getters: {
    isAuthenticated: `${AuthModuleNamespace}/${Const.Getters.isAuthenticated}`,
    currentAffiliation: `${AuthModuleNamespace}/${Const.Getters.currentAffiliation}`,
    permissions: `${AuthModuleNamespace}/${Const.Getters.permissions}`,
    isPatientsAccessRestricted: `${AuthModuleNamespace}/${Const.Getters.isPatientsAccessRestricted}`,
  },
  Actions: {
    login: `${AuthModuleNamespace}/${Const.Actions.login}`,
    logout: `${AuthModuleNamespace}/${Const.Actions.logout}`,
    getCurrent: `${AuthModuleNamespace}/${Const.Actions.getCurrent}`,
    getAffiliationStructureWithChildrenIds: `${AuthModuleNamespace}/${Const.Actions.getAffiliationStructureWithChildrenIds}`,
    selectAffiliation: `${AuthModuleNamespace}/${Const.Actions.selectAffiliation}`,
  },
};

export default {
  AuthConst,
  AuthModuleNamespace,
};
