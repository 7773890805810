













import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AnalysisResultUnit, TemplateTestField, TestResultField } from '@/client-axios';
import { displayReferenceValues } from './TestField.vue';

export function getValueWithUnits(value?: string, unit?: AnalysisResultUnit) {
  if (!value) {
    return '';
  }

  if (!unit) {
    return value;
  }

  return `${value}${unit.delimiter ?? ''}${unit.value ?? ''}`;
}

const getFieldTextHtml = (text?: string) => {
  if (!text) {
    return '';
  }

  return `<div class="display-text">${text}</div>`;
};

@Component
export default class DisplayTestField extends Vue {
  @Prop({ required: true })
  readonly fields!: TestResultField[];

  @Prop({ required: true })
  readonly template!: TemplateTestField;

  @Prop({ required: false, type: Boolean, default: false })
  readonly hideEmpty!: boolean;

  get showField() {
    const fieldPresent = !!this.field;
    if (this.hideEmpty) {
      return fieldPresent && this.displayValue;
    }
    return fieldPresent;
  }

  get field() {
    return this.fields.find((x) => x.templateTestFieldId === this.template.id);
  }

  get displayValue() {
    switch (this.template.analysisResultType?.type) {
      case 'string':
        return this.field?.textValue;
      case 'text':
        return getFieldTextHtml(this.field?.textValue);
      case 'float':
        return getValueWithUnits(
          this.field?.numberValue?.toString(),
          this.template.analysisResultType?.unit,
        );
      case 'options':
        return this.template.analysisResultType?.optionGroup?.options?.find((x) => x.id === this.field?.optionId)?.value;
      default:
        return '';
    }
  }

  get displayLabel() {
    return this.template.name;
  }

  get referenceValues() {
    return displayReferenceValues(this.template);
  }
}
