


















































































































import moment from 'moment';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import StructureTreeSelect from '@/components/StructureTreeSelect.vue';
import DiagnosisTreeSelect from '@/components/DiagnosisTreeSelect.vue';
import { PatientsFilter } from './Patients.vue';

enum CreatedDatePickerType {
  month = 'month',
  range = 'range',
}

const createDatePickerTypeNameDictionary: Record<CreatedDatePickerType, string> = {
  [CreatedDatePickerType.month]: 'Месяц регистрации',
  [CreatedDatePickerType.range]: 'Период регистрации',
};

const getCreatedDatePickerTypeName = (val: CreatedDatePickerType): string => createDatePickerTypeNameDictionary[val];

@Component({
  components: {
    StructureTreeSelect,
    DiagnosisTreeSelect,
  },
  filters: {
    createdDatePickerTypeName: getCreatedDatePickerTypeName,
  },
})
export default class PatientsFilterEditor extends Vue {
  @Prop({ required: true, type: Object })
  readonly value: PatientsFilter;

  @Prop({ required: true, type: Boolean })
  readonly isPatientAccessRestricted: boolean;

  createdDatePickerTypes: CreatedDatePickerType[] = [CreatedDatePickerType.month, CreatedDatePickerType.range];

  createdDatePickerType = CreatedDatePickerType.month;

  get createdDatePickerIsMonth() {
    return this.createdDatePickerType === CreatedDatePickerType.month;
  }

  onMonthChange(val: string) {
    if (val) {
      const month = moment(`${val}`, this.$const.shortDateValueFormat);
      this.value.createdDateFrom = month.startOf('month').format(this.$const.shortDateValueFormat);
      this.value.createdDateTo = month.endOf('month').format(this.$const.shortDateValueFormat);
    } else {
      this.value.createdDateFrom = undefined;
      this.value.createdDateTo = undefined;
    }
  }

  onRangeChange(dates: [string, string]) {
    if (dates[0]) {
      [this.value.createdDateFrom, this.value.createdDateTo] = dates;
    } else {
      this.value.createdDateFrom = undefined;
      this.value.createdDateTo = undefined;
    }
  }
}
