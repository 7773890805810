import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Home from '../views/Home.vue';

import AccountUser from '../views/Account/User.vue';
import AccountLogin from '../views/Account/Login.vue';
import AccountResetPassword from '../views/Account/ResetPassword.vue';
import AccountForgotPassword from '../views/Account/ForgotPassword.vue';
import Notifications from '../views/Account/Notifications.vue';

import Analyzes from '../views/Analyzes/Analyzes.vue';
import AddAnalysis from '../views/Analyzes/AddAnalysis.vue';
import FillAnalysis from '../views/Analyzes/FillAnalysis.vue';
import AnalysisDetails from '../views/Analyzes/AnalysisDetails.vue';
import AnalysisReceived from '../views/Analyzes/AnalyzesReceived.vue';

import Patients from '../views/Patients/Patients.vue';
import AddPatient from '../views/Patients/AddPatient.vue';
import EditPatient from '../views/Patients/EditPatient.vue';
import TransplantationEdit from '../views/Patients/TransplantationEditor.vue';
import PatientDetails from '../views/Patients/PatientDetails.vue';
import PatientTransfer from '../views/Patients/PatientTransfer.vue';

import Statistics from '../views/Statistics/Statistics.vue';
import Dump from '../views/Statistics/Dump.vue';

import Users from '../views/Users/Users.vue';
import UsersCreate from '../views/Users/UsersCreate.vue';
import UsersDetails from '../views/Users/UsersDetails.vue';
import UsersEdit from '../views/Users/UsersEdit.vue';
import UsersLoginsLog from '../views/Users/LoginsLog.vue';

import Structures from '../views/Structures/Structures.vue';
import StructuresCreate from '../views/Structures/StructuresCreate.vue';
import StructuresDetails from '../views/Structures/StructuresDetails.vue';
import StructuresEdit from '../views/Structures/StructuresEdit.vue';

import ShippingCompanies from '../views/ShippingCompanies/ShippingCompanies.vue';
import ShippingCompaniesDetails from '../views/ShippingCompanies/ShippingCompaniesDetails.vue';
import ShippingCompaniesCreate from '../views/ShippingCompanies/ShippingCompaniesCreate.vue';
import ShippingCompaniesEdit from '../views/ShippingCompanies/ShippingCompaniesEdit.vue';

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/account/user',
    name: 'account-user',
    component: AccountUser,
    meta: {
      title: 'Учетная запись',
    },
  },
  {
    path: '/account/login',
    name: 'account-login',
    component: AccountLogin,
    meta: {
      title: 'Авторизация',
      auth: {
        allowAnonymous: true,
      },
    },
  },
  {
    path: '/account/reset-password/:token',
    name: 'account-reset-password',
    component: AccountResetPassword,
    meta: {
      title: 'Установка нового пароля',
      auth: {
        allowAnonymous: true,
      },
    },
  },
  {
    path: '/account/forgot-password',
    name: 'account-forgot-password',
    component: AccountForgotPassword,
    meta: {
      title: 'Восстановление доступа',
      auth: {
        allowAnonymous: true,
      },
    },
  },
  {
    path: '/account/notifications',
    name: 'account-notifications',
    component: Notifications,
    meta: {
      title: 'Уведомления',
    },
  },
  {
    path: '/patients',
    name: 'patients',
    component: Patients,
    meta: {
      title: 'Пациенты',
    },
  },
  {
    path: '/patients/add',
    name: 'patients-add',
    component: AddPatient,
    meta: {
      title: 'Добавить пациента',
    },
  },
  {
    path: '/patients/edit/:id',
    name: 'patients-edit',
    component: EditPatient,
    meta: {
      title: 'Изменить данные пациента',
    },
  },
  {
    path: '/patients/edit/transplantation/:id',
    name: 'patients-edit-transplantation',
    component: TransplantationEdit,
    meta: {
      title: 'Изменить данные аллоТГСК',
    },
  },
  {
    path: '/patients/details/:id',
    name: 'patients-details',
    component: PatientDetails,
    meta: {
      title: 'Информация о пациенте',
    },
  },
  {
    path: '/patients/transfer',
    name: 'patients-transfer',
    component: PatientTransfer,
    meta: {
      title: 'Принять пациента',
    },
  },
  {
    path: '/analyzes',
    name: 'analyzes',
    component: Analyzes,
    meta: {
      title: 'Исследования',
    },
  },
  {
    path: '/analyzes/add/:patientId?',
    name: 'analyzes-add',
    component: AddAnalysis,
    meta: {
      title: 'Добавить исследование',
    },
  },
  {
    path: '/analyzes/fill/:id',
    name: 'analyzes-fill',
    component: FillAnalysis,
    meta: {
      title: 'Внести результат исследования',
    },
  },
  {
    path: '/analyzes/details/:id',
    name: 'analyzes-details',
    component: AnalysisDetails,
    meta: {
      title: 'Информация об исследовании',
    },
  },
  {
    path: '/analyzes/received',
    name: 'analyzes-received',
    component: AnalysisReceived,
    meta: {
      title: 'Отметить полученными',
    },
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: Statistics,
    meta: {
      title: 'Статистика',
    },
  },
  {
    path: '/dump',
    name: 'dump',
    component: Dump,
    meta: {
      title: 'Выгрузка',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      title: 'Учетные записи',
    },
  },
  {
    path: '/users/create',
    name: 'users-create',
    component: UsersCreate,
    meta: {
      title: 'Добавление пользователя',
    },
  },
  {
    path: '/users/details/:id',
    name: 'users-details',
    component: UsersDetails,
    meta: {
      title: 'Информация о пользователе',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: UsersEdit,
    meta: {
      title: 'Изменить пользователя',
    },
  },
  {
    path: '/structures',
    name: 'structures',
    component: Structures,
    meta: {
      title: 'Организации',
    },
  },
  {
    path: '/structures/create',
    name: 'structures-create',
    component: StructuresCreate,
    meta: {
      title: 'Добавить организацию',
    },
  },
  {
    path: '/structures/details/:id',
    name: 'structures-details',
    component: StructuresDetails,
    meta: {
      title: 'Информация об организации',
    },
  },
  {
    path: '/structures/edit/:id',
    name: 'structures-edit',
    component: StructuresEdit,
    meta: {
      title: 'Изменить организацию',
    },
  },
  {
    path: '/users/logins',
    name: 'users-logins',
    component: UsersLoginsLog,
    meta: {
      title: 'Лог событий',
    },
  },
  {
    path: '/shipping-companies',
    name: 'shipping-companies',
    component: ShippingCompanies,
    meta: {
      title: 'Транспортные компании',
    },
  },
  {
    path: '/shipping-companies/details/:id',
    name: 'shipping-companies-details',
    component: ShippingCompaniesDetails,
    meta: {
      title: 'Информация о транспортной компании',
    },
  },
  {
    path: '/shipping-companies/edit/:id',
    name: 'shipping-companies-edit',
    component: ShippingCompaniesEdit,
    meta: {
      title: 'Информация о транспортной компании',
    },
  },
  {
    path: '/shipping-companies/create',
    name: 'shipping-companies-create',
    component: ShippingCompaniesCreate,
    meta: {
      title: 'Добавить транспортную компанию',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
