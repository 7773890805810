



















import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Patient } from '@/client-axios';
import BackendService from '@/api/backendService';

@Component
export default class PatientInfo extends Vue {
  @Prop({ required: true })
  readonly patientId!: number;

  created() {
    this.loadPatientInfo();
  }

  loadingPatientInfo = false;

  loadingPatientInfoError: null | Error = null;

  patientInfo: null | Patient = null;

  @Watch('patientId')
  loadPatientInfo() {
    this.loadingPatientInfo = true;
    this.loadingPatientInfoError = null;
    return BackendService.patientApi.getPatientById(this.patientId)
      .then(({ data }) => {
        this.loadingPatientInfo = false;
        this.patientInfo = data;
      })
      .catch((error) => {
        this.loadingPatientInfo = false;
        this.loadingPatientInfoError = error;
      });
  }
}
