



























































import Vue from 'vue';
import { Component, Ref } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import { UserCreate } from '@/client-axios';
import BackendService from '@/api/backendService';
import { ValidationRule } from '@/mixins/antd-mixin';

@Component
export default class UsersCreate extends Vue {
  model: UserCreate = {
    email: '',
    name: '',
    sendInvitationMail: true,
    phone: '',
  };

  get emailRules(): ValidationRule[] {
    return [
      this.$antd.form.rules.required,
      {
        pattern: /.+@.+\..+/,
        message: 'Невалидный адрес электронной почты',
      },
    ];
  }

  @Ref()
  readonly form: FormModel;

  create() {
    (this.form.validate() as Promise<unknown>)
      .then(this.executeCreate);
  }

  creating = false;

  creatingError: null | Error = null;

  executeCreate() {
    this.creating = true;
    this.creatingError = null;
    return BackendService.usersApi.createUser(this.model)
      .then(({ data }) => {
        this.creating = false;
        this.$router.push({ name: 'users-details', params: { id: data.id.toString() } });
      })
      .catch((error) => {
        this.creating = false;
        this.creatingError = error;
      });
  }
}
