


































































































































import { FormModel } from 'ant-design-vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import { Transplantation } from '@/client-axios';
import BackendService from '@/api/backendService';

@Component({
  components: {
  },
})
export default class TransplantationEditor extends Vue {
  gutter = [10, 10];

  get patientId(): number {
    return parseInt(this.$route.params.id, 10);
  }

  created() {
    this.load();
  }

  value: null | Transplantation = null;

  loading = false;

  loadingError: null | Error = null;

  load() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.patientApi.getTransplantation(this.patientId)
      .then(({ data }) => {
        this.loading = false;
        this.value = data;
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }

  get GVHDGradeRules() {
    return [
      this.$antd.form.rules.required,
      {
        required: true,
        message: 'Необходимо заполнить',
      },
      {
        type: 'integer',
        min: 0,
        message: 'Значение не может быть меньше 0',
      },
      {
        type: 'integer',
        max: 4,
        message: 'Значение не может быть больше 4',
      },
    ];
  }

  @Ref()
  readonly form: FormModel;

  save() {
    (this.form.validate() as Promise<unknown>)
      .then(this.executeSave);
  }

  saving = false;

  savingError: null | Error = null;

  executeSave() {
    if (!this.value) {
      throw new Error('Данные трансплантации отсутсвуют');
    }

    this.saving = true;
    this.savingError = null;
    return BackendService.patientApi.modifyTransplantation(this.patientId, this.value)
      .then(() => {
        this.saving = false;
        this.$router.push({ name: 'patients-details', params: { id: this.patientId.toString() } });
      })
      .catch((error) => {
        this.saving = false;
        this.savingError = error;
      });
  }
}
