






























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Pagination, Sorter, TableColumn } from '@/types/ant-design-vue-types';
import {
  getActionsColumn,
  getDefaultPagination,
  getNumberColumn,
  sortIsDesc,
} from '@/utils/registry';
import { UserListItem } from '@/client-axios';
import BackendService from '@/api/backendService';

interface UsersFilter {
  searchText?: string;
}

const getEmptyFilter = (): UsersFilter => ({
  searchText: undefined,
});

@Component
export default class Users extends Vue {
  get canCreate() {
    return this.$auth.permissions.has(this.$const.permissions.sections.user, this.$const.permissions.actions.create);
  }

  get canEdit() {
    return this.$auth.permissions.has(this.$const.permissions.sections.user, this.$const.permissions.actions.modify);
  }

  get canDelete() {
    return this.$auth.permissions.has(this.$const.permissions.sections.user, this.$const.permissions.actions.delete);
  }

  created() {
    this.load();
  }

  // eslint-disable-next-line
  get columns(): TableColumn<UserListItem>[] {
    return [
      getNumberColumn(this.pagination),
      {
        title: 'Email',
        dataIndex: 'email',
        sorter: true,
        defaultSortOrder: 'ascend',
        customRender: (email: string, user) => (
          <router-link to={{ name: 'users-details', params: { id: user.id } }}>
            { email }
          </router-link>
        ),
      },
      {
        title: 'Имя',
        dataIndex: 'name',
        sorter: true,
      },
      // {
      //   title: 'Заблокирован',
      //   dataIndex: 'isBlocked',
      //   customRender: (blocked: boolean) => (blocked ? <a-icon type="check" /> : ''),
      // },
      getActionsColumn(),
    ];
  }

  filter: UsersFilter = getEmptyFilter();

  onFilterChange() {
    this.pagination = getDefaultPagination();
    this.load();
  }

  pagination: Pagination = getDefaultPagination();

  sorter: Sorter = {
    field: 'email',
    order: 'ascend',
  };

  onTableStateChange(pagination: Pagination, _: unknown, sorter: Sorter) {
    this.pagination = pagination;
    this.sorter = sorter;
    this.load();
  }

  users: UserListItem[] = [];

  loading = false;

  loadingError: null | Error = null;

  load() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.usersApi.listUser(
      this.pagination.current,
      this.pagination.pageSize,
      this.sorter.order ? sortIsDesc(this.sorter.order) : undefined,
      this.sorter.order ? this.sorter.field : undefined,
      this.filter.searchText,
    )
      .then(({ data }) => {
        this.loading = false;
        this.users = data.items ?? [];
        this.pagination.total = data.totalCount ?? 0;
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }

  remove(user: UserListItem) {
    this.$confirm({
      title: `Вы действительно хотите удалить пользователя ${user.email}?`,
      okText: 'Удалить',
      okButtonProps: {
        type: 'danger',
      },
      onOk: () => this.executeRemove(user),
      cancelText: 'Отмена',
    });
  }

  removing = false;

  removingError: null | Error = null;

  executeRemove(user: UserListItem) {
    this.removing = true;
    this.removingError = null;
    return BackendService.usersApi.deleteUserById(user.id)
      .then(() => {
        this.removing = false;
        this.load();
        return true;
      })
      .catch((error) => {
        this.removingError = error;
        this.removing = false;
      });
  }

  get showImpersonateButton() {
    return this.$isInRole(this.$const.roles.ChiefAdministrator);
  }

  impersonating = false;

  impersonateAs(id: number) {
    this.impersonating = true;
    return BackendService.accountApi.loginAs({ id })
      .then(() => {
        window.location.href = '/';
      })
      .catch((error: Error) => {
        this.impersonating = false;
        this.$message.error({
          key: 'impersonate',
          content: `Ошибка представления пользователем: ${error.message}`,
          duration: 0,
        });
      });
  }
}
