














































import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import { ShippingCompanyFullModel } from '@/client-axios';
import BackendService from '@/api/backendService';

@Component
export default class ShippingCompaniesEdit extends Vue {
  get shippingCompanyId(): number {
    return parseInt(this.$route.params.id, 10);
  }

  created() {
    this.load();
  }

  shippingCompany: null | ShippingCompanyFullModel = null;

  loading = false;

  loadingError: null | Error = null;

  load() {
    this.loading = false;
    this.loadingError = null;
    return BackendService.shippingCompanies
      .getShippingCompanyById(this.shippingCompanyId)
      .then(({ data }) => {
        this.shippingCompany = data;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }

  @Ref()
  readonly form: FormModel;

  save() {
    (this.form.validate() as Promise<unknown>).then(this.executeSave);
  }

  saving = false;

  savingError: null | Error = null;

  executeSave() {
    if (!this.shippingCompany) {
      throw new Error('Данные компании отсутствуют');
    }

    this.saving = true;
    this.savingError = null;
    return BackendService.shippingCompanies
      .modifyShippingCompany(this.shippingCompanyId, {
        name: this.shippingCompany.name,
        email: this.shippingCompany.email,
        phone: this.shippingCompany.phone,
      })
      .then(() => {
        this.saving = false;
        this.$router.push({
          name: 'shipping-companies-details',
          params: { id: this.shippingCompanyId.toString() },
        });
      })
      .catch((error) => {
        this.saving = false;
        this.savingError = error;
      });
  }
}
