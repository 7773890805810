















































































































































































import { Store } from 'vuex';
import Vue from 'vue';
import { Component, Ref } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { FormModel } from 'ant-design-vue';
import {
  AffiliationCreate,
  AffiliationListItem,
  ChangePassword,
  Affiliation,
  Role,
  StructureListItem,
  UserFull,
} from '@/client-axios';
import { RootConst, AuthConst } from '@/store';
import RootState from '@/store/rootState';
import BackendService from '@/api/backendService';
import UserAffiliationEditor from './UserAffiliationEditor.vue';
import SubscriptionsEditor from './SubscriptionsEditor.vue';

@Component({
  components: {
    UserAffiliationEditor,
    SubscriptionsEditor,
  },
})
export default class UsersDetails extends Vue {
  get userId() {
    return parseInt(this.$route.params.id, 10);
  }

  created() {
    this.load();
    this.$store.dispatch(RootConst.Actions.loadRoles);
    this.$store.dispatch(RootConst.Actions.loadStructures)
      .then((structures) => {
        this.structures = structures;
      });
  }

  getStructureFullName(structureId: number): string {
    return this.$store.getters[RootConst.Getters.structureFullName](structureId);
  }

  user: null | UserFull = null;

  loading = false;

  loadingError: null | Error = null;

  load() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.usersApi.getUserById(this.userId)
      .then(({ data }) => {
        this.loading = false;
        this.user = data;
      })
      .catch((error) => {
        this.loadingError = error;
        this.loading = false;
      });
  }

  get roles(): Role[] {
    return (this.$store as Store<RootState>).state.roles;
  }

  structures: StructureListItem[] = [];

  newAffilation: null | AffiliationCreate = null;

  get sameAffiliationAdded() {
    return !!this.user?.affiliations?.find((x) => this.newAffilation?.structureId === x.structureId
      && this.newAffilation?.roleId === x.roleId);
  }

  @Getter(AuthConst.Getters.currentAffiliation)
  readonly currentAffiliation: Affiliation | undefined;

  addAffiliation() {
    const isAdmin = this.$isInRole(this.$const.roles.ChiefAdministrator);
    this.newAffilation = {
      userId: this.userId,
      structureId: (isAdmin ? undefined : this.currentAffiliation?.structureId) as unknown as number,
      roleId: undefined as unknown as number,
    };
  }

  @Ref()
  readonly addAffiliationForm: FormModel;

  addAffiliationConfirmed() {
    (this.addAffiliationForm.validate() as Promise<unknown>)
      .then(this.executeAddAffiliaton);
  }

  addingAffiliation = false;

  addingAffiliationError: null | Error = null;

  executeAddAffiliaton() {
    if (!this.newAffilation) {
      throw new Error('Нет данных связи');
    }

    this.addingAffiliation = true;
    this.addingAffiliationError = null;
    return BackendService.usersApi.createAffiliation(this.newAffilation)
      .then(({ data }) => {
        this.addingAffiliation = false;

        if (!this.newAffilation || !this.user?.affiliations) {
          return;
        }

        const role = this.roles.find((x) => x.id === this.newAffilation?.roleId);
        if (!role) {
          throw new Error('Роль не найдена');
        }

        const structure = this.structures.find((x) => x.id === this.newAffilation?.structureId);
        if (!structure) {
          throw new Error('Роль не найдена');
        }

        this.user.affiliations.push({
          id: data.id,
          ...this.newAffilation,
          role: role,
          structure: structure,
          recordStatus: 'A',
        });

        this.addAffiliationCanceled();
      })
      .catch((error) => {
        this.addingAffiliation = false;
        this.addingAffiliationError = error;
      });
  }

  addAffiliationCanceled() {
    this.newAffilation = null;
  }

  removeAffiliation(affiliation: AffiliationListItem) {
    this.$confirm({
      title: 'Вы действительно хотите удалить связь?',
      okText: 'Удалить',
      onOk: () => this.executeRemoveAffiliation(affiliation),
      okButtonProps: {
        props: {
          type: 'danger',
          loading: this.removingAffiliation,
        },
      },
    });
  }

  removingAffiliation = false;

  removingAffiliationError: null | Error = null;

  executeRemoveAffiliation(affiliation: AffiliationListItem) {
    this.removingAffiliation = true;
    this.removingAffiliationError = null;
    return BackendService.usersApi.deleteAffiliationById(affiliation.id)
      .then(() => {
        if (this.user?.affiliations) {
          const affiliationIndex = this.user.affiliations.findIndex((x) => x.id === affiliation.id);
          this.user.affiliations.splice(affiliationIndex, 1);
        }
        this.removingAffiliation = false;
      })
      .catch((error) => {
        this.removingAffiliation = false;
        this.removingAffiliationError = error;
      });
  }

  get canEdit() {
    const currentUser = (this.$store as Store<RootState>).state.auth?.user;
    return this.$auth.permissions.has(
      this.$const.permissions.sections.user,
      this.$const.permissions.actions.modify,
      this.$const.permissions.scopes.system,
    ) || (this.$auth.permissions.has(
      this.$const.permissions.sections.user,
      this.$const.permissions.actions.modify,
      this.$const.permissions.scopes.ownStructureWithChildren,
    ) && this.user?.affiliations.some((x) => currentUser?.available.structures?.includes(x.structureId)));
  }

  changePasswordModel: null | ChangePassword = null;

  changePassword() {
    if (!this.user?.email) {
      throw new Error('EMail не указан');
    }

    this.changePasswordModel = {
      email: this.user.email,
      password: '',
    };
  }

  changingPassword = false;

  changingPasswordError: null | Error = null;

  @Ref()
  readonly changePasswordForm: FormModel;

  validateChangePasswordForm() {
    return this.changePasswordForm.validate() as Promise<unknown>;
  }

  changePasswordConfirmed() {
    return this.validateChangePasswordForm()
      .then(this.executeChangePassword);
  }

  executeChangePassword() {
    if (!this.changePasswordModel) {
      throw new Error('Данные для смены пароля отсутствуют');
    }

    this.changingPassword = true;
    this.changingPasswordError = null;
    return BackendService.usersApi.changePassword(this.changePasswordModel)
      .then(() => {
        this.changingPassword = false;
        this.changePasswordCanceled();
      })
      .catch((error) => {
        this.changingPassword = false;
        this.changingPasswordError = error;
      });
  }

  changePasswordCanceled() {
    this.changePasswordModel = null;
  }

  sendingInvitationEmail = false;

  sendingInvitationEmailError: null | Error = null;

  sendInvitationEmail() {
    if (!this.user?.email) {
      throw new Error('Данные пользователя отсутствуют');
    }

    this.sendingInvitationEmail = true;
    this.sendingInvitationEmailError = null;
    return BackendService.usersApi.sendInvitationEmail({
      email: this.user?.email,
    })
      .then(() => {
        this.sendingInvitationEmail = false;
        this.$message.success({
          key: 'send-invitiation-email',
          content: 'Приветственное письмо успешно оправлено',
          duration: 3,
        });
      })
      .catch((error) => {
        this.sendingInvitationEmail = false;
        this.sendingInvitationEmailError = error;
      });
  }

  get showImpersonateButton() {
    return this.$isInRole(this.$const.roles.ChiefAdministrator);
  }

  impersonating = false;

  impersonatingError: null | Error = null;

  impersonate() {
    this.impersonating = true;
    this.impersonatingError = null;
    return BackendService.accountApi.loginAs({ id: this.userId })
      .then(() => {
        window.location.href = '/';
      })
      .catch((error) => {
        this.impersonating = false;
        this.impersonatingError = error;
      });
  }

  affiliationIdToEditSubscriptions: null | number = null;
}
