import { render, staticRenderFns } from "./Breadcrumbs.vue?vue&type=template&id=365c2660&scoped=true&"
import script from "./Breadcrumbs.vue?vue&type=script&lang=ts&"
export * from "./Breadcrumbs.vue?vue&type=script&lang=ts&"
import style0 from "./Breadcrumbs.vue?vue&type=style&index=0&id=365c2660&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "365c2660",
  null
  
)

export default component.exports