import Vue, { DirectiveOptions } from 'vue';

const focusDirective: DirectiveOptions = {
  inserted(el: HTMLElement) {
    if (el instanceof Vue) {
      ((el as unknown as Vue).$el as HTMLElement).focus();
    } else {
      (el as unknown as HTMLElement).focus();
    }
  },
};

export default focusDirective;
