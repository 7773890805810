


















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { Store } from 'vuex';
import {
  AnalysisResultListItem,
  ClassifierItem,
  TemplateAnalysis,
} from '@/client-axios';
import BackendService from '@/api/backendService';
import { RootConst } from '@/store';
import { AuthConst } from '@/store/constants';
import RootState from '@/store/rootState';

@Component
export default class PendingAnalyzes extends Vue {
  @State('analyzesTemplates')
  readonly templates: TemplateAnalysis[];

  getTemplateName(id: number) {
    return (this.$store as Store<RootState>).state.analyzesTemplates?.find((x) => x.id === id)?.name ?? id;
  }

  @Prop({ required: true, type: String })
  readonly statusName: string;

  get status(): ClassifierItem {
    return this.$store.getters[RootConst.Getters.classifierItemByName]('analysisStatus', this.statusName);
  }

  created() {
    this.loading = true;
    this.loadUserStructuresIds()
      .then(this.load);
  }

  userStructuresIds: number[] = [];

  loadUserStructuresIds() {
    return this.$store.dispatch(AuthConst.Actions.getAffiliationStructureWithChildrenIds)
      .then((ids: number[]) => {
        this.userStructuresIds = ids;
      });
  }

  analyzes: AnalysisResultListItem[] = [];

  loading = false;

  loadingError: null | Error = null;

  load() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.analysesApi.listAnalyses(
      1,
      100,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      this.userStructuresIds,
      this.status.id,
    )
    .then(({ data }) => {
      this.analyzes = data.items;
      this.loading = false;
      this.$emit('loaded', this.analyzes);
    })
    .catch((error) => {
      this.loading = false;
      this.loadingError = error;
    });
  }

  selectedAnalyzesIds: Array<number> = [];

  toggle(item: AnalysisResultListItem) {
    if (this.selectedAnalyzesIds.includes(item.id)) {
      this.selectedAnalyzesIds = this.selectedAnalyzesIds.filter((x) => x !== item.id);
    } else {
      this.selectedAnalyzesIds.push(item.id);
    }
  }

  selectAll() {
    this.selectedAnalyzesIds = this.analyzes.map((x) => x.id);
  }

  reload() {
    this.selectedAnalyzesIds = [];
    this.load();
  }
}
