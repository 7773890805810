




















import { orderBy } from 'lodash';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Store } from 'vuex';
import RootState from '@/store/rootState';
import { Classifier, ClassifierItem } from '@/client-axios';
import { RootConst } from '@/store';

@Component({
  model: {
    prop: 'value',
    event: 'change',
  },
})
export default class ClassifierSelect extends Vue {
  @Prop({ required: true, type: String })
  readonly name: string;

  @Prop({ required: true })
  readonly value: number | number[];

  @Prop({ required: false, type: String, validator: (val: string) => ['radio', 'select'].includes(val) })
  readonly type: 'radio' | 'select';

  @Prop({ required: false, type: Function, default: () => true })
  readonly filter: (x: ClassifierItem) => boolean;

  get componentType() {
    if (this.type) {
      return this.type;
    }
    return this.classifierItems.length > 3 ? 'select' : 'radio';
  }

  get classifierItems() {
    const classifier = ((this.$store as Store<RootState>).getters[RootConst.Getters.classifierByName](this.name) as Classifier);
    if (!classifier) {
      throw new Error(`Классификатор ${this.name} не найден`);
    }
    return orderBy(classifier.items?.filter(this.filter) ?? [], ['order', 'displayValue']);
  }

  updateValue(val?: { target: HTMLInputElement } | number | number[]) {
    if (Array.isArray(val)) {
      this.$emit('change', val);
    } else if (typeof val === 'object') {
      this.$emit('change', val.target.value);
    } else {
      this.$emit('change', val);
    }
  }
}
