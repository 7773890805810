






















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { groupBy, orderBy } from 'lodash';
import { Store } from 'vuex';
import BackendService from '@/api/backendService';
import { Classifier, RiskResult, RiskResultItem } from '@/client-axios';
import { TableColumn } from '@/types/ant-design-vue-types';
import classifier from '@/filters/classifier';
import RootState from '@/store/rootState';
import { RootConst } from '@/store';
import classifiers from '@/utils/classifiers';

const riskCategoryOrderDictionary: { [key: string]: number } = {
  [classifiers.ELN.favorable]: 1,
  [classifiers.ELN.intermediate]: 2,
  [classifiers.ELN.adverse]: 3,
};

@Component
export default class Risk extends Vue {
  @Prop({ required: true, type: Number })
  readonly patientId: number;

  prognostikSurvivanceByRiskCategory = {
    [classifiers.ELN.favorable]: '72% (61-84%)',
    [classifiers.ELN.intermediate]: '60% (54-66%)',
    [classifiers.ELN.adverse]: '45% (38-53%)',
  };

  created() {
    this.calculateRisk();
  }

  beforeUpdate() {
    this.riskCategoryDisplayed = new Set();
  }

  get itemStatuses() {
    return ((this.$store as Store<RootState>).getters[RootConst.Getters.classifierByName]('ELNitemStatus') as Classifier)?.items ?? [];
  }

  get riskCategories() {
    return ((this.$store as Store<RootState>).getters[RootConst.Getters.classifierByName]('ELN') as Classifier)?.items ?? [];
  }

  getColorByRisk = (riskCategoryCode: string) => {
    switch (riskCategoryCode) {
    case classifiers.ELN.favorable:
      return 'green';
    case classifiers.ELN.intermediate:
      return 'blue';
    case classifiers.ELN.adverse:
      return 'red';
    default:
      return undefined;
    }
  }

  calculating = false;

  calculatingError: null | Error = null;

  risk: RiskResult | null = null;

  calculateRisk() {
    this.calculating = true;
    this.calculatingError = null;
    return BackendService.risksApi.getRiskResult(this.patientId)
      .then(({ data }) => {
        this.calculating = false;
        this.risk = data;
      })
      .catch((error) => {
        this.calculating = false;
        this.calculatingError = error;
      });
  }

  get grouppedRisks() {
    return groupBy(this.risk?.items ?? [], (x) => x.riskCategory);
  }

  get orderedRisks() {
    return orderBy(this.risk?.items ?? [], [(x) => riskCategoryOrderDictionary[x.riskCategory], (x) => x.order]);
  }

  riskCategoryDisplayed = new Set<string>();

  get columns(): TableColumn<RiskResultItem>[] {
    return [
      {
        dataIndex: 'riskCategory',
        title: 'Категория',
        width: '25%',
        customRender: (riskCategory: string, row) => {
          const riskCategoryItems = this.grouppedRisks[riskCategory];
          if (!riskCategoryItems) {
            return {};
          }

          const color = this.risk?.overallRisk === riskCategory ? this.getColorByRisk(row.riskCategory) : undefined;
          const text = classifier(row.riskCategoryId);
          const result = {
            children: this.risk?.overallRisk === riskCategory
              ? (<a-tag color={color}>{text}</a-tag>)
              : text,
            attrs: {
              rowSpan: this.riskCategoryDisplayed.has(riskCategory) ? 0 : riskCategoryItems.length,
            },
            style: {
              verticalAlign: 'top',
            },
          };
          this.riskCategoryDisplayed.add(riskCategory);
          return result;
        },
      },
      {
        dataIndex: 'itemStatus',
        width: '1%',
        customRender: (itemStatus: string, row: RiskResultItem) => {
          switch (itemStatus) {
          case classifiers.ELNitemStatus.positive:
            return (
              <a-tooltip title={row.note} placement="left">
                <a-icon type="check" />
              </a-tooltip>
            );
          case classifiers.ELNitemStatus.negative:
            return (
              <a-tooltip title={row.note} placement="left">
                <a-icon type="close" />
              </a-tooltip>
            );
          default:
            return (
              <a-icon type="question" class="text-muted" />
            );
          }
        },
      },
      {
        dataIndex: 'displayValue',
        title: 'Генетическая аномалия',
        customRender: (displayValue: string, row: RiskResultItem) => {
          switch (row.itemStatus) {
          case classifiers.ELNitemStatus.positive:
          case classifiers.ELNitemStatus.negative:
            return this.$createElement('span', {
              domProps: {
                innerHTML: displayValue,
              },
            });
          case classifiers.ELNitemStatus.undefined:
            return this.$createElement('span',
              {
                class: 'text-muted',
                domProps: {
                  innerHTML: displayValue,
                },
              });
          default:
            return '';
          }
        },
      },
    ];
  }
}
