
























































































import { Store } from 'vuex';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
  AffiliationFull,
  Patient,
  PatientModifyExternalId,
  StructureListItem,
} from '@/client-axios';
import BackendService from '@/api/backendService';
import { RootConst } from '@/store';
import RootState from '@/store/rootState';

@Component({
  components: {
  },
})
export default class PatientGeneral extends Vue {
  @Prop({ required: true })
  readonly patient: Patient;

  @Prop({ required: false, type: Boolean, default: true })
  readonly readonly: boolean;

  created() {
    this.loadAffiliation();
  }

  affiliation: null | AffiliationFull = null;

  structureHierarchy: StructureListItem[] = [];

  loadingAffiliation = false;

  loadAffiliation() {
    if (!this.patient?.affiliationId) {
      return Promise.resolve();
    }

    this.loadingAffiliation = true;
    return BackendService.usersApi.getAffiliationById(this.patient.affiliationId)
      .then(({ data }) => {
        this.affiliation = data;
      })
      .then(() => {
        this.loadingAffiliation = false;
      })
      .catch(() => {
        this.loadingAffiliation = false;
      });
  }

  get patientStructureTitle(): string {
    if (this.patient.affiliation?.structureId) {
      return (this.$store as Store<RootState>).getters[RootConst.Getters.structureFullName](this.patient.affiliation?.structureId);
    }
    return '';
  }

  get editExternalIdAvailable(): boolean {
    return this.$isAdmin() || this.$isInRole(this.$const.roles.Consultant);
  }

  editExternalId = false;

  editExternalIdModel: null | PatientModifyExternalId = null;

  @Watch('editExternalId')
  onEditExternalId() {
    if (this.editExternalId) {
      this.editExternalIdModel = {
        externalId: this.patient.externalId,
      };
    } else {
      this.editExternalIdModel = null;
    }
  }

  editingExternalId = false;

  editingExternalIdError: null | Error = null;

  editExternalIdConfirmed() {
    if (!this.editExternalIdModel) {
      throw new Error('Данные для сохранения отсутствуют');
    }

    this.editingExternalId = true;
    return BackendService.patientApi.modifyPatientExternalId(this.patient.id, this.editExternalIdModel)
      .then(() => {
        this.editingExternalId = false;
        this.patient.externalId = this.editExternalIdModel?.externalId;
        this.editExternalId = false;
      })
      .catch((error) => {
        this.editingExternalIdError = error;
        this.editingExternalId = false;
      });
  }
}
