



























































































import Vue from 'vue';
import {
  Component,
  Prop,
  Ref,
  Watch,
} from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import { Donor, DonorCreate } from '@/client-axios';
import BackendService from '@/api/backendService';
import { Pagination, TableColumn } from '@/types/ant-design-vue-types';
import {
  getActionsColumn,
  getDefaultPagination as getDefaultRegistryPagination,
  getNumberColumn,
} from '@/utils/registry';
import EditableCell from '@/components/Form/EditableCell.vue';
import { Tables } from '@/utils/tables';
import DonorEditor from './DonorEditor.vue';

const getDefaultPagination = (): Pagination => ({
  ...getDefaultRegistryPagination(),
  hideOnSinglePage: true,
});

@Component({
  components: {
    EditableCell,
    DonorEditor,
  },
})
export default class Donors extends Vue {
  @Prop({ required: true, type: Number })
  readonly patientId: number;

  @Prop({ required: false, type: Boolean, default: false })
  readonly readonly: boolean;

  created() {
    this.loadDonors();
  }

  donors: Donor[] = [];

  showDeleted = false;

  @Watch('showDeleted')
  onFilterChange() {
    this.loadDonors();
  }

  pagination: Pagination = getDefaultPagination();

  loading = false;

  loadingError: null | Error = null;

  loadDonors() {
    this.loading = true;
    this.loadingError = null;
    return BackendService.patientApi.listDonors(
      this.pagination.current,
      this.pagination.pageSize,
      undefined,
      undefined,
      undefined,
      this.patientId,
      this.showDeleted,
    )
    .then(({ data }) => {
      this.donors = data.items;
      this.pagination.total = data.totalCount;
      this.loading = false;
    })
    .catch((error) => {
      this.loading = false;
      this.loadingError = error;
    });
  }

  get columns(): TableColumn<Donor>[] {
    return [
      getNumberColumn(this.pagination),
      {
        title: 'Идентификатор во внешней системе',
        dataIndex: 'externalId',
        width: '15%',
      },
      {
        title: 'ФИО',
        dataIndex: 'lastName',
        customRender: (_: string, row) => [row.lastName, row.firstName, row.middleName].join(' '),
        width: '25%',
      },
      {
        title: 'Степень родства',
        dataIndex: 'relationDegree',
        width: '15%',
      },
      {
        title: 'HLA типирование',
        dataIndex: 'resultHLAId',
        customRender: (resultHLAId: number | undefined, row) => {
          if (resultHLAId) {
            return (
              <router-link to={{ name: 'analyzes-details', params: { id: resultHLAId } }}>
                Просмотр
              </router-link>
            );
          }
          return (
            <router-link to={{ name: 'analyzes-add', query: { donorId: row.id, template: 'HLA' } }}>
              <a-button>
                Добавить
              </a-button>
            </router-link>
          );
        },
      },
      {
        title: 'Совместимость',
        dataIndex: 'compatibility',
        scopedSlots: { customRender: 'compatibility' },
        width: '20%',
      },
      getActionsColumn(),
    ];
  }

  onTableStateChange(pagination: Pagination) {
    this.pagination = pagination;
    this.loadDonors();
  }

  @Ref()
  readonly form: FormModel;

  newDonor: null | DonorCreate = null;

  add() {
    this.newDonor = {
      patientId: this.patientId,
      resultHLAId: undefined,
      externalId: undefined,
      compatibility: undefined,
    };
  }

  adding = false;

  addingError: null | Error = null;

  addConfirm() {
    return (this.form.validate() as Promise<void>)
      .then(this.executeAdd);
  }

  executeAdd() {
    if (!this.newDonor) {
      throw new Error('Данные донора отсутствуют');
    }

    this.adding = true;
    this.addingError = null;
    return BackendService.patientApi.createDonor(this.newDonor)
      .then(() => {
        this.adding = false;
        this.addCancel();
        this.loadDonors();
      });
  }

  addCancel() {
    this.newDonor = null;
  }

  remove(item: Donor) {
    this.$confirm({
      title: 'Вы действительно хотите удалить донора?',
      cancelText: 'Отмена',
      okText: 'Подтвердить',
      okType: 'danger',
      okButtonProps: {
        props: {
          loading: this.removing,
        },
      },
      onOk: () => this.executeRemove(item),
    });
  }

  removing = false;

  removingError: null | Error = null;

  executeRemove(item: Donor) {
    this.removing = true;
    this.removingError = null;
    return BackendService.patientApi.deleteDonorById(item.id)
      .then(() => {
        this.removing = false;
        this.loadDonors();
      })
      .catch((error) => {
        this.removing = false;
        this.removingError = error;
      });
  }

  undoingRemove = false;

  undoRemove() {
    this.undoingRemove = false;
    return BackendService.entitiesApi.undoDelete({ id: this.patientId, table: Tables.Donors })
      .then(() => {
        this.undoingRemove = false;
        this.loadDonors();
      })
      .catch((error) => {
        this.undoingRemove = false;
        this.$message.error(`При выполнении операции произошла ошибка ${error.message}`);
      });
  }

  saving = false;

  savingError: null | Error = null;

  saveDonor(donor: Donor) {
    this.saving = true;
    this.savingError = null;
    return BackendService.patientApi.modifyDonor(donor.id, donor)
      .then(() => {
        this.saving = false;
      })
      .catch((error) => {
        this.saving = false;
        this.savingError = error;
      });
  }
}
