





















import { debounce } from 'lodash';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { WHODiagnosisItem, WHOTree } from '@/client-axios';
import {
  TreeNode,
} from '@/utils/structures';
import BackendService from '@/api/backendService';

function convertToTreeData(diagnosises: WHOTree[]): TreeNode[] {
  return diagnosises.map((x) => ({
    key: x.id,
    value: x.id,
    title: x.code ? `${x.code} - ${x.name}` : x.name,
    children: x.children ? convertToTreeData(x.children) : [],
    selectable: !!x.code,
    isLeaf: !!x.code,
  }));
}

@Component
export default class DiagnosisTreeSelect extends Vue {
  @Prop({ required: true })
  readonly value: number[] | number;

  onValueChange(value: number[] | number | undefined, selectedTitles: string[] | undefined, ev: { triggerNode?: { dataRef: TreeNode } }) {
    if (!ev.triggerNode) {
      this.$emit('input', undefined, undefined);
    } else {
      const treeNode = ev.triggerNode.dataRef;
      const [code, name] = treeNode.title.split(' - ');
      this.$emit('input', value, { id: treeNode.value, name: name, code: code } as WHODiagnosisItem);
    }
  }

  created() {
    this.onSearch = debounce(this.onSearch, 300) as unknown as (val: string) => Promise<void>;
  }

  loadingDiagnosises = false;

  searchDiagnosisTreeValue = '';

  diagnosisTree: TreeNode[] = [];

  open = false;

  selectedNode: TreeNode | null = null;

  onSearch(searchString: string) {
    this.searchDiagnosisTreeValue = searchString;

    this.performSearch();
  }

  performSearch() {
    if (this.searchDiagnosisTreeValue.length < 3) {
      return Promise.resolve();
    }

    this.loadingDiagnosises = true;
    return BackendService.diagnosisClassificationApi.treeWHOClassification(this.searchDiagnosisTreeValue)
      .then(({ data }) => {
        this.diagnosisTree = convertToTreeData(data);
      })
      .then(() => {
        this.loadingDiagnosises = false;
      })
      .catch(() => {
        this.loadingDiagnosises = false;
      });
  }

  onTreeNodeSelect(val: number, e:{selected: boolean, selectedNodes: TreeNode, node: TreeNode, event: Event}) {
    this.$emit('input', val);
  }
}
