import { DiagnosisDataCreate, Transplantation } from '@/client-axios';

export function getEmptyDiagnosisData(): DiagnosisDataCreate {
  return {
    diagnosisDate: undefined,
    HCTCI: undefined,
    leucocytes: undefined,
    myelodysplasticSyndrome: undefined,
    prevOncologyTreatment: undefined,
    hematologyRemission: undefined,
    hematologyRemissionDate: undefined,
    hctItemsId: [],
    whoDiagnosisId: undefined as unknown as number,
  };
}

export function getEmptyTransplantationData(): Transplantation {
  return {
    engraftment: false,
    isPerformed: false,
    transplantationDate: undefined as unknown as string,
    aGVHD: false,
    aGVHDDate: undefined as unknown as string,
    aGVHDGrade: undefined as unknown as number,
    cGVHD: false,
    cGVHDDate: undefined as unknown as string,
    cGVHDGrade: undefined as unknown as number,
  };
}

export default {
  getEmptyDiagnosisData,
};
