















import { groupBy, sortBy } from 'lodash';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { GroupedCityModel, GroupedModel } from '@/client-axios';
import { TableColumn } from '@/types/ant-design-vue-types';
import { IStatisticsFilter, CalculcatedGrouppedData, getColumnTitleByPeriodType } from '@/utils/statistics';

@Component
export default class StatisticsGroup extends Vue {
  @Prop({ required: true, type: Array })
  readonly value: (GroupedModel | GroupedCityModel)[];

  @Prop({ required: false, type: Boolean, default: false })
  readonly withCity: boolean;

  get showCity(): boolean {
    return this.withCity && this.value.some((x) => typeof (x as GroupedCityModel).city !== 'undefined');
  }

  @Prop({ required: true })
  readonly filter: IStatisticsFilter;

  @Prop({ required: true, type: String })
  readonly groupNameTitle: string;

  get patientsDataSource(): Array<CalculcatedGrouppedData> {
    const data = sortBy(
      Object.entries(groupBy(this.value, (x) => x.groupName))
        .map(([title, group]): CalculcatedGrouppedData => {
          const countsByTimeStep = group.map((y) => [y.timeStep, y.count]);
          return Object.fromEntries([
            ['groupName', title],
            ['city', (group[0] as GroupedCityModel).city],
            ...countsByTimeStep,
            ['total', group.reduce((accum, curr) => accum + curr.count, 0)],
          ]);
        }),
      (x) => x.hospitalDepartmentTitle,
    );

    const totalRow: CalculcatedGrouppedData = data.reduce(
      (accum, curr) => {
        const currTimeSteps = Object.keys(curr).filter((x) => x !== 'total' && x !== 'groupName' && x !== 'city');
        currTimeSteps.forEach((timeStep) => {
          if (!(timeStep in accum)) {
            accum[timeStep] = 0;
          }
          accum[timeStep] = (accum[timeStep] as number) + (curr[timeStep] as number);
          accum.total += curr[timeStep] as number;
        });
        return accum;
      },
      { groupName: 'Всего', total: 0 },
    );

    if (data.length > 0) {
      return [
        ...data,
        totalRow,
      ];
    }

    return [];
  }

  rowIsTotal(index: number): boolean {
    return index === this.patientsDataSource.length - 1;
  }

  rowClassName(record: CalculcatedGrouppedData, index: number): string {
    return this.rowIsTotal(index) ? 'ant-table-row-total' : '';
  }

  // eslint-disable-next-line
  get patientsDataColumns(): TableColumn<CalculcatedGrouppedData>[] {
    const indexColumn: TableColumn<CalculcatedGrouppedData> = {
      key: 'index',
      align: 'right',
      width: '4em',
      customRender: (...parameters) => {
        const index = parameters[2];
        if (!this.rowIsTotal(index)) {
          return (index + 1).toString();
        }
        return '';
      },
      customCell: () => ({
        class: 'ant-table-column-index',
      }),
    };
    const titleColumn: TableColumn<CalculcatedGrouppedData> = {
      dataIndex: 'groupName',
      title: this.groupNameTitle,
      width: '30%',
      customRender: (title: string, row, index: number) => {
        if (this.rowIsTotal(index)) {
          return {
            style: {
              textAlign: 'right',
            },
            children: title,
          };
        }
        return title;
      },
    };
    const cityColumn: TableColumn<CalculcatedGrouppedData> = {
      dataIndex: 'city',
      title: 'Город',
      width: '10%',
      customRender: (city: string | number | undefined, row, index: number) => {
        if (this.rowIsTotal(index)) {
          return {
            style: {
              textAlign: 'right',
            },
            children: city?.toString(),
          };
        }
        return city?.toString();
      },
    };

    const titleColumns = [titleColumn];
    if (this.withCity && this.showCity) {
      titleColumns.push(cityColumn);
    }

    const totalColumn: TableColumn<CalculcatedGrouppedData> = {
      dataIndex: 'total',
      title: 'Всего',
      align: 'right',
      customCell: () => ({
        class: 'ant-table-column-total',
      }),
    };

    if (!this.filter.periodType) {
      return [
        indexColumn,
        ...titleColumns,
        totalColumn,
      ];
    }

    const timeStepsColumns: TableColumn<CalculcatedGrouppedData>[] = this.timeSteps
      .map((timeStep) => ({
        key: timeStep,
        dataIndex: timeStep,
        title: this.filter.periodType ? getColumnTitleByPeriodType(this.filter.periodType, timeStep) : '',
        align: 'right',
        customRender: (count: number | undefined) => {
          if (count) {
            return count.toString();
          }
          return '0';
        },
      }));
    return [
      indexColumn,
      ...titleColumns,
      ...timeStepsColumns,
      totalColumn,
    ];
  }

  get timeSteps() {
    const distinctTimeSteps = Array.from(new Set(this.value.map((x) => x.timeStep)));
    distinctTimeSteps.sort();
    return distinctTimeSteps;
  }
}
