


















import { uniqBy } from 'lodash';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { PermissionAction, PermissionScope, PermissionSection } from '@/mixins/const-mixin';
import { Role } from '@/client-axios';
import { RootConst } from '@/store';
import { TableColumn } from '@/types/ant-design-vue-types';

interface Row {
  section: string;
  [action: string]: string;
}

@Component
export default class RolePermissionsTable extends Vue {
  actions = PermissionAction;

  @Prop({ required: true, type: Number })
  readonly roleId: number;

  roles: Role[] = [];

  created() {
    this.$store.dispatch(RootConst.Actions.loadRoles)
      .then((roles) => {
        this.roles = roles;
      });
  }

  get role() {
    return this.roles.find((x) => x.id === this.roleId);
  }

  get selectedRolePermissionSections(): string[] {
    if (!this.role?.permissions) {
      return [];
    }

    return uniqBy(this.role.permissions, (x) => x.section).map((x) => x.section);
  }

  get selectedRolePermissionActions(): string[] {
    if (!this.role?.permissions) {
      return [];
    }

    return uniqBy(this.role.permissions, (x) => x.action).map((x) => x.action);
  }

  getDisplayValue(section: string, action: string): string {
    const scope = this.role?.permissions?.find((x) => x.action === action && x.section === section)?.scope as PermissionScope;
    return scope ? this.scopeDisplayValue(scope) : '';
  }

  actionDisplayValue = (action: string): string => {
    switch (action) {
      case PermissionAction.view:
        return 'Просмотр';
      case PermissionAction.create:
        return 'Создание';
      case PermissionAction.modify:
        return 'Изменение';
      case PermissionAction.delete:
        return 'Удаление';
      default:
        return '';
    }
  }

  sectionDisplayValue = (action: string): string => {
    switch (action) {
      case PermissionSection.patient:
        return 'Пациенты';
      case PermissionSection.analysis:
        return 'Исследования';
      case PermissionSection.user:
        return 'Учетные записи';
      case PermissionSection.structure:
        return 'Организации';
      case PermissionSection.statistics:
        return 'Статистика';
      case PermissionSection.dump:
        return 'Выгрузка';
      case PermissionSection.shippingCompany:
        return 'Транспортные компании';
      case PermissionSection.affiliation:
        return 'Аффиляции';
      case PermissionSection.consultation:
        return 'Консультации';
      case PermissionSection.transplantation:
        return 'ТГСК';
      default:
        return `(неизвестная секция ${action})`;
    }
  }

  scopeDisplayValue = (action: string): string => {
    switch (action) {
      case PermissionScope.ownPatients:
        return 'Для своих пациентов';
      case PermissionScope.ownStructure:
        return 'Для своей структуры';
      case PermissionScope.ownStructureWithChildren:
        return 'Для своей структуры и подструктур';
      case PermissionScope.system:
        return 'Для всей системы';
      default:
        return '';
    }
  }

  get columns(): TableColumn<Row>[] {
    return [
      {
        title: 'Раздел',
        dataIndex: 'section',
        customRender: this.sectionDisplayValue,
      },
      ...this.selectedRolePermissionActions.map((action): TableColumn<Row> => ({
        dataIndex: action,
        title: this.actionDisplayValue(action),
      })),
    ];
  }

  get rows(): Row[] {
    return this.selectedRolePermissionSections.map((section) => ({
      section: section,
      ...Object.fromEntries(this.selectedRolePermissionActions.map((action) => [action, this.getDisplayValue(section, action)])),
    }));
  }
}
