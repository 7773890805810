const units = ['кБ', 'МБ', 'ГБ', 'ТБ', 'ПБ'];

export default function (bytes: number) {
  const thresh = 1024;
  if (Math.abs(bytes) < thresh) {
      return `${bytes} Б`;
  }
  let u = -1;
  do {
      bytes /= thresh;
      u += 1;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return `${bytes.toFixed(1)} ${units[u]}`;
}
