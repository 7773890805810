










import Chart, { ChartDataset } from 'chart.js/auto';
import Vue from 'vue';
import {
  Component,
  Prop,
  Ref,
  Watch,
} from 'vue-property-decorator';
import { GroupedModel } from '@/client-axios';
import { getColumnTitleByPeriodType, IStatisticsFilter, PeriodType } from '@/utils/statistics';

export type DataType = 'basic' | 'cumulative';

export function getDataTypeDisplayName(value: DataType): string {
  switch (value) {
    case 'basic':
      return 'Базовый';
    case 'cumulative':
      return 'Кумулятивный';
    default:
      throw new Error('Неизвестный тип графика');
  }
}

function getEmptyCache(): { [key: string]: number[] | undefined } {
  return {
    basic: undefined,
    cumulative: undefined,
  };
}

function getCumulativeData(data: number[]): number[] {
  const result = [...data];
  for (let index = 1; index < result.length; index += 1) {
    result[index] += result[index - 1];
  }
  return result;
}

@Component
export default class StatisticsChart extends Vue {
  @Prop({ required: true, type: Array })
  readonly data: GroupedModel[];

  @Prop({ required: true })
  readonly filter: IStatisticsFilter;

  @Prop({ required: true, type: String })
  readonly title: string;

  @Prop({
    type: String,
    required: false,
    default: 'basic',
    validator: (val: string) => ['basic', 'cumulative'].includes(val),
  })
  readonly type: DataType;

  @Ref()
  readonly canvas: HTMLCanvasElement;

  mounted() {
    this.onDataChange();
  }

  get showChart() {
    return !!this.chart && !!this.filter.periodType && this.timeSteps.length > 1;
  }

  @Watch('showChart')
  onShowChartChange(showChart: boolean) {
    this.$emit('update:showChart', showChart);
  }

  chart: null | Chart = null;

  get timeSteps() {
    const distinctTimeSteps = Array.from(new Set(this.data.map((x) => x.timeStep)));
    distinctTimeSteps.sort();
    return distinctTimeSteps;
  }

  @Watch('data')
  onDataChange() {
    this.clearTypeCache();
    this.createOrUpdateChart();
  }

  @Watch('filter')
  onFilterChange() {
    this.clearTypeCache();
    this.createOrUpdateChart();
  }

  @Watch('type')
  onTypeChange() {
    if (this.chart) {
      this.chart.data.datasets[0] = this.dataset;
      this.chart.update();
    }
  }

  get basicData() {
    return this.timeSteps.map((timeStep) => this.data.filter((y) => y.timeStep === timeStep)
      .reduce((accum, curr) => curr.count + accum, 0) ?? 0);
  }

  get dataByType() {
    return this.getDataByType(this.basicData, this.type);
  }

  dataByTypeCache = getEmptyCache();

  clearTypeCache() {
    this.dataByTypeCache = getEmptyCache();
  }

  getDataByType(data: number[], dataType: DataType): number[] {
    const cachedValue = this.dataByTypeCache[dataType];
    if (cachedValue) {
      return cachedValue;
    }

    let result: number[];
    switch (dataType) {
      case 'basic':
        result = data;
        break;
      case 'cumulative':
        result = getCumulativeData(data);
        break;
      default:
        throw new Error('Неизвестный тип графика');
    }

    this.dataByTypeCache[dataType] = result;

    return result;
  }

  get dataset(): ChartDataset<'line', any[]> {
    if (this.type === 'cumulative') {
      return {
        label: `${this.title} (кумулятивно)`,
        borderColor: '#f67019',
        data: this.dataByType,
      };
    }

    return {
      label: this.title,
      borderColor: '#40a9ff',
      data: this.dataByType,
    };
  }

  createOrUpdateChart() {
    if (!this.filter.periodType) {
      return;
    }

    if (this.data.length < 2) {
      return;
    }

    const labels = this.timeSteps.map((x) => getColumnTitleByPeriodType(this.filter.periodType as PeriodType, x));

    if (this.chart) {
      this.chart.data.labels = labels;
      this.chart.data.datasets[0] = this.dataset;
      this.chart.update();
      return;
    }

    this.chart = new Chart(this.canvas, {
      type: 'line',
      options: {
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: false,
            text: this.title,
          },
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1,
            },
          },
          x: {
            ticks: {
              align: 'end',
            },
          },
        },
      },
      data: {
        labels: labels,
        datasets: [
          this.dataset,
        ],
      },
    });
  }
}
