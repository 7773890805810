






























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Transplantation } from '@/client-axios';
import BackendService from '@/api/backendService';

@Component({
  components: {
  },
})
export default class TransplantationDetails extends Vue {
  @Prop({ required: true, type: Number })
  readonly patientId: number;

  created() {
    this.load();
  }

  transplantationData: null | Transplantation = null;

  loading = false;

  loadingError: null | Error = null;

  load() {
    this.loading = false;
    this.loadingError = null;
    return BackendService.patientApi.getTransplantation(this.patientId)
      .then(({ data }) => {
        this.transplantationData = data;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }
}
