














































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { TableColumn } from '@/types/ant-design-vue-types';
import { getActionsColumn } from '@/utils/registry';
import { StructureListItem } from '@/client-axios';
import BackendService from '@/api/backendService';
import classifier from '@/filters/classifier';
import { RootConst } from '@/store';
import { getStructuresLeafs, INode } from '@/utils/structures';

type StructureListItemNode = INode & StructureListItem;

@Component
export default class Structures extends Vue {
  get canCreate() {
    return this.$auth.permissions.has(this.$const.permissions.sections.structure, this.$const.permissions.actions.create);
  }

  get canEdit() {
    return this.$auth.permissions.has(this.$const.permissions.sections.structure, this.$const.permissions.actions.modify);
  }

  get canDelete() {
    return this.$auth.permissions.has(this.$const.permissions.sections.structure, this.$const.permissions.actions.delete);
  }

  created() {
    this.load();
  }

  // eslint-disable-next-line
  get columns(): TableColumn<StructureListItem>[] {
    return [
      {
        title: 'Название',
        dataIndex: 'title',
        customRender: (title: string, row) => (
          <router-link to={{ name: 'structures-details', params: { id: row.id } }}>
            { title }
          </router-link>
        ),
      },
      {
        title: 'Город',
        dataIndex: 'city',
      },
      {
        title: 'Тип',
        dataIndex: 'typeId',
        width: '25%',
        customRender: (typeId: number) => classifier(typeId),
      },
      getActionsColumn(),
    ];
  }

  structures: StructureListItem[] = [];

  expandedRowKeys: number[] = [];

  loading = false;

  loadingError: null | Error = null;

  load() {
    this.loading = true;
    this.loadingError = null;
    this.$store.dispatch(RootConst.Actions.loadStructures, { force: true })
      .then((structures) => {
        this.loading = false;
        this.structures = structures;
        this.expandedRowKeys = this.structures.map((x) => x.id);
      })
      .catch((error) => {
        this.loading = false;
        this.loadingError = error;
      });
  }

  get structuresTree() {
    const leafMapFunction = (item: StructureListItem, children: StructureListItemNode[]) => ({
      ...item,
      children: children.length === 0 ? undefined : children,
    });
    return getStructuresLeafs<StructureListItemNode>(null, this.structures, new Set(), leafMapFunction);
  }

  remove(structure: StructureListItem) {
    this.$confirm({
      title: `Вы действительно хотите удалить организацию ${structure.title}?`,
      okText: 'Удалить',
      okButtonProps: {
        type: 'danger',
      },
      onOk: () => this.executeRemove(structure),
      cancelText: 'Отмена',
    });
  }

  removing = false;

  removingError: null | Error = null;

  executeRemove(structure: StructureListItem) {
    this.removing = true;
    this.removingError = null;
    return BackendService.usersApi.deleteStructureById(structure.id)
      .then(() => {
        this.removing = false;
        this.load();
        return true;
      })
      .catch((error) => {
        this.removingError = error;
        this.removing = false;
      });
  }
}
